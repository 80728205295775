import React from "react";
import gitInfo from "../generatedGitInfo.json";

function AppVersion() {
  return (
    <div className="absolute bottom-0 right-0">
      <div className="text-xxs">
        {gitInfo.version},{/* ({gitInfo.branch}) */}
        {new Date(gitInfo.date).toLocaleDateString("cs-CZ", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour12: false,
          hour: "numeric",
          minute: "numeric",
        })}
      </div>
    </div>
  );
}

export default AppVersion;
