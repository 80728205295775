import proj4 from "proj4";
// import Polygon from "ol/geom/Polygon";

import { transform } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import { GeometryFactory } from "jsts/org/locationtech/jts/geom";
import GeoJSONReader from "jsts/org/locationtech/jts/io/GeoJSONReader";
import IsValidOp from "jsts/org/locationtech/jts/operation/valid/IsValidOp";

const reader = new GeoJSONReader();

export function isValidPolygon(coordinates) {
  // Convert the OpenLayers feature's geometry to GeoJSON format
  const geometryFactory = new GeometryFactory();
  const reader = new GeoJSONReader(geometryFactory);

  // Convert this input data to a GeoJSON Polygon representation
  const geoJSONPolygon = {
    type: "Polygon",
    coordinates: coordinates,
  };

  // const geojsonFormat = new GeoJSON();
  // const geojsonGeometry = geojsonFormat.writeGeometryObject(
  //   openLayersGeometry,
  //   {
  //     dataProjection: "EPSG:4326",
  //     featureProjection: "EPSG:3857",
  //   }
  // );

  try {
    // Read the geometry with JSTS
    // const jstsGeometry = reader.read(geojsonGeometry);
    const jstsGeometry = reader.read(geoJSONPolygon);

    // Check validity using JSTS
    const isValid = IsValidOp.isValid(jstsGeometry);
    return isValid;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const krovak =
  "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813975277778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs";

// Transform the coordinates from Krovak (EPSG:5514) to Longitude/Latitude (EPSG:4326)
export const transformKrovakToLonLat = (coordinate) => {
  return proj4(krovak, "EPSG:4326", coordinate);
};

export const getMapyCzUrlFromLonLat = (lon, lat, zoom) => {
  //https://mapy.cz/zakladni?q=50.1222139N%2C14.4138156E
  return `https://mapy.cz/zakladni?q=${lat}N%2C${lon}E&base=ophoto`;
  // return `https://mapy.cz/zakladni?x=${lon}&y=${lat}&z=${zoom}&base=ophoto`;
};

export const getMapyCzUrlFromKrovak = (x, y, zoom) => {
  const lonLatCoordinate = transformKrovakToLonLat([x, y]);
  return getMapyCzUrlFromLonLat(lonLatCoordinate[0], lonLatCoordinate[1], zoom);
};

export const getCenterPoint = (points) => {
  if (!points) return null;
  if (Object.keys(points).length === 0) return null;
  if (points.length === 0) return null;
  if (points.length === 1) return points[0];

  const x = points.reduce((acc, point) => acc + point[0], 0) / points.length;
  const y = points.reduce((acc, point) => acc + point[1], 0) / points.length;
  return [x, y];
};

// export const computeArea = (points) => {
//   console.log(points);
//   if (!points) return null;
//   if (points.length < 3) return 0;

//   const polygon = new Polygon([points]);
//   const area = polygon.getArea();
//   return area;
// };

export const computeArea = (coordinates) => {
  // console.log(points);
  if (!coordinates) return null;
  // const flattenPoints
  // if (points.length < 3) return 0;

  const valid = isValidPolygon(coordinates);

  if (!valid) return 0;

  const points = flattenPoints(coordinates);
  const area =
    points.reduce((acc, point, index) => {
      const nextPoint = points[(index + 1) % points.length];
      return acc + (point[0] * nextPoint[1] - nextPoint[0] * point[1]);
    }, 0) / 2;

  return Math.abs(area);
};

export const identifyGeometry = (geometry) => {
  if (!Array.isArray(geometry)) {
    throw new Error("Input must be an array");
  }

  // If first element of array is a number, it's a point
  if (typeof geometry[0] === "number") {
    return "point";
  }

  // If first element of first element of array is a number, it's a line
  if (typeof geometry[0][0] === "number") {
    return "line";
  }

  // If first element of first element of first element of array is a number, it's a polygon
  if (typeof geometry[0][0][0] === "number") {
    return "polygon";
  }

  // If it gets here, the structure is not recognized
  return "unknown";
};

export const flattenPoints = (geometry) => {
  if (!geometry) return null;

  if (!Array.isArray(geometry)) {
    throw new Error("Input must be an array");
  }

  // Check if it's a coordinate pair
  if (typeof geometry[0] === "number" && geometry.length === 2) {
    return [geometry];
  }

  // Check if it's a line
  if (
    Array.isArray(geometry[0]) &&
    typeof geometry[0][0] === "number" &&
    geometry[0].length === 2
  ) {
    return geometry;
  }

  // If it's a polygon, recursively flatten the nested arrays
  let result = [];

  for (let i = 0; i < geometry.length; i++) {
    result = [...result, ...flattenPoints(geometry[i])];
  }

  return result;
};
