import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import { useSelector } from "react-redux";

const useSetViewerTitle = ({ dimensionType }) => {
  const { t } = useTranslation(namespaces.viewer);
  const { client, project, projectState } = useSelector((state) => state.info);

  useEffect(() => {
    document.title = `${project?.name || "..."} > ${projectState?.name || "..."} - ${t(
      `sidebar.view.${dimensionType}`
    )} - ${client?.name || "..."  }`;
  }, [client, project, projectState?.name, dimensionType, t]);
};

export default useSetViewerTitle;
