import React, { useEffect, useCallback, useMemo, useRef } from "react";
import TextInput, { TextInputSmall } from "../inputs/TextInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { RecordAreaType } from "../../api/consts";
import { computeArea, flattenPoints, isValidPolygon } from "../../utils/geo";
import { formatArea } from "../../features/measures/polygonArea2D";
import TogglesSelect from "../inputs/TogglesSelect";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import { useSelector } from "react-redux";
import useAuthInfo from "../../hooks/useAuthInfo";

function FacilityForm({ data, onChange, issues }) {
  const { t } = useTranslation(namespaces.viewer);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  const setData = useCallback(
    (newData) => {
      onChange(newData);
    },
    [onChange]
  );

  const handleTextChange = (name) => (value) => {
    setData({ ...data, [name]: value });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    const { value } = selectedOption;
    setData({ ...data, [name]: value });
  };

  const areaOptions = Object.values(RecordAreaType).map((area) => ({
    value: area,
    label: t(`form.${area}`),
  }));

  const isAreaSet = useRef(false);

  const [areaError, setAreaError] = React.useState(null);

  useEffect(() => {
    // console.log(data.points)
    // const t = setTimeout(() => {
    if (data?.points && isAreaSet.current === false) {
      isAreaSet.current = true;
      if (isValidPolygon(data.points) === false) {
        return setData({ ...data, size: "invalid" });
      }

      const area = computeArea(data.points);
      setData({ ...data, size: area });
    }
    // }, 500); // TODO: this is a hack

    // return () => clearTimeout(t);
  }, [data?.points, setData]);

  const size = useMemo(() => {
    if (data.size) {
      if (data.size === "invalid") {
        return "invalid";
      }

      return formatArea(data.size);
    }
    return "";
  }, [data.size]);

  return (
    <div className="flex flex-col gap-[20px] bg-bg1 rounded-xl pb-[25px]">
      {/* <div>
        <SelectBoxInput
          label={t("form.type")}
          options={["", "Plocha", "Obecne"]}
          selectedOption={data.type}
          onChange={handleSelectChange("type")}
        />
      </div> */}
      <div
        className="
          flex flex-row items-start justify-between
          w-full gap-[24px]
        "
      >
        {/* <SelectBoxInput
            label={t("form.type")}
            options={areaOptions}
            selectedOption={data.type}
            onChange={handleSelectChange("type")}
          /> */}
        <TogglesSelect
          label={t("form.type")}
          disabled={!canEdit}
          options={areaOptions}
          selectedOption={data.type}
          extraError={getErrorForPath(issues, ["type"])}
          onChange={handleSelectChange("type")}
        />
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          {size === "invalid" ? (
            <TextInputSmall
              disabled={true}
              extraError={{ message: "invalidPolygon" }}
              lightTheme={true}
              label={t("form.size")}
              value={0}
            ></TextInputSmall>
          ) : (
            <TextInputSmall
              disabled={true}
              extraError={getErrorForPath(issues, ["size"])}
              lightTheme={true}
              label={t("form.size")}
              value={size}
              // onChange={handleTextChange("size")}
            />
          )}
        </div>
      </div>
      <div>
        <TextInputSmall
          disabled={!canEdit}
          extraError={getErrorForPath(issues, ["note"])}
          lightTheme={true}
          label={t("form.note")}
          value={data.note}
          onChange={handleTextChange("note")}
        />
      </div>
    </div>
  );
}

export default FacilityForm;
