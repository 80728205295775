
export function formatDate(isoString, format) {
  if (!isoString) {
    return "";
  }
  return new Date(isoString).toLocaleDateString("en-GB", { format });
}

export const toReadableDate = (isoString) => formatDate(isoString, "dd/MM/yyyy");

export const dateToYYYYMMDD = (date) => {
  if (!date) return null;

  if (typeof date === "string") {
    date = new Date(date);
  }

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

