import Measure from "./measure";
import LineString from "ol/geom/LineString";
// class that hold line coordinates and calculate length
class LineDistance2D extends Measure {
  static Type = "distance";
  constructor(lineCoordinates, name) {
    super(name, "distance");
    this.line = new LineString(lineCoordinates);
  }

  getGeometry() {
    return this.line;
  }

  get coordinates() {
    return this.line.getCoordinates();
  }

  set coordinates(lineCoordinates) {
    this.line.setCoordinates(lineCoordinates);
  }

  getLength() {
    return this.line.getLength();
  }

  getResult() {
    return formatLength(this.getLength());
  }

  static fromSerialized(serialized) {
    let measure = new LineDistance2D(serialized.points || [], serialized.name);
    measure.id = serialized.id;
    measure.toBeStored = serialized.toBeStored;
    measure.dbId = serialized.dbId;
    return measure;
  }
}

export const formatLength = function (length) {
  let output;
  // if (length > 10000) {
  //   output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  // } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  // }
  return output;
};

export default LineDistance2D;
