import React from "react";
const LoadingBar = ({ progress, bgColor }) => {
  const bgColorClass = bgColor ? `bg-${bgColor}` : "bg-bg1";

  return (
    <div className={`relative w-full h-[6px] rounded-lg ${bgColorClass}`}>
      <div
        className="absolute top-0 left-0 h-full bg-primary rounded-lg"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default LoadingBar;
