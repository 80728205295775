import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import NavBar from "../components/NavBar";
import PageContent from "../components/PageContent";
import Page from "../components/Page";
import IconTextButton from "../components/buttons/IconTextButton";
import MeasureCard from "../components/cards/MeasureCard";
import CardGrid from "../components/cards/CardGrid";

import InputDialog from "../components/dialogs/InputDialog";
import MessageDialog from "../components/dialogs/MessageDialog";
import ShareLinkDialog from "../components/dialogs/ShareLinkDialog";
import OpenMeasureDialog from "../components/dialogs/OpenMeasureDialog";
import UploadMeasureDialog from "../components/dialogs/UploadMeasureDialog";
import {
  useGetClientQuery,
  useGetProjectQuery,
  useListProjectStatesQuery,
  useDeleteProjectStateMutation,
  useRenameProjectStateMutation,
  useCreateProjectStateMutation,
  DRAFT_PREFIX,
} from "../api/managementApi";
import { adminNavItem } from "./AdminPage";
import useAuth from "../hooks/useAuth";
import DownloadFilesDialog from "../components/dialogs/DownloadFilesDialog";
import { stateNameMaxLength, stateNamePattern } from "../utils/fsUtils";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import { addMessage } from "../redux/messages/messagesReducer";
import RecordListDialog from "../components/dialogs/RecordListDialog";

const ProjectPage = () => {
  useLinkAccessCheck();

  const { t } = useTranslation(namespaces.pages);
  const { clientId, projectId } = useParams();
  const dispatch = useDispatch();
  const { token, linkTokenLevel, isDemo } = useAuth();

  // get client
  const { data: client } = useGetClientQuery({
    id: clientId,
  });

  // get project
  const { data: project } = useGetProjectQuery({
    id: projectId,
    clientId: clientId,
  });

  const navItems = [
    token && adminNavItem,
    (token || linkTokenLevel === "client") && {
      name: client?.name,
      link: `/clients/${clientId}`,
    },
    (token || linkTokenLevel === "project" || linkTokenLevel === "client") && {
      name: project?.name,
      link: `/clients/${clientId}/projects/${projectId}`,
    },
  ].filter(Boolean);

  const {
    data: projectStates,
    isLoading: isProjectStatesLoading,
    isFetching: isProjectStatesFetching,
    refetch,
  } = useListProjectStatesQuery({ clientId, projectId });

  const [renameProjectState] = useRenameProjectStateMutation();

  const [createProjectState] = useCreateProjectStateMutation();

  const [deleteProjectState] = useDeleteProjectStateMutation();

  // const [editProjectState, { error: updateError }] =
  //   useEditProjectStateMutation();
  // console.log(clientId, projectId);

  const handleAddMeasure = () => {
    setUploadMeasureDialogOpen(true);
  };

  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [openMeasureDialogOpen, setOpenMeasureDialogOpen] =
    React.useState(false);
  const [uploadMeasureDialogOpen, setUploadMeasureDialogOpen] =
    React.useState(false);
  const [downloadFilesDialogOpen, setDownloadFilesDialogOpen] =
    React.useState(false);

  const [selectedMeasure, setSelectedMeasure] = React.useState(null);

  const [isRecordListVisible, setIsRecordListVisible] = React.useState(false);

  const handleDeleteClick = (measure) => {
    setSelectedMeasure(measure);
    setDeleteDialogOpen(true);
  };

  const handleRenameClick = (measure) => {
    setSelectedMeasure(measure);
    setRenameDialogOpen(true);
  };

  const handleClick = (measure) => {
    setSelectedMeasure(measure);
    setOpenMeasureDialogOpen(true);
    // navigate(`projects/${measure.id}`);
  };

  const handleShareClick = (measure) => {
    setSelectedMeasure(measure);
    setShareDialogOpen(true);
  };

  const handleFilesClick = (measure) => {
    setSelectedMeasure(measure);
    setDownloadFilesDialogOpen(true);
  };

  const handleRenameSubmit = async (name) => {
    await renameProjectState({
      clientId,
      projectId,
      id: selectedMeasure.id,
      name,
    });

    await refetch();

    setRenameDialogOpen(false);
  };

  // refresh selected measure if it was updated
  useEffect(() => {
    if (projectStates) {
      const refreshed = projectStates.find(
        (state) => state.id === selectedMeasure?.id
      );

      if (refreshed) {
        setSelectedMeasure(refreshed);
      }
    }
  }, [projectStates]);

  const handleDeleteSubmit = async () => {
    await deleteProjectState({
      clientId,
      projectId,
      id: selectedMeasure.id,
    });

    await refetch();

    setDeleteDialogOpen(false);
  };

  const handleUploadNewMeasureSubmit = async ({ id, name }) => {
    // rename from draft to name
    await renameProjectState({
      clientId,
      projectId,
      id: id,
      name,
    });

    dispatch(
      addMessage({
        type: "success",
        text: "toasts.projectStateSuccess",
      })
    );

    await refetch();

    setUploadMeasureDialogOpen(false);
  };

  const createProjectStateDraft = async ({ name }) => {
    const data = await createProjectState({
      clientId,
      projectId,
      name: DRAFT_PREFIX + name,
    }).unwrap();

    return data;
  };

  const deleteProjectStateDraft = async (id) => {
    await deleteProjectState({
      clientId,
      projectId,
      id,
    }).unwrap();
  };

  const handleOpenRecords = () => {
    setIsRecordListVisible(true);
  };

  return (
    <Page>
      <NavBar items={navItems}></NavBar>
      {/* {error && <Toast message={error.message} type="error"></Toast>} */}
      <PageContent
        title={project?.name}
        actions={[
          project?.allowedRecordTypes?.length > 0 && (
            <IconTextButton
              // secondary={true}
              iconClassName={"w-[20px] h-[20px]"}
              icon="/images/files.svg"
              label={t("projectPage.records")}
              onClick={handleOpenRecords}
            ></IconTextButton>
          ),
          token && (
            <IconTextButton
              icon="/images/plus.svg"
              label={t("projectPage.addMeasure")}
              onClick={handleAddMeasure}
            ></IconTextButton>
          ),
        ]}
      >
        <CardGrid isLoading={isProjectStatesFetching || isProjectStatesLoading}>
          {projectStates?.length > 0 &&
            projectStates.map((measure) => (
              <React.Fragment key={measure.id}>
                <MeasureCard
                  measure={measure}
                  onClick={handleClick}
                  onShare={handleShareClick}
                  onDelete={token && handleDeleteClick}
                  onEdit={handleRenameClick}
                  onFilesClick={handleFilesClick}
                />
              </React.Fragment>
            ))}
        </CardGrid>

        {isRecordListVisible && (
          <RecordListDialog
            projectId={projectId}
            onClose={() => setIsRecordListVisible(false)}
          ></RecordListDialog>
        )}
      </PageContent>

      <InputDialog
        isOpen={renameDialogOpen}
        onCancel={() => {
          setRenameDialogOpen(false);
        }}
        initialValue={selectedMeasure?.name}
        label={t("projectPage.renameMeasureDialog.name")}
        title={t("projectPage.renameMeasureDialog.title")}
        submitText={t("projectPage.renameMeasureDialog.rename")}
        onSubmit={handleRenameSubmit}
        inputProps={{
          pattern: stateNamePattern,
          maxLength: stateNameMaxLength,
        }}
        disabled={isDemo}
      />
      <MessageDialog
        isOpen={deleteDialogOpen}
        title={t("projectPage.deleteMeasureDialog.title", {
          folder: selectedMeasure?.name,
        })}
        onCancel={() => setDeleteDialogOpen(false)}
        onSubmit={handleDeleteSubmit}
        cancelText={t("projectPage.deleteMeasureDialog.cancel")}
        submitText={t("projectPage.deleteMeasureDialog.delete")}
        disabled={isDemo}
      />
      {selectedMeasure && shareDialogOpen && (
        <ShareLinkDialog
          isOpen={shareDialogOpen}
          data={selectedMeasure}
          title={t("shareLinkDialog.title")}
          onClose={() => setShareDialogOpen(false)}
          deactivateDisabled={isDemo}
          disabled={isDemo}
        ></ShareLinkDialog>
      )}
      <OpenMeasureDialog
        isOpen={openMeasureDialogOpen}
        measure={selectedMeasure}
        clientId={clientId}
        projectId={projectId}
        onClose={() => {
          setOpenMeasureDialogOpen(false);
        }}
      ></OpenMeasureDialog>
      <UploadMeasureDialog
        disabled={isDemo}
        isOpen={uploadMeasureDialogOpen}
        onClose={() => {
          setUploadMeasureDialogOpen(false);
        }}
        createProjectStateDraft={createProjectStateDraft}
        deleteProjectStateDraft={deleteProjectStateDraft}
        onSubmit={handleUploadNewMeasureSubmit}
      ></UploadMeasureDialog>
      <DownloadFilesDialog
        disabled={isDemo}
        isOpen={downloadFilesDialogOpen}
        onClose={() => {
          setDownloadFilesDialogOpen(false);
        }}
        // files={selectedMeasure?.files}
        measure={selectedMeasure}
      ></DownloadFilesDialog>
    </Page>
  );
};

export default ProjectPage;
