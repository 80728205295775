import React from "react";

const PageContent = (props) => {
  const { title, actions, children } = props;

  return (
    <div className="mx-[145px] my-[40px] max-w-[1138px] 2xl:min-w-[1138px] xl:min-w-[1138px] lg:min-w-[1024px] md:min-w-[750px]">
      <div className="flex justify-between items-center">
        <h1 className="text-[30px] font-bold text-700 text-ellipsis overflow-hidden
        ">{title}</h1>
        
        {actions && actions.length > 0 && (
          <div className="flex gap-[10px]">
            {actions.map((action, idx) => (
              <React.Fragment key={idx}>{action}</React.Fragment>
            ))}
          </div>
        )}
      </div>

      <div className="h-[2px] bg-white opacity-80 mt-[14px]"></div>
      <div>{children}</div>
    </div>
  );
};

export default PageContent;
