import React from "react";
import InputLabel from "./InputLabel";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";

const TextAreaInput = ({ label, defaultValue, onChange, onValidityChange, placeholder, lightTheme, inputClassName, ...otherProps }) => {
  const [value, setValue] = React.useState(defaultValue || "");
  const [inputError, setInputError] = React.useState(false);

  const { t } = useTranslation(namespaces.common);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);

    if (e.target.validity.patternMismatch) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  React.useEffect(() => {
    if (onValidityChange) {
      onValidityChange(!inputError);
    }
  }, [inputError]);

  const theme = lightTheme ? "bg-white" : "bg-bg1";

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <textarea
        onChange={handleChange}
        className={`rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline ${theme} ${otherProps?.pattern ? "invalid:bg-red-100" : ""} ${inputClassName || ""}`}
        id="name"
        placeholder={placeholder || ""}
        value={value}
        
        {...otherProps}
      />
      {inputError && (
        <p className="text-red-500 text-xs">
          {t("dialogs.inputFormatError")}
        </p>
      )}
    </div>
  );
};

export const TextAreaInputSmall = (props) => {
  return <TextAreaInput {...props} inputClassName="h-[45px] text-[12px] px-[10px] py-[5px] font-medium rounded-[14px]" />;
};

export default TextAreaInput;
