import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  mode: "2D",
};

export const measuresSlice = createSlice({
  name: "measures",
  initialState,
  reducers: {
    setMeasureMode: (state, action) => {
      state.mode = action.payload;
    },
   
  },

    // builder.addMatcher(
    //   computeApi.endpoints.getLasExtremePoints.matchRejected,
    //   (state,data) => {
    //     const measureDbId = data.meta.arg.originalArgs.measureDbId;
    //     const existingMinMax = state.minMaxData.find(
    //       (m) => m.measureDbId === measureDbId
    //     );

    //     if (existingMinMax) {
    //       existingMinMax.isLoading = false;
    //     }
    //   }
    // );

    // builder.addMatcher(
    //   computeApi.endpoints.getLasExtremePoints.matchPending,
    //   (state,data) => {
    //     const measureDbId = data.meta.arg.originalArgs.measureDbId;
    //     const existingMinMax = state.minMaxData.find(
    //       (m) => m.measureDbId === measureDbId
    //     );

    //     if (existingMinMax) {
    //       existingMinMax.isLoading = true;
    //     } else {
    //       state.minMaxData.push({
    //         measureDbId: measureDbId,
    //         isLoading: true,
    //       });
    //     }
    //   }
    // );
  // },
});

export const getUniqueName = (name, measures) => {
  const names = measures.map((measure) => measure.name);
  let uniqueName = name;
  let i = 1;
  while (names.includes(uniqueName)) {
    uniqueName = `${name} (${i})`;
    i++;
  }
  return uniqueName;
};

export const {
  setMeasureMode,
} = measuresSlice.actions;

export default measuresSlice.reducer;
