import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: false,
      wait: true,
    },
    fallbackLng: "cs",
    lng: "cs",
    ns: ["common", "pages", "viewer"],
    pluralSeparator: '_',
    pluralRule: (lng, options) => {
      const count = options.count;

      if (lng === 'cs') {
        if (count === 1) {
          return 'one';
        } else if (count >= 2 && count <= 4) {
          return 'two';
        } else {
          return 'many';
        }
      } else {
        return count !== 1 ? 'other' : 'one';
      }
    },
  });

export default i18n;
