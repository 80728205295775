import Measure from "./measure";
import Polygon from "ol/geom/Polygon";
// class that holds polygon coordinates and calculates area
class PolygonArea2D extends Measure {
  static Type = "area";
  constructor(polygonCoordinates, name) {
    super(name, "area");
    this.polygon = new Polygon(polygonCoordinates);
  }

  getGeometry() {
    return this.polygon;
  }

  get coordinates() {
    return this.polygon.getCoordinates();
  }

  set coordinates(polygonCoordinates) {
    this.polygon.setCoordinates(polygonCoordinates);
  }

  getArea() {
    return this.polygon.getArea();
  }

  getResult() {
    return this.getArea();
  }

  static fromSerialized(serialized) {
    let measure = new PolygonArea2D(serialized.points || [], serialized.name);
    measure.id = serialized.id;
    measure.dbId = serialized.dbId;
    measure.toBeStored = serialized.toBeStored;
    return measure;
  }
}

export const formatArea = function (area) {
  let output;
  // if (area > 10000) {
  //   output = Math.round((area / 1000000) * 100) / 100 + " " + "km²";
  // } else {
    output = Math.round(area * 100) / 100 + " " + "m²";
  // }
  return output;
};


export default PolygonArea2D;
