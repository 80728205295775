import React from "react";

function ListItemHeader({ onClick, title, isExpandable, expanded }) {
  return (
    <div
      className="text-base text-700 font-bold flex flex-row cursor-pointer
      max-w-[235px]
      "
      onClick={onClick}
    >
      <div className={`flex min-w-[12px] ${isExpandable ? "mr-1" : ""}`}>
        {isExpandable ? (
          <img
            width={16}
            height={16}
            src={
              expanded
                ? "/images/sidebar_dropdown_selected.svg"
                : "/images/sidebar_dropdown.svg"
            }
            alt="measure"
            className="inline"
          />
        ) : null}
      </div>
      {/* wrap text on overflow */}
      <div
        className="whitespace-pre-wrap break-words
        overflow-ellipsis
        overflow-hidden
      "
      >
        {title}
      </div>
      {/* - {measure.getResult()} */}
    </div>
  );
}

export default ListItemHeader;
