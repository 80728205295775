import React from "react";

function Logo(props) {

  return (
    //  <img src="/images/drone-logo.svg" alt="logo" {...props} />

    <svg
      width="90px"	
      height="37px"	
      viewBox={`0 0 90 37`}
      fill={props.fill || "#1E2D2F"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.68225 23.0109C8.27908 22.8738 6.5601 22.6472 6.5601 21.0176C6.5601 19.5685 8.09882 18.7544 9.90865 18.7544C11.7185 18.7544 13.2558 19.3881 13.3019 20.9729H19.2736C19.5 16.3541 15.0209 14 9.8178 14C4.61471 14 0.452819 16.3079 0.452819 21.1995C0.452819 26.4981 6.15343 27.0855 10.5417 27.673C12.0805 27.8996 13.6639 28.0815 13.6639 29.8467C13.6192 31.7938 11.7632 32.3365 9.90865 32.3365C8.09882 32.3827 6.37984 31.5672 6.33514 29.7558H0C0.226409 35.5523 5.24779 37 10.1798 37C15.1117 37 19.862 35.0529 19.9976 29.8452C20.1331 24.004 14.5681 23.5969 9.68225 23.008V23.0109Z" />
      <path d="M54.5785 11.232L54.5278 14.1312H50V18.8383H54.4279V37H61.1934V18.8383H68.7566V14.1312H61.1934V11.3745C61.1934 6.47638 65.7212 6.66733 69.3038 7.95096L70 3.00589C62.1402 0.725067 54.577 1.86472 54.577 11.232H54.5785Z" />
      <path d="M89.3899 31.1538C85.6285 32.5063 81.7248 32.273 81.7248 27.6105V18.8904H89.108V13.9945H81.7248V7H76.4568L75.7508 13.993H71V18.4697L75.28 18.8904V27.7502C75.28 36.9816 82.194 38.2419 90 36.0972L89.3884 31.1553L89.3899 31.1538Z" />
      <path d="M10.9991 4.96291C10.9991 2.41635 9.20816 0 5.88047 0H2V10H5.88047C9.25396 10 11.0449 7.5246 10.9991 4.96291ZM4.22373 8.06813V1.92884H5.85046C7.74568 1.92884 8.77542 3.3732 8.77542 4.97653C8.77542 6.57986 7.77569 8.06662 5.85046 8.06662H4.22373V8.06813Z" />
      <path d="M14.8149 6.70214H16.3254L18.0661 10H20V9.70088L18.0892 6.37432C19.0561 5.81687 19.5997 4.70348 19.5997 3.43298C19.5997 1.66244 18.5483 0.00671423 16.6145 0.00671423C15.4058 -0.00839278 14.2087 0.00671423 13 0.00671423V10H14.8136V6.70214H14.8149ZM14.7906 1.87545H16.6042C17.378 1.87545 17.8487 2.5462 17.8487 3.38917C17.8615 4.24574 17.3652 4.94369 16.6042 4.94369H14.7906V1.87696V1.87545Z" />
      <path d="M32 4.99415C32 1.74327 29.3383 0 26.5236 0C23.7089 0 21 1.72863 21 4.99415C21 8.25966 23.5998 10 26.4764 10C29.7372 10 32 7.94057 32 4.99415ZM23.3231 4.99415C23.3231 2.94643 24.8305 1.90867 26.5236 1.90867C28.2167 1.90867 29.7079 2.93179 29.7079 4.99415C29.7079 7.0565 28.1857 8.10597 26.509 8.10597C24.8322 8.10597 23.3231 7.10919 23.3231 4.99415Z" />
      <path d="M36.0201 4.29901L41.3902 10H42V0.0435305H39.967V5.67397L34.6385 0H34V9.97298H36.0201V4.29901Z" />
      <path d="M46.7288 5.77173H49.7034V3.90023H46.7288V1.8715H49.908V0H45V10H50V8.05593H46.7288V5.77173Z" />
      <path d="M37.0014 37H37.0028C43.0755 37 47.9986 31.6275 48 25H37V37H37.0014Z" />
      <path d="M36.9984 14H36.9967C29.8198 14 24.0016 18.9248 24 25H37V14H36.9984Z" />
    </svg>
  );
}

export default Logo;
