import React, { useEffect } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { addSeparators } from "../../utils/stringUtils";

function CardTag({ text }) {
  return (
    <div className="flex items-center justify-center h-[22px] px-[8px] leading-[10px] rounded-full bg-white text-[#A1A1A1] text-[11px] font-normal border border-[1px] border-[#A1A1A1]">
      <span className="mt-[1px] whitespace-nowrap">{text}</span>
    </div>
  );
}

function Card(props) {
  const { title, pretitle, subtitle, onClick, actions, tags, ...otherProps } =
    props;

  const handleCardClick = (e) => {
    onClick(e);
  };

  // const [titleEdited, setTitleEdited] = React.useState(title);

  // useEffect(() => {
  //   // setTitleEdited(addSeparators(title, 19));
  //   setTitleEdited(addSeparators(title, 19));
  // }, [title]);

  return (
    <div
      className="rounded-lg bg-white w-[267px] h-[174px] px-[22px] pt-[11px] pb-[10px] flex flex-col justify-between cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-300"
      onClick={handleCardClick}
      {...otherProps}
    >
      <div className="flex flex-col">
        <div className="flex flex-row-reverse mb-[7px] justify-between">
          {tags?.length > 0 &&
            tags.map((tag) => <CardTag text={tag} key={tag}></CardTag>)}
        </div>
        {/* <span className="text-gray-400 font-normal text-[11px] mb-[2px] h-[15px] mt-[-2px] text-right text-[#A1A1A1]">
          {pretitle}
        </span> */}
        <h2 className="text-xxl font-bold text-right h-[53px]" title={title}>
          <LinesEllipsis
            style={{overflow: "hidden"}}
            text={title}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
            onReflow={(reflowed) => {
              // console.log(reflowed);
            }}
          />
        </h2>
        <span className="text-gray-400 font-normal text-[11px] mt-[3px] mb-[7px] h-[15px] text-right text-[#A1A1A1]">
          {subtitle}
        </span>
        <div className="h-[2px] w-full bg-[#EEF0F2]" />

        <div className="flex items-center justify-end pt-[5px] gap-[12px]">
          {actions.map((action, idx) => (
            <React.Fragment key={idx}>{action}</React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;
