import React from "react";
import { DimensionType } from "../api/dimensionType";
import useGetProjectRecords from "../hooks/useGetRecords";
import IconButton from "./buttons/IconButton";
import { useDeleteRecordMutation } from "../api/managementApi";
import MessageDialog from "./dialogs/MessageDialog";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import EditRecordDialog from "./dialogs/EditRecordDialog";
import { RecordToPrint } from "./viewer/RecordToPrint";
import useRecordPrinting from "../hooks/useRecordPrinting";
import { useSelector } from "react-redux";
import { ExportButton } from "./viewer/sidebar/ActionButtons";
import SearchBar from "./inputs/SearchBar";
import useSearchRecords from "../hooks/useSearchRecords";
import useAuthInfo from "../hooks/useAuthInfo";

function RecordListPageItem({ record }) {
  const [deleteRecord, { isLoading: isDeleting }] = useDeleteRecordMutation();

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [isOpenEditRecordDialog, setIsOpenEditRecordDialog] =
    React.useState(false);

  const { t } = useTranslation(namespaces.viewer);

  const { printRef, isPrinting, handleExport, printData } =
    useRecordPrinting(record);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();

  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  const handleDeleteRecord = () => {
    console.log("delete record");
    setDeleteDialogOpen(true);
  };

  const handleEditRecord = () => {
    setIsOpenEditRecordDialog(true);
  };

  const handleDeleteRecordSubmit = () => {
    deleteRecord({
      id: record.id,
      dimension: DimensionType.D2,
    });
    setDeleteDialogOpen(false);
  };

  return (
    <div className="flex justify-between items-center px-5 py-2">
      <div className="flex text-black font-bold text-700 justify-center items-center text-xl">
        {record.name}
      </div>
      <div className="flex flex-row gap-2">
        <IconButton
          icon="/images/sidebar_record_detail.svg"
          size={24}
          onClick={handleEditRecord}
        ></IconButton>
        <ExportButton
          onClick={handleExport}
          disabled={isPrinting}
          isLoading={isPrinting}
        ></ExportButton>

        {/* <IconButton
          disabled={isPrinting}
          icon="/images/sidebar_record_export.svg"
          size={24}
          onClick={handleExportRecord}
        ></IconButton> */}
        {canEdit && (
          <IconButton
            icon="/images/trash.svg"
            size={24}
            onClick={handleDeleteRecord}
          ></IconButton>
        )}
      </div>
      <EditRecordDialog
        isOpen={isOpenEditRecordDialog}
        onClose={() => {
          setIsOpenEditRecordDialog(false);
        }}
        recordId={record.id}
      ></EditRecordDialog>
      <MessageDialog
        isOpen={deleteDialogOpen}
        title={t("deleteRecordDialog.title")}
        onCancel={() => setDeleteDialogOpen(false)}
        onSubmit={handleDeleteRecordSubmit}
        cancelText={t("deleteRecordDialog.cancel")}
        submitText={t("deleteRecordDialog.delete")}
      />
      <div className="hidden">
        <RecordToPrint
          ref={printRef}
          record={printData?.record || {}}
        ></RecordToPrint>
      </div>
    </div>
  );
}

function RecordList({ projectId }) {
  const { records } = useGetProjectRecords({
    projectId,
    dimension: DimensionType.D2,
  });

  const [recordsSearchText, setRecordsSearchText] = useSearchRecords();

  // // copy all records 100 times
  // const newRecords = [];
  // for (let i = 0; i < 10; i++) {
  //   newRecords.push(...records);
  // }

  // get page height
  const screenHeight = window.screen.height;
  const size = Math.round(screenHeight * 0.65);

  if (!records) return null;

  return (
    size && (
      <div className={`flex flex-col gap-3`} style={{ maxHeight: size }}>
        <h3 className="text-xxxl font-bold text-700 my-3">Záznamy</h3>

        <div className="rounded-xl bg-secondary p-4">
          <SearchBar
            onSearch={(searchText) => {
              setRecordsSearchText(searchText);
            }}
            value={recordsSearchText}
          ></SearchBar>
        </div>

        <div className={`h-[80%] overflow-y-auto`}>
          {records?.map((record, i) => (
            <div
              key={record.id + i}
              className="border-t-2 rounded-full border-white hover:bg-secondary"
            >
              <RecordListPageItem
                key={record.id}
                record={record}
              ></RecordListPageItem>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default RecordList;
