import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode";
import DialogWrapper from "./DialogWrapper";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import { namespaces } from "../../consts/i18n";

const QRCodeLinkDialog = ({ isOpen, link, onClose }) => {
  const { t } = useTranslation(namespaces.viewer);
  const [qrCodeDataUrl, setQRCodeDataUrl] = useState("");

  const [isClipboardCopied, setIsClipboardCopied] = React.useState(false);


  const handleCopyLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(link);
    setIsClipboardCopied(true);
  };

  useEffect(() => {
    if (isOpen) {
      // Generate the QR code data URL based on the data
      QRCode.toDataURL(JSON.stringify(link), (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setQRCodeDataUrl(url);
        }
      });
    }
  }, [isOpen, link]);

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleOpenInBrowser = (e) => {
    e.preventDefault();
    window.open(link, "_blank");
  };

  return isOpen ? (
    <DialogWrapper onClick={handleClose}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleClose}></CloseButton>
        <div className="flex flex-col gap-y-[39px]">
          <div>
            <h3 className="text-xxxl font-bold text-center px-[26px] text-ellipsis overflow-hidden">
              {t("qrCodeLinkDialog.title")}
            </h3>
          </div>

          <div className="flex justify-center items-center my-4">
            {qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code" />}
          </div>

          <div>
            <div className="relative">
              <input
                value={link}
                disabled={true}
                className={"rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1 text-[#B3B3B3] pr-[56px]"}
                id="name"
                type="text"
                placeholder=""
                
              ></input>
              <button
                className="absolute right-0 top-0 h-full w-[56px] flex justify-center items-center active:opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleCopyLink}
              >
                <img src="/images/copy.svg" alt="copy" className="" />
              </button>
            </div>
            {isClipboardCopied && (
              <div className="mx-[10px] my-[5px] text-sm text-center">
                {t("qrCodeLinkDialog.linkCopiedToClipboard")}
              </div>
            )}
          </div>

           
          <TextButton
              className={"h-[56px]"}
              secondary
              label={t("qrCodeLinkDialog.openInMapyCz")}
              onClick={handleOpenInBrowser}
            />

          <div className="flex flex-row items-center justify-center grow-0">
            <div>
              <TextButton
                className={"h-[56px]"}
                label={t("qrCodeLinkDialog.close")}
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default QRCodeLinkDialog;