import React, { useCallback, useEffect } from "react";
import IconButton from "../../buttons/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  markMeasureToBeStored,
  removeMeasure,
  renameMeasure,
  toggleMeasureVisibility,
  // saveMeasures,
  selectMeasure,
  deselectMeasure,
  hideAllMeasures,
} from "../../../redux/measures/measuresReducer";

import {
  selectMeasure as selectMeasure2,
  deselectMeasure as deselectMeasure2,
  showMeasure,
  hideMeasure,
  removeNonStoredMeasure,
  updateNonStoredMeasure,
  getKeyForMinMax,
} from "../../../redux/measures2/measures2Reducer";

import { useTranslation } from "react-i18next";
import InputDialog from "../../dialogs/InputDialog";
import { namespaces } from "../../../consts/i18n";
import MessageDialog from "../../dialogs/MessageDialog";
import PolygonVolume3D from "../../../features/measures/polygonVolume3D";
import PointMeasure3D from "../../../features/measures/point3D";
import IconTextButton from "../../buttons/IconTextButton";
import StateItem from "./StateItem";
import { useParams } from "react-router-dom";
import { useListProjectStatesQuery } from "../../../api/managementApi";
import { useReactToPrint } from "react-to-print";
import { ComparisonToPrint } from "../ComparisonToPrint";
import usePotreeViewer from "../../../hooks/usePotreeViewer";
import {
  convertCompareResult,
  pointsArrayOmitZ,
  useLazyCompareVolumeQuery,
  useLazyGetLasInfoQuery,
} from "../../../api/computeApi";
import {
  measureNameMaxLength,
  measureNamePattern,
} from "../../../utils/fsUtils";
import { extractLasFileId } from "../../../hooks/useGetProjectStateAdditionals";
import { DEMO_CLIENT_ID } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { DimensionType } from "../../../api/dimensionType";
import { toLonLat } from "ol/proj";
import { getMapyCzUrlFromKrovak } from "../../../utils/geo";
import QRCodeLinkDialog from "../../dialogs/QRCodeLinkDialog";
import ListItemWrapper from "./ListItemWrapper";
import ListItemHeader from "./ListItemHeader";
import {
  ButtonsDelimiter,
  DeleteButton,
  EditButton,
  StoreButton,
  VisibilityButton,
} from "./ActionButtons";
import {
  useUpdateMeasureMutation,
  useDeleteMeasureMutation,
} from "../../../api/managementApi";
import useGetMeasures from "../../../hooks/useGetMeasures";
import useAuthInfo from "../../../hooks/useAuthInfo";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const orderByDate = (projectStates, stateIds) => {
  const getCreatedAt = (stateId) => {
    const state = projectStates.find((s) => s.id === stateId);
    return new Date(state.createdAt);
  };

  return stateIds.sort((a, b) => {
    return getCreatedAt(a) - getCreatedAt(b);
  });
};

const MeasureListItem = ({ measure, selected, onClick, onStoreClick }) => {
  const dispatch = useDispatch();

  const { clientId, projectId, stateId } = useParams();

  const { makeScreenshot, zoomToMeasure } = usePotreeViewer();

  const { hidden, minMax } = useSelector((state) => state.measures2);

  const measureMode = useSelector((state) => state.measures.mode);

  const { measures } = useGetMeasures({
    projectId,
    dimension: measureMode,
  });

  const { token } = useAuth();

  const { isStateLinkLevel } = useAuthInfo();

  // get list of project states
  const { data: projectStates } = useListProjectStatesQuery({
    clientId: clientId,
    projectId: projectId,
  });

  // const minMaxData = useSelector((state) => state.measures.minMaxData);

  const [
    compareTrigger,
    { isLoading: isLoadingCompare, isFetching: isFetchingCompare },
  ] = useLazyCompareVolumeQuery();

  const [
    getLasInfoTrigger,
    { isLoading: isLoadingLasInfo, isFetching: isFetchingLasInfo },
  ] = useLazyGetLasInfoQuery();

  // const measures = useSelector((state) => state.measures.data);

  const [screenshotImageData, setScreenshotImageData] = React.useState(null);
  const { t } = useTranslation(namespaces.viewer);

  const [updateMeasure, { isLoading: isUpdating }] = useUpdateMeasureMutation();
  const [deleteMeasure, { isLoading: isDeleting }] = useDeleteMeasureMutation();

  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [selectedStates, setSelectedStates] = React.useState([]);
  const [resultVisible, setResultVisible] = React.useState(false);
  const [compareResult, setCompareResult] = React.useState({});

  const [displayedPoints, setDisplayedPoints] = React.useState([]);
  // const [isLoadingCompare, setIsLoadingCompare] = React.useState(false);

  const [isLoadingPrint, setIsLoadingPrint] = React.useState(false);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleRenameClick = () => {
    setRenameDialogOpen(true);
  };

  const handleToggleVisibilityClick = useCallback(() => {
    const { id } = measure;
    if (hidden.includes(id)) {
      dispatch(showMeasure(id));
    } else {
      dispatch(hideMeasure(id));
    }
  }, [measure, measureMode, hidden]);

  const handleStoreClick = useCallback(() => {
    // if (measure.toBeStored) {
    //   dispatch(markMeasureToBeStored({ id: measure.id, toBeStored: false }));
    // } else {
    //   dispatch(markMeasureToBeStored({ id: measure.id, toBeStored: true }));
    // }

    onStoreClick({ measure: measure, toBeStored: !measure.toBeStored });

    // dispatch(saveMeasures());
  }, [dispatch, measure, onStoreClick]);

  // only two selected states are allowed, if new state is selected, the old one is removed
  const handleStateItemClick = (state) => {
    setResultVisible(false);
    setCompareResult({});
    if (selectedStates.includes(state.id)) {
      setSelectedStates(selectedStates.filter((s) => s !== state.id));
    } else {
      if (selectedStates.length === 2) {
        setSelectedStates([selectedStates[1], state.id]);
      } else {
        setSelectedStates([...selectedStates, state.id]);
      }
    }
  };

  const handleCompareClick = async (e) => {
    e.stopPropagation();
    if (selectedStates.length === 2) {
      try {
        const orderByDateStates = orderByDate(projectStates, selectedStates);

        const inputState = projectStates.find(
          (s) => s.id === orderByDateStates[1]
        );
        const compareState = projectStates.find(
          (s) => s.id === orderByDateStates[0] // first is newer
        );

        const inputFile = extractLasFileId(inputState);
        const compareFile = extractLasFileId(compareState);

        if (!inputFile || !compareFile) {
          throw new Error("no las file found");
        }

        const result = await compareTrigger({
          inputFile: inputFile,
          compareFile: compareFile,
          polygonPoints: pointsArrayOmitZ(measure.points),
        }).unwrap();

        const inputLasInfo = await getLasInfoTrigger(inputFile).unwrap();
        const compareLasInfo = await getLasInfoTrigger(compareFile).unwrap();

        const converted = convertCompareResult(
          result,
          inputLasInfo,
          compareLasInfo
        );

        // setCompareResult(converted.volume);
        setCompareResult(converted);
        setResultVisible(true);
      } catch (e) {
        console.log("error", e);
      }

      // fake result in promise
      // setIsLoadingCompare(true);
      // const result = await new Promise((res) => {
      //   setTimeout(() => {
      //     res({
      //       data: {
      //         original: 100,
      //         result: 200,
      //         positiveChange: 100,
      //         negativeChange: -50,
      //       },
      //     });
      //   }, 500);
      // });
      // setIsLoadingCompare(false);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick(measure);
  };

  const hiddenBeforePrint = React.useRef([]);
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: async () => {
      // console.log("onBeforeGetContent");
      // await delay(1000);
    },
    // print: async (printIframe) => {

    // },
    onAfterPrint: () => {
      // console.log("onAfterPrint");
      // dispatch(hideAllMeasures());
      measures.forEach((m) => {
        dispatch(showMeasure(m.id));
      });

      hiddenBeforePrint.current.forEach((m) => {
        if (m) dispatch(hideMeasure(m.id));
      });
      hiddenBeforePrint.current = [];
    },
  });

  const selectedStatesInfo = projectStates?.filter((s) =>
    selectedStates.includes(s.id)
  );

  const handleExportClick = useCallback(async () => {
    try {
      // hide all measures and show only the one that is being exported
      setIsLoadingPrint(true);
      // const visibleMeasures = measures.filter((m) => !m.hidden);
      hiddenBeforePrint.current = [...hidden];
      // dispatch(hideAllMeasures());
      measures.forEach((m) => {
        dispatch(hideMeasure(m.id));
      });
      dispatch(showMeasure(measure.id));
      zoomToMeasure(measure.id, { duration: 0 });
      await delay(300);
      const data = makeScreenshot();
      setScreenshotImageData(data);
      await delay(300);
      setIsLoadingPrint(false);
      handlePrint();
      // await delay(1000);
      // restore previous state
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPrint(false);
    }
  }, [measure, measures, hidden, handlePrint]);

  useEffect(() => {
    if (screenshotImageData) {
    }
  }, [screenshotImageData, handlePrint]);

  useEffect(() => {
    if (!projectStates) return;

    const _displayedPoints = [];
    if (measure.type === "point") {
      _displayedPoints.push({
        label: "pozice",
        coordinate:
          measureMode === DimensionType.D3 ? measure.points[0] : measure.points,
        dimension: measureMode,
      });
    }

    const fileId = extractLasFileId(
      projectStates.filter((s) => s.id === stateId)[0]
    );

    const key = getKeyForMinMax(fileId, pointsArrayOmitZ(measure.points));
    const minMaxData = minMax[key]; // minMax[] //minMaxData.find((d) => d.measureDbId === measure.dbId);
    // console.log("minMaxData", minMaxData);
    // debugger;
    if (minMaxData) {
      if (minMaxData.min && minMaxData.max && !minMax.isLoading) {
        _displayedPoints.push({
          key: "min_" + key,
          label: "min",
          coordinate: minMaxData.min,
          dimension: measureMode,
        });
        _displayedPoints.push({
          key: "max_" + key,
          label: "max",
          coordinate: minMaxData.max,
          dimension: measureMode,
        });
      }
      if (minMaxData.isLoading) {
        _displayedPoints.push({
          key: "loading_" + key,
          label: "min/max načítání...",
          isLoading: true,
          coordinate: null,
          dimension: measureMode,
        });
      }
    }

    setDisplayedPoints(_displayedPoints);
  }, [measureMode, minMax, measure, stateId, projectStates]);

  return (
    <div>
      <ListItemWrapper
        onSelected={() => {
          dispatch(selectMeasure2(measure.id));
        }}
        onDeselected={() => {
          dispatch(deselectMeasure2(measure.id));
        }}
        header={
          <ListItemHeader
            onClick={handleClick}
            title={measure.name}
            isExpandable={
              measure.type === PolygonVolume3D.Type || measure.type === "point"
            }
            expanded={selected}
          ></ListItemHeader>
        }
        actionButtons={[
          <StoreButton
            onClick={handleStoreClick}
            stored={measure.toBeStored}
            disabled={
              (!token && clientId === DEMO_CLIENT_ID) || isStateLinkLevel
            }
          ></StoreButton>,

          <ButtonsDelimiter />,
          <EditButton
            onClick={handleRenameClick}
            disabled={
              (!token && clientId === DEMO_CLIENT_ID && measure.toBeStored) ||
              (isStateLinkLevel && measure.toBeStored)
            }
          ></EditButton>,

          <VisibilityButton
            onClick={handleToggleVisibilityClick}
            visible={hidden.includes(measure.id) === false}
          ></VisibilityButton>,

          <DeleteButton
            onClick={handleDeleteClick}
            disabled={
              (!token && clientId === DEMO_CLIENT_ID && measure.toBeStored) ||
              (isStateLinkLevel && measure.toBeStored)
            }
          ></DeleteButton>,
        ]}
      >
        {/* min max + point info */}
        {selected && displayedPoints.length > 0 && (
          <div className="border-t-2 border-secondary py-[5px]">
            {displayedPoints.map((point) => (
              <PointItem
                isLoading={point.isLoading}
                key={point.key || point.label + point.coordinate.toString}
                coordinate={point.coordinate}
                label={point.label}
                dimension={point.dimension}
              ></PointItem>
            ))}
          </div>
        )}
        {selected && measure.type === PolygonVolume3D.Type && (
          <div className="pb-[20px] relative border-t-2 border-secondary py-[5px]">
            {projectStates?.length > 0 &&
              projectStates.map((state) => {
                return (
                  <StateItem
                    key={state.id}
                    state={state}
                    selected={selectedStates.includes(state.id)}
                    onClick={handleStateItemClick}
                  />
                );
              })}
            {(isLoadingCompare ||
              isFetchingCompare ||
              isLoadingLasInfo ||
              isFetchingLasInfo) && (
              <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white bg-opacity-50">
                <div className="w-full h-full flex items-center justify-center">
                  {/* <span className="loader"> </span> */}
                </div>
              </div>
            )}
          </div>
        )}
        <InputDialog
          isOpen={renameDialogOpen}
          onCancel={() => {
            setRenameDialogOpen(false);
          }}
          initialValue={measure.name}
          label={t("renameMeasureDialog.measureName")}
          title={t("renameMeasureDialog.title")}
          submitText={t("renameMeasureDialog.rename")}
          inputProps={{
            maxLength: measureNameMaxLength,
            pattern: measureNamePattern,
          }}
          onSubmit={(text) => {
            setRenameDialogOpen(false);

            // dispatch(renameMeasure({ id: measure.id, name: text }));
            if (measure.toBeStored === false) {
              // dispatch(renameMeasure({ id: measure.id, name: text }));
              dispatch(updateNonStoredMeasure({ id: measure.id, name: text }));
            } else {
              updateMeasure({ id: measure.id, name: text });
            }
          }}
        />
        <MessageDialog
          isOpen={deleteDialogOpen}
          title={t("deleteMeasureDialog.title")}
          onCancel={() => setDeleteDialogOpen(false)}
          onSubmit={() => {
            setDeleteDialogOpen(false);
            //dispatch(removeMeasure(measure.id));
            if (measure.toBeStored === false) {
              dispatch(removeNonStoredMeasure(measure.id));
            } else {
              deleteMeasure({ id: measure.id });
            }
          }}
          cancelText={t("deleteMeasureDialog.cancel")}
          submitText={t("deleteMeasureDialog.delete")}
        />
      </ListItemWrapper>

      {selected && selectedStates.length === 2 && (
        <div className="flex flex-col gap-[12px] mt-[12px]">
          {!resultVisible && (
            <React.Fragment>
              {/* <div className="w-full">
                <LoadingBar bgColor={"white"} progress={50}></LoadingBar>
              </div> */}
              {isLoadingCompare ||
              isFetchingCompare ||
              isFetchingLasInfo ||
              isLoadingLasInfo ? (
                <div className="w-full h-[50px] mt-[-20px] flex items-center justify-center">
                  <span className="loader"> </span>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <div>
                    <IconTextButton
                      disabled={isLoadingCompare || isFetchingCompare}
                      isLoading={isLoadingCompare || isFetchingCompare}
                      onClick={handleCompareClick}
                      className={"shadow-none hover:shadow-sm"}
                      label={t("sidebar.compareMeasure")}
                      icon={"/images/sidebar_compare.svg"}
                      iconClassName={"w-[16px] h-[16px]"}
                    ></IconTextButton>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}

          {resultVisible && (
            <div>
              <div className="flex flex-col shadow-lg bg-white rounded-lg px-[20px] py-[12px] text-700 text-sm font-bold gap-[6px]">
                {/* <div className="flex justify-between items-center">
                  <span> {t("sidebar.compareResult.original")}</span>
                  <span>
                    {compareResult.original} m<sup>3</sup>
                  </span>
                </div> */}

                <div className="flex justify-between items-center">
                  <span className="self-start">
                    {" "}
                    {t("sidebar.compareResult.change")}
                  </span>
                  <div className="flex flex-col gap-[7px]">
                    <div>
                      {compareResult.positiveChange} m<sup>3</sup>
                    </div>
                    <div>
                      {compareResult.negativeChange} m<sup>3</sup>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center border-t border-[#F1AB86] pt-[7px]">
                  <span> {t("sidebar.compareResult.result")}</span>
                  <span>
                    {compareResult.result} m<sup>3</sup>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-center mt-[12px]">
                <div>
                  <IconTextButton
                    onClick={handleExportClick}
                    isLoading={isLoadingPrint}
                    disabled={isLoadingPrint}
                    className={"shadow-none hover:shadow-sm"}
                    label={t("sidebar.exportComparison")}
                    icon={"/images/sidebar_export.svg"}
                  ></IconTextButton>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {resultVisible && (
        <div className="hidden">
          <ComparisonToPrint
            ref={printRef}
            states={selectedStatesInfo}
            data={{
              result: compareResult.result,
              changePositive: compareResult.positiveChange,
              changeNegative: compareResult.negativeChange,
              original: compareResult.original,
              image: screenshotImageData,
              lasInfo: compareResult.lasInfo,
              gridStep: compareResult.gridStep,
            }}
          ></ComparisonToPrint>
        </div>
      )}
    </div>
  );
};

function getCoordinateString(coordinate, dimension) {
  if (!coordinate) return "";

  const x = coordinate[0].toFixed(2);
  const y = coordinate[1].toFixed(2);
  const z =
    dimension === DimensionType.D3 ? `Z:${coordinate[2].toFixed(2)}` : "";

  return `X:${x} Y:${y} ${z}`;
}

export const PointItem = ({
  label,
  coordinate,
  dimension,
  isLoading,
  onEdit,
  onDelete,
}) => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(getCoordinateString(coordinate, dimension));
  };

  const formattedCoordinate = getCoordinateString(coordinate, dimension);

  const onOpenMapyCzClick = () => {
    const url = getMapyCzUrlFromKrovak(coordinate[0], coordinate[1], 18);
    window.open(url, "_blank");
  };

  const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = React.useState(false);
  const [qrcodeLinkData, setQRCodeLinkData] = React.useState(null);

  const onQRCodeClick = () => {
    const url = getMapyCzUrlFromKrovak(coordinate[0], coordinate[1], 18);
    setQRCodeLinkData(url);
    setIsQRCodeDialogOpen(true);
  };

  const handleEditClick = () => {
    if (onEdit) onEdit();
  };

  const handleDeleteClick = () => {
    if (onDelete) onDelete();
  };

  const { t } = useTranslation(namespaces.viewer);

  return (
    <div className="flex justify-between items-center">
      <span className="text-700 text-sm font-bold">{label}</span>
      {!isLoading && (
        <div className="flex justify-between items-center">
          <span className="text-400 text-sm">{formattedCoordinate}</span>
          <div className="grow flex flex-row justify-between gap-[10px] items-center">
            <IconButton
              size={24}
              onClick={onCopyClick}
              icon={"/images/sidebar_copy.svg"}
              title={t("sidebar.measure.copyCoordinates") + " " + label}
            ></IconButton>
            {/* <IconButton
              size={24}
              onClick={onOpenMapyCzClick}
              icon={"/images/sidebar_pin.svg"}
              title={t("sidebar.measure.openMapyCz")}
            ></IconButton> */}
            {onEdit && (
              <IconButton
                size={14}
                onClick={handleEditClick}
                icon={"/images/sidebar_edit_mini.svg"}
                title={t("sidebar.measure.editPoint")}
              ></IconButton>
            )}
            <IconButton
              size={18}
              onClick={onQRCodeClick}
              icon={"/images/sidebar_pin.svg"}
              // icon={"/images/sidebar_sendtomobile.svg"}
              title={t("sidebar.measure.openShareMapyCzDialog")}
            ></IconButton>

            {onDelete && (
              <IconButton
                size={18}
                onClick={handleDeleteClick}
                icon={"/images/sidebar_trash_mini.svg"}
                title={t("sidebar.measure.delete")}
              ></IconButton>
            )}
          </div>
        </div>
      )}
      <QRCodeLinkDialog
        isOpen={isQRCodeDialogOpen}
        link={qrcodeLinkData}
        onClose={() => {
          setIsQRCodeDialogOpen(false);
        }}
      />
    </div>
  );
};

export default MeasureListItem;
