import React from "react";
import TextInput, { TextInputPrint } from "../inputs/TextInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import FilesInput from "../inputs/FilesInput";
import TextAreaInput, { TextAreaInputSmall } from "../inputs/TextAreaInput";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import { useSelector } from "react-redux";
import DownloadFiles from "../inputs/DownloadFiles";
import InputLabel from "../inputs/InputLabel";

function ParksAndGardensFormPrint({ data }) {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <div className="flex flex-col gap-[20px] rounded-xl pb-[25px]">
      {/* <div>
        <SelectBoxInput
          label={t("form.type")}
          options={["", "Rostlina", "Obecny"]}
          selectedOption={data.type}
          onChange={handleSelectChange("type")}
        />
      </div> */}
      <div className="">
        <TextInputPrint
          disabled={true}
          label={t("form.inventoryNumber")}
          value={data.inventoryNumber}
          onChange={() => {}}
        />
      </div>
      <div className="">
        <TextInputPrint
          disabled={true}
          label={t("form.name")}
          value={data.floraName}
          onChange={() => {}}
        />
      </div>
      <div>
        <TextAreaInputSmall
          label={t("form.note")}
          value={data.note}
          onChange={() => {}}
        />
      </div>
    </div>
  );
}

export default ParksAndGardensFormPrint;
