import React, { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useListProjectStatesQuery } from "../../api/managementApi";
import { useSelector } from "react-redux";
import { DimensionType } from "../../api/dimensionType";

export const ViewerSmallButton = ({
  children,
  onClick,
  className,
  primary,
  disabled,
  title
}) => {
  const handleClick = (e) => {
    onClick(e);
  };

  let classNames =
    "rounded-full px-[10px] my-[5px] text-700 text-primary font-bold shadow-sm shadow-[#ddd] text-[14px] flex items-center hover:bg-primaryLight justify-center h-[36px] disabled:opacity-50 disabled:cursor-not-allowed";

  const hasBg = className?.includes("bg-");
  if (!hasBg) {
    //if no bg class is present, add bg-white
    classNames += " bg-white";
  }

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`${classNames} ${className || ""}`}
      title={title}
    >
      {children}
    </button>
  );
};

const TimelineEntry = ({ title, stateId, onClick, selected }) => {
  const handleClick = (e) => {
    onClick(stateId);
  };

  return (
    <ViewerSmallButton
      onClick={handleClick}
      className={
        (selected ? "bg-primary text-white hover:text-primary" : "bg-white") +
        " max-w-[120px] truncate align pointer-events-auto"
      }
      title={title}
    >
      <span className="text-ellipsis truncate text-[12px] mx-[10px]">{title}</span>
    </ViewerSmallButton>
  );
};

const Timeline = () => {
  const windowWidth = useWindowWidth();

  const { clientId, projectId, stateId } = useParams();

  const navigate = useNavigate();

  const {
    data: projectStates,
  } = useListProjectStatesQuery({ clientId, projectId });

  const measureMode = useSelector((state) => state.measures.mode);

  // set slides number based on window width
  let slidesPerView = Math.floor((windowWidth - 600) / 160);
  if (slidesPerView < 1) {
    slidesPerView = 1;
  }

  const swiperRef = useRef(null);

  const navigateToState = useCallback(
    (state) => {
      const viewerType =
        measureMode === DimensionType.D3 ? "pointcloud" : "map";
      navigate(
        `/clients/${clientId}/projects/${projectId}/${viewerType}/${state.id}`
      );
    },
    [measureMode, clientId, projectId, navigate]
  );

  const handlePrevClick = useCallback(() => {
    const currentStateIndex = projectStates.findIndex((s) => s.id === stateId);

    if (currentStateIndex === 0) {
      // already at the first state, do nothing
      return;
    }

    const nextStateIndex = currentStateIndex - 1;
    swiperRef.current.swiper.slideTo(nextStateIndex);

    const state = projectStates[nextStateIndex];
    if (state) {
      navigateToState(state);
    }
  }, [swiperRef, projectStates, navigateToState]);

  const handleNextClick = useCallback(() => {
    // swiperRef.current.swiper.slideNext();
    const currentStateIndex = projectStates.findIndex((s) => s.id === stateId);

    if (currentStateIndex === -1) {
      return;
    }

    const nextStateIndex = currentStateIndex + 1;
    swiperRef.current.swiper.slideTo(nextStateIndex);

    const state = projectStates[nextStateIndex];
    if (state) {
      navigateToState(state);
    }
  }, [swiperRef, projectStates, navigateToState]);


  const handleEntryClick = (id) => {
    const viewerType = measureMode === DimensionType.D3 ? "pointcloud" : "map";
    navigate(`/clients/${clientId}/projects/${projectId}/${viewerType}/${id}`);
  };

  const currentStateIndex = projectStates?.findIndex((s) => s.id === stateId);

  return (
    <div className="flex flex-row items-center ml-[5px]">
      {projectStates?.length > 0 && (
        <div className="">
          <ViewerSmallButton
            onClick={handlePrevClick}
            disabled={currentStateIndex === 0}
            className={"w-[36px] h-[36px] mr-[20px] px-[8px] pointer-events-auto"}
          >
            <img
              src="/images/viewer_arrow_left.svg"
              className="w-[100%] h-[100%] object-contain"
            />
          </ViewerSmallButton>
        </div>
      )}
      {projectStates?.length > 0 && (
        <Swiper
          className="flex grow"
          ref={swiperRef}
          slidesPerView={slidesPerView}
          // spaceBetween={50}
          // onReachBeginning={handleReachBeginning}
          // onReachEnd={handleReachEnd}
        >
          {projectStates.map((entry) => (
            <SwiperSlide key={entry.id} className="flex items-center justify-center">
              <TimelineEntry
                selected={entry.id === stateId}
                onClick={handleEntryClick}
                title={entry.name || entry.title}
                stateId={entry.id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {projectStates?.length > 0 && (
        <div className="">
          <ViewerSmallButton
            onClick={handleNextClick}
            disabled={currentStateIndex === projectStates.length - 1}
            className={"w-[36px] h-[36px] mr-[30px] ml-[20px] px-[8px] pointer-events-auto"}
          >
            <img
              src="/images/viewer_arrow_right.svg"
              className="w-[100%] h-[100%] object-contain"
            />
          </ViewerSmallButton>
        </div>
      )}
    </div>
  );
};

export default Timeline;

const entries = [
  {
    title: "21/10",
    id: 1,
  },
  {
    title: "22/10",
    id: 2,
  },
  {
    title: "25/10",
    id: 3,
  },
  {
    title: "26/10",
    id: 4,
  },
  {
    title: "27/10",
    id: 5,
  },
  {
    title: "28/10",
    id: 6,
  },
  {
    title: "29/10",
    id: 7,
  },
  {
    title: "30/10",
    id: 8,
  },
  {
    title: "31/10",
    id: 9,
  },
  {
    title: "01/11",
    id: 10,
  },
  {
    title: "02/11",
    id: 11,
  },
  {
    title: "03/11",
    id: 12,
  },
  {
    title: "04/11",
    id: 13,
  },
  {
    title: "05/11",
    id: 14,
  },
];
