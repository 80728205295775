import React from "react";
import TextInput, { TextInputPrint } from "../inputs/TextInput";
import DateSeparatedInput from "../inputs/DateSeparatedInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { dateToYYYYMMDD } from "../../utils/date";

function GraveYardFormPrint({ data }) {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <div className="flex flex-col gap-[20px] rounded-xl pb-[25px]">
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <TextInputPrint
            label={t("form.firstName")}
            disabled={true}
            value={data.firstName}
            onChange={() => {}}
          />
        </div>
        <div className="w-1/2">
          <TextInputPrint
            disabled={true}
            label={t("form.lastName")}
            value={data.lastName}
            onChange={() => {}}
          />
        </div>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <DateSeparatedInput
            variant={"small"}
            disabled={true}
            label={t("form.dateOfBirth")}
            value={dateToYYYYMMDD(data.dateOfBirth)}
            onChange={() => {}}
          />
        </div>
        <div className="w-1/2">
          <DateSeparatedInput
            disabled={true}
            variant={"small"}
            label={t("form.dateOfDeath")}
            value={dateToYYYYMMDD(data.dateOfDeath)}
            onChange={() => {}}
          />
        </div>
      </div>
      <div
        className="flex 
      flex-row gap-[24px]"
      >
        <div className="w-1/2">
          <DateSeparatedInput
            disabled={true}
            variant={"small"}
            label={t("form.dateOfLicenceExpiration")}
            value={dateToYYYYMMDD(data.dateOfLicenceExpiration)}
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
}

export default GraveYardFormPrint;
