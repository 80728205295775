import React from "react";
import AnimateSpin from "./AnimateSpin";
const IconTextButton = ({
  onClick,
  label,
  icon,
  className,
  iconClassName,
  isLoading,
  ...otherProps
}) => {
  const handleClick = (e) => {
    onClick(e);
  };

  let iconClassNames = "w-[12px] h-[12px]";

  if (iconClassName) {
    iconClassNames = iconClassName;

    //  if contains w- and h- then replace with new values
    if (iconClassName.includes("w-") && iconClassName.includes("h-")) {
      let wMatch = iconClassName.match(/w-(?:\[[0-9]+\]|([0-9]+))px/g);
      if (wMatch) {
          const w = wMatch[0].replace("w-", "").replace("px", "").replace(/\[|\]/g, "");
          iconClassNames = iconClassNames.replace(/w-(?:\[[0-9]+\]|([0-9]+))px/g, `w-[${w}px]`);
      }
  
      let hMatch = iconClassName.match(/h-(?:\[[0-9]+\]|([0-9]+))px/g);
      if (hMatch) {
          const h = hMatch[0].replace("h-", "").replace("px", "").replace(/\[|\]/g, "");
          iconClassNames = iconClassNames.replace(/h-(?:\[[0-9]+\]|([0-9]+))px/g, `h-[${h}px]`);
      }
  }
  
  }

  return (
    <button
      className={`rounded-lg flex flex-row h-[43px] px-[18px] shadow-lg items-center gap-[20px] bg-white text-black hover:bg-primaryLight hover:shadow-xl py-4 px-4 w-full text-base font-bold color-primary transition-all duration-300 ${
        className || ""
      } disabled:opacity-50 disabled:cursor-not-allowed`}
      type="button"
      onClick={handleClick}
      {...otherProps}
    >
      <img src={icon} className={iconClassNames} />
      {isLoading ? (
        /*t("loading")*/ <AnimateSpin size={60}></AnimateSpin>
      ) : (
        <span className="text-primary">{label}</span>
      )}
    </button>
  );
};

export default IconTextButton;
