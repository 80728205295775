import { useEffect, useState } from "react";
import {
  useLazyGetMeasuresQuery,
  useLazySearchMeasuresQuery,
} from "../api/managementApi";
import { useSelector } from "react-redux";
import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";

const filterMeasures = (measures, filteredTypes) => {
  return measures.filter((measure) => {
    if (filteredTypes.length === 0) return true;

    return filteredTypes.every((filter) => {
      switch (filter) {
        case "saved":
          return measure.toBeStored;
        case "distance":
          return measure.type === toolModeType.DISTANCE;
        case "area":
          return measure.type === toolModeType.AREA;
        case "volume":
          return measure.type === toolModeType.VOLUME;
        default:
          return false;
      }
    });
  });
};

const useGetMeasures = ({ projectId, dimension }) => {
  const [getMeasures, { data: serverMeasures }] = useLazyGetMeasuresQuery();

  const [searchMeasures, { data: searchedMeasures }] =
    useLazySearchMeasuresQuery();

  const nonStoredMeasures = useSelector((state) => state.measures2.nonStored);

  const [allMeasures, setAllMeasures] = useState([]);

  const searchText = useSelector((state) => state.measures2.search.text);

  const filterTypes = useSelector((state) => state.measures2.filter.types);

  useEffect(() => {
    // console.log("useGetMeasures", projectId, dimension, searchString);
    if (!projectId || !dimension) return;

    if (searchText && searchText.length > 0) {
      searchMeasures({
        projectId,
        dimension,
        searchString: searchText,
      });
    } else {
      getMeasures({
        projectId,
        dimension,
      });
    }
  }, [searchText, projectId, dimension]);

  useEffect(() => {
    if (!serverMeasures) return;

    const allMeas = [
      ...serverMeasures.filter((r) =>
        nonStoredMeasures.find((nr) => nr.id === r.id) ? false : true
      ),
      ...nonStoredMeasures,
    ];

    // console.log("allMeas", allMeas);
    // console.log("serverMeasures", serverMeasures);

    setAllMeasures(filterMeasures(allMeas, filterTypes));
  }, [serverMeasures, nonStoredMeasures, filterTypes]);

  // console.log("serverMeasures", serverMeasures);

  return {
    measures: (searchText ? searchedMeasures : allMeasures) || [],
  };
};

export default useGetMeasures;
