import React from "react";

function FilterItem({ label, onToggle, checked }) {
  const handleClick = () => {
    onToggle();
  };

  return (
    <button
      className={(checked ? " bg-primary text-white" : " bg-white text-black") + " rounded-full h-[28px] p-1 px-4 text-base font-bold hover:shadow-lg"}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

export default FilterItem;
