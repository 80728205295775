import React, {useCallback} from 'react'
import { getFilters } from '../../../hooks/useFilterMeasures';
import { useTranslation } from 'react-i18next';
import useFilterMeasures from '../../../hooks/useFilterMeasures';
import FilterItem from '../FilterItem';
import { namespaces } from '../../../consts/i18n';


function MeasureFilter({dimensionType}) {

  const filters = getFilters(dimensionType);

  const { t } = useTranslation(namespaces.viewer);

  const [measureFilterTypes, toggleMeasureFilterType] = useFilterMeasures();

  const handleToggleFilter = useCallback((filterItem) => {
    toggleMeasureFilterType(filterItem);
  }, [toggleMeasureFilterType]);

  return <div className="flex flex-row flex-wrap gap-2 my-[30px]">
    {filters.map((filter) => (
      <FilterItem
        key={filter}
        checked={measureFilterTypes.includes(filter)}
        onToggle={() => handleToggleFilter(filter)}
        label={t(`sidebar.filters.${filter}`)}
      ></FilterItem>
    ))}
  </div>
}

export default MeasureFilter