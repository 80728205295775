function base64UrlDecode(str) {
  str = str.replace('-', '+').replace('_', '/');
  while (str.length % 4) {
    str += '=';
  }
  return atob(str);
}

export function decodeJwt(jwt) {
  const parts = jwt.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid token');
  }

  const headerBase64Url = parts[0];
  const payloadBase64Url = parts[1];

  const headerJson = base64UrlDecode(headerBase64Url);
  const payloadJson = base64UrlDecode(payloadBase64Url);

  let header;
  let payload;

  try {
    header = JSON.parse(headerJson);
    payload = JSON.parse(payloadJson);
  } catch (error) {
    throw new Error('Invalid token');
  }

  return {
    header: header,
    payload: payload
  };
}

export function isTokenExpired(token) {
  const decoded = decodeJwt(token);
  const now = new Date().getTime() / 1000 ;
  return now > decoded.payload.exp;
}

