import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DimensionType } from "../../api/dimensionType";
import Help from "./Help";
import Timeline, { ViewerSmallButton } from "./Timeline";
// import { DEMO_PROJECT_ID } from "../../config";
import CameraViewControl from "./CameraViewControl";
import MeasureControl from "./MeasureControl";
import ResetButton from "./ResetButton";
import useAuthInfo from "../../hooks/useAuthInfo";
const TopBar = ({ onCreateMeasure }) => {
  const navigate = useNavigate();
  const { projectId, clientId, stateId } = useParams();
  const { isStateLinkLevel } = useAuthInfo();

  const measureMode = useSelector((state) => state.measures.mode);

  const handleClickBack = (e) => {
    navigate(`/clients/${clientId}/projects/${projectId}`);
  };

  const handleClickSwitchMode = (e) => {
    const viewerType = measureMode === DimensionType.D2 ? "pointcloud" : "map";
    navigate(
      `/clients/${clientId}/projects/${projectId}/${viewerType}/${stateId}`
    );
  };

  return (
    <React.Fragment>
      <div className="absolute top-[-32px] left-0 w-full h-[160px] pointer-events-none bg-gradient-to-b from-white to-transparent"></div>
      <div className="absolute top-[13px] left-0 w-full h-[60px] flex flex-row items-center gap-[28px] pointer-events-none">
        {isStateLinkLevel ? (
          <div className="w-[25px]"></div>
        ) : (
          <ViewerSmallButton
            className={"ml-[50px] pointer-events-auto w-[50px] "}
            onClick={handleClickBack}
          >
            <img
              src="/images/viewer_exit.svg"
              className="w-[26px] h-[17px] mx-[20px]"
              alt=""
            />
          </ViewerSmallButton>
        )}
        <ViewerSmallButton
          className={"w-[50px] pointer-events-auto"}
          onClick={handleClickSwitchMode}
        >
          <span className="text-[16px]">
            {measureMode === DimensionType.D2 ? "3D" : "2D"}
          </span>
        </ViewerSmallButton>
        <div className="shadow-sm w-[4px] h-[32px] bg-white rounded">
          {/* <img
            src="/images/viewer_horizontal_delimiter.svg"
            className="h-[32px] w-[4px]"
          /> */}
        </div>
        <div
          className="grow overflow-x-hidden py-[5px]"
          style={{ pointerEvents: "none" }}
        >
          <Timeline></Timeline>
        </div>
      </div>
      <div className="absolute top-[100px] left-[50px]">
        <MeasureControl onCreateMeasure={onCreateMeasure}></MeasureControl>
      </div>
      <div className="absolute left-[59px] bottom-[64px] flex gap-[20px] flex-col">
        {measureMode === DimensionType.D3 && <ResetButton></ResetButton>}
        {measureMode === DimensionType.D3 && (
          <CameraViewControl></CameraViewControl>
        )}
      </div>
      <div className="absolute right-[60px] bottom-[64px]">
        <Help></Help>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
