import React, { useState, useEffect, useRef } from "react";
import InputLabel from "./InputLabel";

const SelectBox = ({
  label,
  options,
  selectedOption,
  onChange,
  lightMode,
  className,
}) => {
  const [selected, setSelected] = useState(selectedOption || options[0]);
  const [collapsed, setCollapsed] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    setSelected(selectedOption);
    console.log("selectedOption", selectedOption);
    console.log(options)
  }, [selectedOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setCollapsed(true);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (option) => {
    const { value } = option;
    setSelected(value);
    onChange(option);
    setCollapsed(true);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const selectClass = `p-2 px-5 font-bold text-700 text-base w-full`;
  const arrowClass = `transform transition-transform duration-300 ${
    collapsed ? "rotate-0" : "rotate-90"
  }`;

  const bg = lightMode ? "bg-white" : "bg-bg1";

  return (
    <div
      ref={ref}
      className={`flex flex-col items-start w-full ${className || ""}`}
    >
      <InputLabel className="mr-2">{label}</InputLabel>
      <div className={`flex flex-wrap gap-[12px]`}>
        {options.map((option) => (
          <button
            className={`rounded-full text-center px-5 py-4 font-bold text-700 ${selected === option.value ? "bg-primary text-white" : "bg-bg1"}`}
            key={option.value}
            onClick={() => handleChange(option)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectBox;

// const SelectBox = ({ label, options, selectedOption, onChange, lightMode, className }) => {
//   const [selected, setSelected] = useState(selectedOption || options[0]);
//   const [collapsed, setCollapsed] = useState(true);
//   const ref = useRef(null);

//   useEffect(() => {
//     setSelected(selectedOption);
//   }, [selectedOption]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (ref.current && !ref.current.contains(event.target)) {
//         setCollapsed(true);
//       }
//     };

//     // Bind the event listener
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleChange = (option) => {
//     const { value } = option;
//     setSelected(value);
//     onChange(option);
//     setCollapsed(true);
//   };

//   const toggleCollapse = () => {
//     setCollapsed(!collapsed);
//   };

//   const selectClass = `p-2 px-5 font-bold text-700 text-base w-full`;
//   const arrowClass = `transform transition-transform duration-300 ${
//     collapsed ? "rotate-0" : "rotate-90"
//   }`;

//   const bg = lightMode ? "bg-white" : "bg-bg1";

//   return (
//     <div ref={ref} className={`flex flex-col items-start w-full ${className || ""}`}>
//       <InputLabel className="mr-2">{label}</InputLabel>
//       <div
//         className={`relative ${bg} py-[7px] w-full ${
//           collapsed ? "rounded-full" : "rounded-[25px] rounded-b-none"
//         }`}
//       >
//         <button onClick={toggleCollapse} className={selectClass}>
//           <div className="flex items-center gap-2">
//             <svg
//               className={arrowClass}
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//               width="20"
//               height="20"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M14 5l7 7m0 0l-7 7m7-7H3"
//               />
//             </svg>
//             {options.find((option) => option.value === selected)?.label}
//           </div>
//         </button>
//         {!collapsed && (
//           <div className={`absolute z-10 w-full rounded-b-[25px] pb-2 ${bg}`}>
//             {options.map((option) => (
//               <button
//                 className="block w-full text-center text-sm px-2 py-1 hover:font-bold text-700"
//                 key={option.value}
//                 onClick={() => handleChange(option)}
//               >
//                 {option.label}
//               </button>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SelectBox;
