import React from "react";
import ToggleButton from "./ToggleButton";
import InputLabel from "./InputLabel";

function TogglesSelect({
  label,
  options,
  selectedOption,
  onChange,
  lightMode,
  className,
  extraError,
  disabled,
}) {
  return (
    <div className={className}>
      <InputLabel>{label}</InputLabel>
      <div
        className="flex flex-row gap-[15px] 
      flex-wrap
      "
      >
        {options.map((option, index) => (
          <ToggleButton
            key={index}
            label={option?.label || option}
            disabled={option?.disabled || disabled}
            onToggle={
              disabled || option?.disabled ? () => {} : () => onChange(option)
            }
            checked={
              selectedOption === option?.value || selectedOption === option
            }
            lightMode={lightMode}
          />
        ))}
      </div>
      {extraError && (
        <div className="text-red-500 text-xs mt-[5px]">
          {extraError.message}
        </div>
      )}
    </div>
  );
}

export default TogglesSelect;
