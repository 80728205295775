import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLinkQuery, useRegerateLinkMutation } from "../../api/loginApi";
import { namespaces } from "../../consts/i18n";
import {
  createLinkInputPathFromData, createResultLinkFromDataAndToken, getObjectFromIds
} from "../../utils/linkUtils";
import { isString } from "../../utils/stringUtils";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogWrapper from "./DialogWrapper";
import MessageDialog from "./MessageDialog";

const ShareLinkDialog = ({ isOpen, data, onClose, deactivateDisabled, disabled }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onClose();
  };

  const { t } = useTranslation(namespaces.pages);
  const ids = getObjectFromIds(data);
  const { data: linkData, refetch, error: linkError } = useLinkQuery(ids);
  const [
    regenerateLink,
    { isLoading: isRegeneratingLink, error: regenerateLinkError },
  ] = useRegerateLinkMutation();

  const [isClipboardCopied, setIsClipboardCopied] = React.useState(false);

  useEffect(() => {
    setInputValue(t("shareLinkDialog.linkLoading"));
    setIsClipboardCopied(false);
    refetch();
  }, [isOpen]);

  const [inputValue, setInputValue] = React.useState(" ");

  useEffect(() => {
    if (linkData?.token && isString(linkData.token)) {
      // console.log("generated token: "  +  linkData.token);
      setInputValue(createResultLinkFromDataAndToken(data, linkData.token));
    }
  }, [linkData, setInputValue, data]);

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleRequestDeactivate = (e) => {
    e.preventDefault();
    setIsDeactivateWarningDialogOpen(true);
  };

  const handleDeactivate = async () => {
    await regenerateLink(ids);
    await refetch();
    setIsDeactivateWarningDialogOpen(false);
    setIsClipboardCopied(false);
  };

  const handleCopyLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputValue);
    setIsClipboardCopied(true);
  };

  const [isDeactivateWarningDialogOpen, setIsDeactivateWarningDialogOpen] =
    React.useState(false);

  const error = linkError || regenerateLinkError;

  return isOpen ? (
    <DialogWrapper onClick={handleClose}>
      {/* {error && <Toast message={error.error} type="error"></Toast>} */}
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleClose}></CloseButton>
        <div className="flex flex-col gap-y-[39px]">
          <div>
            <h3 className="text-xxxl font-bold text-center px-[26px] text-ellipsis overflow-hidden">
              {t("shareLinkDialog.title")} {`"${data.name}"`}
            </h3>
          </div>

          <div>
            <div className="relative">
              <input
                value={disabled ? "http://localhost:3000/link/1" : inputValue}
                disabled={true}
                className={"rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1 text-[#B3B3B3] pr-[56px]" + (disabled ? " cursor-not-allowed blur-sm" : "")}
                id="name"
                type="text"
                placeholder=""
                
              ></input>
              <button
                className="absolute right-0 top-0 h-full w-[56px] flex justify-center items-center active:opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleCopyLink}
                disabled={disabled}
              >
                <img src="/images/copy.svg" alt="copy" className="" />
              </button>
            </div>
            {isClipboardCopied && (
              <div className="mx-[10px] my-[5px] text-sm text-center">
                {t("shareLinkDialog.linkCopiedToClipboard")}
              </div>
            )}
          </div>

          
            <TextButton
              className={"h-[56px]"}
              secondary
              label={t("shareLinkDialog.deactivate")}
              onClick={handleRequestDeactivate}
              disabled={deactivateDisabled}
            />
          

          <div className="flex flex-row items-center justify-center grow-0">
            <div>
              <TextButton
                disabled={!inputValue}
                className={"h-[56px]"}
                label={t("shareLinkDialog.close")}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
      <MessageDialog
        isSubmitLoading={isRegeneratingLink}
        isOpen={isDeactivateWarningDialogOpen}
        title={t("shareLinkDialog.deactivateWarning")}
        submitText={t("shareLinkDialog.submitDeactivate")}
        cancelText={t("shareLinkDialog.notDeactivate")}
        onCancel={() => setIsDeactivateWarningDialogOpen(false)}
        onSubmit={handleDeactivate}
      />
    </DialogWrapper>
  ) : null;
};

export default ShareLinkDialog;
