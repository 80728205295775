import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";

const Toast = ({
  message,
  type,
  onAfterHide,
  className,
  hideTimeout = 10000,
}) => {
  const toastRef = useRef(null);

  const { t } = useTranslation(namespaces.pages);

  // console.log("toast message: " + message);

  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgressPercent((prev) => prev + 1);
    }, hideTimeout / 100);
    return () => clearTimeout(timer);
  }, [progressPercent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      toastRef.current.classList.add("opacity-0");
      toastRef.current.classList.add("hidden")
      toastRef.current.classList.remove("opacity-100");
      if (onAfterHide) onAfterHide();
    }, hideTimeout);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={toastRef}
      className={`fixed top-0 mx-3 my-3 p-3 rounded-lg shadow-lg z-[1000] max-w-[240px] text-${
        type === "error" ? "red-400" : "green-400"
      } bg-white opacity-100 transition duration-500 ease-in-out ${
        className || ""
      }`}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          {type === "error" ? (
            <svg
              className="fill-current h-6 w-6 text-red-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
            </svg>
          ) : (
            <svg
              className="fill-current h-6 w-6 text-green-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          )}
        </div>
        <div>
          <p
            className={`font-bold text-${
              type === "error" ? "red-400" : "green-400"
            }`}
          >
            {type === "error" ? t("toast.error") : t("toast.success")}:
          </p>
          <p
            className={`text-sm text-${
              type === "error" ? "red-400" : "green-400"
            }`}
          >
            {message}
          </p>
        </div>
        <div>
          <svg
            className="fill-current h-[14px] w-[14px] text-gray-500 ml-auto cursor-pointer mt-[-5px] mr-[-5px] ml-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={() => {
              toastRef.current.classList.add("opacity-0");
              toastRef.current.classList.remove("opacity-100");
              if (onAfterHide) onAfterHide();
            }}
          >
            <path d="M14.348 14.849a1 1 0 0 1-1.414 1.413l-4.243-4.243-4.243 4.243a1 1 0 1 1-1.414-1.413l4.243-4.243-4.243-4.243a1 1 0 1 1 1.414-1.413l4.243 4.243 4.243-4.243a1 1 0 0 1 1.414 1.413l-4.243 4.243 4.243 4.243z" />
          </svg>
        </div>
      </div>
      <div className="h-1 bg-gray-200 mt-1 rounded">
        <div
          className={`h-1 rounded bg-gray-300`}
          style={{
            transition: "width 0.05s linear",
            width: `${progressPercent}%`,
            // backgroundColor: `${
            //   type === "error" ? "#ffaa00" : "#00ffaa"
            // }`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Toast;
