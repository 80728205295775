import React from "react";

function ToggleButton({ label, onToggle, checked, disabled }) {
  const handleClick = () => {
    onToggle(!checked);
  };

  return (
    <button
      disabled={disabled && !checked}
      className={
        (checked ? " bg-primary text-white" : " bg-white text-black") +
        " rounded-full h-[36px] p-1 px-6 text-base font-bold hover:shadow-lg flex items-center justify-center gap-[4px] disabled:opacity-50 disabled:cursor-not-allowed"
      }
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

export default ToggleButton;
