import { createSlice } from "@reduxjs/toolkit";
import { managementApi } from "../../api/managementApi";
export const initialState = {
  client: null,
  project: null,
  projectState: null,
};

export const projectSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      managementApi.endpoints.getProject.matchFulfilled,
      (state, { payload }) => {
        state.project = payload;

        // debug
        // state.project.allowedRecordTypes = [
        //   "FAULT",
        //   "AREA",
        //   "PARK",
        //   "CEMETERY",
        //   "GENERIC",
        // ];
      }
    );
    builder.addMatcher(
      managementApi.endpoints.getClient.matchFulfilled,
      (state, { payload }) => {
        state.client = payload;
      }
    );
    builder.addMatcher(
      managementApi.endpoints.getProjectState.matchFulfilled,
      (state, { payload }) => {
        state.projectState = payload;
      }
    );
  },
});

export default projectSlice.reducer;
