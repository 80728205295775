import React from "react";
import { ViewerSmallButton } from "./Timeline";
import { useSelector } from "react-redux";
import { DimensionType } from "../../api/dimensionType";
import { useTranslation } from "react-i18next";
import CloseButton from "../buttons/CloseButton";
import { namespaces } from "../../consts/i18n";


const Help = () => {
  const measureMode = useSelector((state) => state.measures.mode);

  const { t } = useTranslation(namespaces.viewer);
  const [helpVisible, setHelpVisible] = React.useState(false);

  const handleClickHelp = (e) => {
    setHelpVisible(!helpVisible);
  };

  return (
    <React.Fragment>
      {helpVisible ? (
        <div className="absolute right-[0px] bottom-[0px]">
          <div className="w-[235px] bg-white rounded-lg shadow-lg pt-[10px] ">
            <CloseButton
              onClick={handleClickHelp}
              className="absolute top-0 right-0 mt-[0px] p-[6px]"
            />
            <div className="flex flex-col gap-[20px] p-[20px] m-[5px]">
              <h5 className="text-center font-bold text-700">
                {t("help.controlTitle")}
              </h5>
              {helpItems
                .filter(
                  (item) =>
                    item.measureMode === measureMode &&
                    item.subcategory === "control"
                )
                .map((item) => (
                  <HelpItem
                    title={t(item.title)}
                    description={t(item.description)}
                    icon={item.icon}
                  />
                ))}
            </div>
            <div className="flex flex-col gap-[20px] p-[20px] m-[5px]">
              <h5 className="text-center font-bold text-700">
                {t("help.measureTitle")}
              </h5>
              {helpItems
                .filter(
                  (item) =>
                    item.measureMode === measureMode &&
                    item.subcategory === "measure"
                )
                .map((item) => (
                  <HelpItem
                    title={t(item.title)}
                    description={t(item.description)}
                    icon={item.icon}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <ViewerSmallButton
          onClick={handleClickHelp}
          className={"w-[36px] h-[36px] px-[8px]"}
        >
          <img
            src="/images/viewer_help.svg"
            className="w-[29px] h-[13px] object-contain"
          />
        </ViewerSmallButton>
      )}
    </React.Fragment>
  );
};

const helpItems = [
  {
    title: "help.controlRotate3D",
    description: "help.controlRotate3DDescription",
    icon: "/images/viewer_LMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "control",
  },
  {
    title: "help.controlMove3D",
    description: "help.controlMove3DDescription",
    icon: "/images/viewer_RMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "control",
  },
  {
    title: "help.controlMove2D",
    description: "help.controlMove2DDescription",
    icon: "/images/viewer_LMB.svg",
    measureMode: DimensionType.D2,
    subcategory: "control",
  },
  {
    title: "help.controlZoom3D",
    description: "help.controlZoomDescription3D",
    icon: "/images/viewer_MMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "control",
  },
  {
    title: "help.controlZoom2D",
    description: "help.controlZoomDescription2D",
    icon: "/images/viewer_MMB.svg",
    measureMode: DimensionType.D2,
    subcategory: "control",
  },
  {
    title: "help.zoomTo",
    description: "help.zoomToDescription",
    icon: "/images/viewer_LMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "control",
  },
  {
    title: "help.measureAddPoint2D",
    description: "help.measureAddPointDescription2D",
    icon: "/images/viewer_LMB.svg",
    measureMode: DimensionType.D2,
    subcategory: "measure",
  },
  {
    title: "help.measureAddPoint3D",
    description: "help.measureAddPointDescription3D",
    icon: "/images/viewer_LMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "measure",
  },
  {
    title: "help.endMeasure2D",
    description: "help.endMeasureDescription2D",
    icon: "/images/viewer_RMB.svg",
    measureMode: DimensionType.D2,
    subcategory: "measure",
  },
  {
    title: "help.endMeasure3D",
    description: "help.endMeasureDescription3D",
    icon: "/images/viewer_RMB.svg",
    measureMode: DimensionType.D3,
    subcategory: "measure",
  },
];

const HelpItem = ({ title, description, icon }) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col gap-[5px] ">
        <p className="text-primary text-700 font-bold overflow-hidden text-[14px] leading-[17px]">
          {title}
        </p>
        <p className="text-sm text-400 overflow-hidden text-[10px]">{description}</p>
      </div>
      <img className="ml-[24px]" src={icon} width={23} />
    </div>
  );
};

export default Help;