import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { namespaces } from "../../consts/i18n";
import { toReadableDate } from "../../utils/date";
import { convertBytesToFileSizeString } from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import Card from "./Card";

function MeasureCard(props) {
  const {
    measure,
    onClick,
    onEdit,
    onShare,
    onDelete,
    onFilesClick,
    ...otherProps
  } = props;

  const navigate = useNavigate();

  const { t } = useTranslation(namespaces.pages);

  const handleClick = (e) => {
    e.preventDefault();
    // navigate(`measure/${measure.id}`);
    onClick(measure);
  };

  const handleCreateLink = (e) => {
    e.stopPropagation();
    onShare(measure);
  };

  const handleEditName = (e) => {
    e.stopPropagation();
    onEdit(measure);
  };

  const handleDeleteMeasure = (e) => {
    e.stopPropagation();
    onDelete(measure);
  };

  const handleFilesClick = (e) => {
    e.stopPropagation();
    onFilesClick(measure);
  };

  const tags = [
    `${t("projectPage.measurement")} • ${convertBytesToFileSizeString(
      computeSizeOfState(measure)
    )}`,
  ];
  // const handleOpenMeasure = (e) => {
  //   e.stopPropagation();
  // };

  return (
    <Card
      title={measure.name}
      tags={tags}
      pretitle={t("projectPage.measurement")}
      subtitle={`vytvořeno ${toReadableDate(measure.createdAt)}`}
      onClick={handleClick}
      actions={[
        // <CircleButton
        //   icon="/images/link.svg"
        //   onClick={handleOpenMeasure}
        // ></CircleButton>,
        measure.files?.length > 0 && (
          <CircleButton
            icon="/images/files.svg"
            onClick={handleFilesClick}
          ></CircleButton>
        ),
        onShare && (
          <CircleButton
            icon="/images/link.svg"
            onClick={handleCreateLink}
          ></CircleButton>
        ),
        onEdit && (
          <CircleButton
            icon="/images/edit.svg"
            onClick={handleEditName}
          ></CircleButton>
        ),
        onDelete && (
          <CircleButton
            icon="/images/trash.svg"
            onClick={handleDeleteMeasure}
          ></CircleButton>
        ),
      ]}
      {...otherProps}
    ></Card>
  );
}

const computeSizeOfState = (state) => {
  let size = 0;

  if (state?.files) {
    state.files.forEach((file) => {
      if ([".las", ".tif", ".tfw"].includes(file.extension?.toLowerCase()))
        size += file.size;
    });
  }

  return size;
};

export default MeasureCard;
