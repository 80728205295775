import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { useEffect } from "react";
import { DimensionType } from "../api/dimensionType";
import { RecordAreaType, RecordType } from "../api/consts";
import { setRecordAreaFilterTypes } from "../redux/records/recordsReducer";

// get values from RecordAreaType
const possibleFilters = Object.values(RecordAreaType);

export const getFilters = (allowedRecordTypes) => {
  if (allowedRecordTypes.length === 0) {
    return [];
  }

  if (allowedRecordTypes.includes(RecordType.AREA)) {
    return possibleFilters;
  }
  return [];
};

const useFilterRecords = () => {
  const dispatch = useDispatch();

  const filterTypes = useSelector((state) => state.records.filter.areaTypes);

  useEffect(() => {
    setFilterTypes([]);
  }, []);

  const setFilterTypes = (types) => {
    dispatch(setRecordAreaFilterTypes(types));
  };

  const toggleFilterType = useCallback(
    (type, { on = false, off = false, toggle = undefined } = {}) => {
      if (on) {
        toggle = true;
      }

      if (off) {
        toggle = false;
      }

      if (toggle === undefined) {
        toggle = !filterTypes.includes(type);
      }

      if (toggle) {
        setFilterTypes([...filterTypes, type]);
      } else {
        setFilterTypes(filterTypes.filter((t) => t !== type));
      }
    },
    [filterTypes]
  );

  return [filterTypes, toggleFilterType];
};

export default useFilterRecords;
