class Measure3DHelper {
  static initMarkers(potreeMeasure, coordinates) {
    for (let i = 0; i < coordinates.length; i++) {
      potreeMeasure.addMarker(coordinates[i]);
    }
  }

  static serialize(potreeMeasure) {
    return {
      points: potreeMeasure.points.map((point) => point.position.toArray()),
    };
  }
}

export default Measure3DHelper;