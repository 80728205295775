import React, { useState, useEffect, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { addMessage } from "../redux/messages/messagesReducer";
import uploadApi from "../api/uploadApi";
import { convertIframeToHtmlStringWithStyles } from "../utils/print";
import { useLazyGetRecordQuery } from "../api/managementApi";

const useRecordPrinting = (record) => {
  const dispatch = useDispatch();
  const [printData, setPrintData] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printTrigger, setPrintTrigger] = useState(false);

  const [getRecord] = useLazyGetRecordQuery();

  const printMethod = useCallback(
    async (printIframe) => {
      try {
        setIsPrinting(true);
        const html = convertIframeToHtmlStringWithStyles(printIframe);

        const attachments = printData?.attachments || [];

        const blobData = await uploadApi.createPdfBlob({
          html: html,
          attachements: attachments,
        });
        const blob = new Blob([blobData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = record.name + ".pdf";
        link.click();
      } catch (err) {
        console.log(err);
        dispatch(addMessage({ type: "error", message: err.message }));
      }
      setIsPrinting(false);
    },
    [printData, dispatch, record]
  );

  const printRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: async () => {},
    print: printMethod,
    onAfterPrint: () => {},
  });

  useEffect(() => {
    if (printData && printTrigger) {
      handlePrint();
      setPrintTrigger(false);
    }
  }, [printData, handlePrint, printTrigger]);

  const handleExport = useCallback(async () => {
    try {
      const response = await getRecord({ id: record.id });
      setPrintData({
        record: response?.data,
        attachments: response?.data?.photos?.map((photo) => {
          return photo.id;
        }),
      });
      setTimeout(() => {
        setPrintTrigger(true);
      }, 500); // hack to wait for the iframe to load
    } catch (err) {
      dispatch(addMessage({ type: "error", message: err.message }));
    }
  }, [record, handlePrint, setPrintData, setPrintTrigger, dispatch]);

  return {
    printRef,
    isPrinting,
    printData,
    handleExport,
  };
};

export default useRecordPrinting;
