import React, { useEffect } from "react";
import TextInput, { TextInputSmall, TextInputPrint } from "../inputs/TextInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { RecordAreaType } from "../../api/consts";
import { computeArea, flattenPoints } from "../../utils/geo";
import { formatArea } from "../../features/measures/polygonArea2D";
import TogglesSelect from "../inputs/TogglesSelect";

function FacilityFormPrint({ data }) {
  const { t } = useTranslation(namespaces.viewer);

  const areaOptions = Object.values(RecordAreaType).map((area) => ({
    value: area,
    label: t(`form.${area}`),
  }));

  return (
    <div className="flex flex-col gap-[20px] rounded-xl pb-[25px]">
      <div
        className="
          flex flex-row items-start justify-between
          w-full gap-[24px]
        "
      >
        <TogglesSelect
          label={t("form.type")}
          disabled={true}
          options={areaOptions}
          selectedOption={data.type}
          onChange={() => {}}
        />
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <TextInputPrint
            disabled={true}
            label={t("form.size")}
            value={data.size ? formatArea(data.size) : ""}
            onChange={() => {}}
          />
        </div>
      </div>
      <div>
        <TextInputPrint
          disabled={true}
          label={t("form.note")}
          value={data.note}
          onChange={() => {}}
        />
      </div>
    </div>
  );
}

export default FacilityFormPrint;
