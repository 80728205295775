import { createSlice } from "@reduxjs/toolkit";
import { computeApi } from "../../api/computeApi";
// {
// name:
// toBeStored: true/false // if true, then it is stored in the server
// }

export const initialState = {
  nonStored: [],
  hidden: [],
  selected: [],
  editable: [],
  search: {
    text: "",
  },
  filter: {
    areaTypes: [],
  },
  changes: {}, // user need to save changes to server, so we need to keep track of changes, it is map with key as id and value as changes
};

export const recordsSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    addNonStoredRecord: (state, action) => {
      state.nonStored.push({ ...action.payload, toBeStored: false });

      // automatically add to editable
      state.editable.push(action.payload.id);
    },
    removeNonStoredRecord: (state, action) => {
      state.nonStored = state.nonStored.filter((r) => r.id !== action.payload);

      // automatically remove from editable
      state.editable = state.editable.filter((id) => id !== action.payload);
    },
    clearNonStoredRecords: (state, action) => {
      state.nonStored = [];
    },
    showRecord: (state, action) => {
      state.hidden = state.hidden.filter((id) => id !== action.payload);
    },
    hideRecord: (state, action) => {
      state.hidden.push(action.payload);
    },
    selectRecord: (state, action) => {
      state.selected.push(action.payload);
    },
    deselectRecord: (state, action) => {
      state.selected = state.selected.filter((id) => id !== action.payload);
    },
    setRecordSearchText: (state, action) => {
      state.search.text = action.payload;
    },
    setRecordAreaFilterTypes: (state, action) => {
      state.filter.areaTypes = action.payload;
    },
    addChangeForRecord: (state, action) => {
      state.changes[action.payload.id] = {
        ...state.changes[action.payload.id],
        ...action.payload,
      }
    },
    clearRecordChanges: (state, action) => {
      delete state.changes[action.payload];
    },
    addToEditableRecords: (state, action) => {
      if (state.editable.includes(action.payload)) return;
      state.editable.push(action.payload);
    },
    removeFromEditableRecords: (state, action) => {
      state.editable = state.editable.filter((id) => id !== action.payload);
    },
  },
});

export const {
  addNonStoredRecord,
  removeNonStoredRecord,
  clearNonStoredRecords,
  showRecord,
  hideRecord,
  selectRecord,
  deselectRecord,
  setRecordSearchText,
  setRecordAreaFilterTypes,
  addChangeForRecord,
  clearRecordChanges,
  addToEditableRecords,
  removeFromEditableRecords,
} = recordsSlice.actions;

export default recordsSlice.reducer;
