import React from "react";
import { useNavigate } from "react-router-dom";
const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [imageSrc, setImageSrc] = React.useState("/images/drone-not-found.jpg");

  return (
    <div
      className="
        w-full h-full
        flex flex-col items-center justify-center gap-4
      "
    >
      <img
        src={imageSrc}
        onMouseEnter={() => setImageSrc("/images/drone-not-found-2.jpg")}
        onMouseLeave={() => setImageSrc("/images/drone-not-found.jpg")}
        alt="404"
        className={`h-1/2 rounded-lg transition duration-500 ease-in-out`}
      />
    
      <div
        className="
        w-1/2
        flex flex-col items-center justify-center
        rounded-lg
        p-4
        "
      >
        <h1
          className="
          text-2xl
          text-gray-500
          "
        >
          <b>Stránka nenalezena.</b> Dron se asi ztratil na cestě sem. Doporučujeme vám se vrátit{" "}
          <a
            href="#"
            onClick={handleGoBack}
            className="underline decoration-1
            cursor-pointer hover:text-red-300
          "
          >
            zpět
          </a>
          .
        </h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
