import React, { useEffect } from "react";
import { PotreeContext } from "../providers/potreeProvider";
import useScripts from "./useScripts";

const potreeLibsUrls = [
  "/generated/jquery/jquery-3.1.1.min.js",
  "/generated/spectrum/spectrum.js",
  "/generated/jquery-ui/jquery-ui.min.js",
  "/generated/other/BinaryHeap.js",
  "/generated/tween/tween.min.js",
  "/generated/d3/d3.js",
  "/generated/proj4/proj4.js",
  "/generated/openlayers3/ol.js",
  "/generated/i18next/i18next.js",
  "/generated/jstree/jstree.js",
  "/generated/potree/potree.js",
  "/generated/plasio/js/laslaz.js",
];

const useInitPotreeViewer = () => {
  const initialized = React.useRef(false);
  const potreeRenderAreaRef = React.useRef(null);
  const { state, dispatch } = React.useContext(PotreeContext);

  const allLoaded = useScripts(
    potreeLibsUrls.map((url) => `${process.env.PUBLIC_URL}${url}`)
  );

  useEffect(() => {
    if (!allLoaded) return;
    if (!potreeRenderAreaRef.current) return;

    if (initialized.current === false) {
      initialized.current = true;
      const Potree = window.Potree;
      const viewer = new Potree.Viewer(potreeRenderAreaRef.current);
      // window.VIEWER = viewer;
      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(5_000_000);
      viewer.loadSettingsFromURL();
      viewer.setDescription("");
      viewer.setBackground("none");

      // viewer.setShowBoundingBox(true);
      // viewer.loadGUI(() => {
      //   viewer.setLanguage("en");
      //   viewer.toggleSidebar();
      // });

      // console.log(Potree);

      dispatch({ type: "SET_POTREE_VIEWER", payload: viewer });
      dispatch({ type: "SET_POTREE", payload: Potree });

      //window.viewer = viewer;

      return () => {
        // unfortunately, Potree.Viewer does not have a destroy method
      };
    }
  }, [potreeRenderAreaRef, dispatch, allLoaded]);

  return { initialized: initialized.current, potreeRenderAreaRef };
};

export default useInitPotreeViewer;
