import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DimensionType } from "../../../api/dimensionType";
import {
  useCreateMeasureMutation,
  useDeleteMeasureMutation,
  useGetProjectStateQuery,
  useUpdateMeasureMutation,
} from "../../../api/managementApi";
import { namespaces } from "../../../consts/i18n";
import { toolModeType } from "../../../redux/pointCloudViewer/pointCloudViewerReducer";
import CircleButton from "../../buttons/CircleButton";
import CreateMeasureDialog from "../../dialogs/CreateMeasureDialog";
import FilterItem from "../FilterItem";
import MeasureList from "./MeasureList";
import QRCodeLinkDialog from "../../dialogs/QRCodeLinkDialog";
import CreateRecordDialog from "../../dialogs/CreateRecordDialog";
import useGetProjectRecords from "../../../hooks/useGetRecords";
import RecordList from "./RecordList";
import useViewerDisplayMode from "../../../hooks/useViewerDisplayMode";
import { DisplayType } from "../../../redux/viewer/viewerReducer";
import SearchBar from "../../inputs/SearchBar";
import FilterItemWithIcon from "../../inputs/FilterItemWithIcon";
import {
  addNonStoredMeasure,
  removeNonStoredMeasure,
} from "../../../redux/measures2/measures2Reducer";
import useSearchMeasures from "../../../hooks/useSearchMeasures";
import useSearchRecords from "../../../hooks/useSearchRecords";
import DisplayTypeTabs from "./DisplayTypeTabs";
import useFilterMeasures, {
  getFilters,
} from "../../../hooks/useFilterMeasures";
import MeasureFilter from "./MeasureFilter";
import RecordFilter from "./RecordFilter";
const isRecordsEnabledForProject = (project) => {
  const enabled = project?.allowedRecordTypes?.length > 0;
  return enabled;
};

const Sidebar = ({ onCreateMeasure, dimensionType }) => {
  const { projectId, clientId, stateId } = useParams();

  const dispatch = useDispatch();

  const {data: projectState} = useGetProjectStateQuery({
    projectId,
    id: stateId,
  });

  const [displayMode, setDisplayMode] = useViewerDisplayMode();

  const [isOpenCreateMeasureDialog, setIsOpenCreateMeasureDialog] =
    React.useState(false);

  const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = React.useState(true);

  const handleCreateMeasureClick = (name, type = toolModeType.DISTANCE) => {
    setIsOpenCreateMeasureDialog(false);
    onCreateMeasure(name, type);
  };

  const handleCloseQRCodeDialog = () => {
    setIsQRCodeDialogOpen(false);
  };

  const [isCreateRecordDialogOpen, setIsCreateRecordDialogOpen] =
    React.useState(false);
  const [createRecordInitialData, setCreateRecordInitialData] =
    React.useState(null);

  

  const { project } = useSelector((state) => state.info);

  const [createMeasure /* { isLoading: createMeasureLoading }*/] =
    useCreateMeasureMutation();
  const [updateMeasure /*{ isLoading: updateMeasureLoading }*/] =
    useUpdateMeasureMutation();
  const [deleteMeasure /*{ isLoading: deleteMeasureLoading }*/] =
    useDeleteMeasureMutation();

  const { t } = useTranslation(namespaces.viewer);

  const handleStoreClick = useCallback(
    ({ measure, toBeStored }) => {
      if (dimensionType === DimensionType.D2 && toBeStored) {
        setCreateRecordInitialData({ measure });
        setIsCreateRecordDialogOpen(true);
        return;
      }

      if (toBeStored) {
        createMeasure({
          projectId: projectId,
          dimension: dimensionType,
          name: measure.name,
          type: measure.type,
          data: measure.points,
        })
          .unwrap()
          .then((res) => {
            dispatch(removeNonStoredMeasure(measure.id));
          });
      } else {
        deleteMeasure({ id: measure.dbId }).then(() => {
          console.log(measure);
          dispatch(addNonStoredMeasure(measure));
        });
      }
    },
    [dimensionType]
  );

  const [searchFold, setSearchFold] = React.useState(false);

  const handleToggleFoldSearch = () => {
    setSearchFold(!searchFold);
  };

  const [measureSearchText, setMeasureSearchText] = useSearchMeasures();
  const [recordsSearchText, setRecordsSearchText] = useSearchRecords();

  return (
    <div className="max-h-screen h-screen flex flex-col overflow-y-hidden">
      {dimensionType === DimensionType.D2 &&
        isRecordsEnabledForProject(project) && (
          <div className="h-[54px] max-h-[54px]">
            <DisplayTypeTabs></DisplayTypeTabs>
          </div>
        )}
      <div className="pt-[32px] pl-[40px] pr-[20px] flex flex-col grow" style={{height: "calc(100vh - 54px)"}}>
        <div className="flex flex-row justify-between mb-[10px]">
          <div className="max-w-[400px] w-full">
            <div className="flex flex-row justify-between">
              <h3 className="text-3xl font-bold text-700 m-0">
                {t("sidebar.title")} {project?.name}
              </h3>
              <CircleButton
                icon="/images/sidebar_fold.svg"
                primary
                // disabled={disabled}
                className={`w-[29px] h-[29px] transform  ${
                  searchFold ? "rotate-[180deg]" : "rotate-0"
                } animate-transition transition-all
                `}
                iconSize="12px"
                onClick={handleToggleFoldSearch}
              ></CircleButton>
            </div>
            <span className="text-sm font-bold text-700 m-0">
              {projectState?.name} - {t(`sidebar.view.${dimensionType}`)}
            </span>
          </div>
          {/* <CircleButton
          primary
          className={"w-[43px] h-[43px]"}
          icon={"/images/sidebar_add.svg"}
          onClick={() => setIsOpenCreateMeasureDialog(true)}
        /> */}
        </div>
        <div
          /*animate folding continously*/
          className={`transition-all ease-in-out ${
            searchFold ? "duration-200 overflow-hidden" : "duration-300 delay-150"
          } ${searchFold ? "max-h-0" : "max-h-[1000px]"}`}
        >
          <SearchBar
            onSearch={(searchText) => {
              if (displayMode === DisplayType.MEASURES) {
                setMeasureSearchText(searchText);
              }
              if (displayMode === DisplayType.RECORDS) {
                setRecordsSearchText(searchText);
              }
            }}
            value={
              displayMode === DisplayType.MEASURES
                ? measureSearchText
                : recordsSearchText
            }
          ></SearchBar>

          {displayMode === DisplayType.MEASURES && (
            <MeasureFilter dimensionType={dimensionType} />
          )}
          {displayMode === DisplayType.RECORDS && <RecordFilter />}
        </div>
        <div className="grow overflow-y-auto pr-[4px]">
          {displayMode === DisplayType.MEASURES && (
            <MeasureList
              filter={(measure) => {
                return true;
                // if (checkedFilters.length === 0) return true;
                // // if satisfy all filters return true
                // return checkedFilters.every((filter) => {
                //   switch (filter) {
                //     case "saved":
                //       return measure.toBeStored;
                //     case "distance":
                //       return measure.type === toolModeType.DISTANCE;
                //     case "area":
                //       return measure.type === toolModeType.AREA;
                //     case "volume":
                //       return measure.type === toolModeType.VOLUME;
                //     default:
                //       return false;
                //   }
                // });
              }}
              onStoreClick={handleStoreClick}
            ></MeasureList>
          )}

          {displayMode === DisplayType.RECORDS && <RecordList></RecordList>}
        </div>
        <CreateMeasureDialog
          dimensionType={dimensionType}
          isOpen={isOpenCreateMeasureDialog}
          onClose={() => setIsOpenCreateMeasureDialog(false)}
          onCreate={handleCreateMeasureClick}
        ></CreateMeasureDialog>
        <CreateRecordDialog
          project={project}
          isOpen={isCreateRecordDialogOpen}
          initialData={createRecordInitialData}
          onClose={() => setIsCreateRecordDialogOpen(false)}
        ></CreateRecordDialog>
      </div>
    </div>
  );
};

export default Sidebar;
