import React, { useEffect } from "react";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogTitle from "./DialogTitle";
import DialogWrapper from "./DialogWrapper";
import TextInput from "../inputs/TextInput";

const InputDialog = ({
  isOpen,
  label,
  title,
  submitText,
  onSubmit,
  filterInputFunction,
  onCancel,
  isSubmitLoading,
  initialValue,
  enableClearInputValueAfterSubmit,
  inputProps,
  disabled,
}) => {
  const [inputValue, setInputValue] = React.useState(initialValue || "");
  const [inputError, setInputError] = React.useState(false);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      if (inputError) return;
      onSubmit(inputValue);
      if (enableClearInputValueAfterSubmit) {
        setInputValue("");
      }
    },
    [
      enableClearInputValueAfterSubmit,
      inputProps?.pattern,
      inputValue,
      onSubmit,
      inputError,
    ]
  );

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-y-[39px]">
          <DialogTitle title={title} />

          <TextInput
            label={label}
            value={inputValue || ""}
            onChange={setInputValue}
            onValidityChange={(valid) => {
              setInputError(!valid);
            }}
            disabled={disabled}
            {...inputProps}
          ></TextInput>
          {/* <div>
            <label
              className="block text-left text-700 font-bold text-xs mb-2 text-primary"
              htmlFor="name"
            >
              {label}
            </label>
            <input
              ref={inputRef}
              onChange={(e) => {
                // enable only inputs that contains characters that is for enabled for folder names

                // inputRef.current.checkValidity() && setInputError(false);
                if (
                  inputProps?.pattern &&
                  inputRef.current &&
                  !inputRef.current.checkValidity()
                ) {
                  setInputError(true);
                } else {
                  setInputError(false);
                }

                const value = e.target.value;
                // const value =
                //   (filterInputFunction &&
                //     filterInputFunction(e.target.value)) ||
                //   e.target.value;

                setInputValue(value);
              }}
              className={`rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1 ${
                inputProps?.pattern ? "invalid:bg-red-100" : ""
              }`}
              id="name"
              type="text"
             
              placeholder=""
              value={inputValue || ""}
              {...inputProps}
            />
            {inputError && (
              <p className="text-red-500 text-xs">
                {t("dialogs.inputFormatError")}
              </p>
            )}
          </div> */}

          <TextButton
            disabled={!inputValue || isSubmitLoading || inputError || inputValue === initialValue || disabled}
            className={"h-[56px]"}
            label={submitText}
            onClick={handleSubmit}
            isLoading={isSubmitLoading}
          />
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default InputDialog;
