import React from "react";
import {
  useLoginMutation,
  loginApi,
  useLazyGetMyselfQuery,
} from "../api/loginApi";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { managementApi } from "../api/managementApi";
import { isTokenExpired } from "../utils/jwt";

const TryParseJSON = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  const linkToken = sessionStorage.getItem("linkToken");

  if (token) {
    try {
      if (!isTokenExpired(token)) {
        return token;
      } else {
        localStorage.removeItem("token");
      }
    } catch (err) {
      localStorage.removeItem("token");
    }
  }

  if (linkToken) {
    return linkToken;
  }

  return null;
};

export const removeAdminTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [token, _setToken] = useState(null);
  const [linkToken, _setLinkToken] = useState(null);
  const [linkTokenLevel, setLinkTokenLevel] = useState(null);
  const [linkAccessData, setLinkAccessData] = useState(null);
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [loginFunc, { isLoading, error }] = useLoginMutation();

  const [triggerGetMyself, { data: myself, error: myselfError }] =
    useLazyGetMyselfQuery();
  const dispatch = useDispatch();

  const setToken = (token) => {
    _setToken(token);
  };

  const login = useCallback(async (email, password) => {
    try {
      // setAuthLoading(true);
      // const testToken = "tokentest";
      // const testUser = { name: "Tony Stark", email: "tony@stark.cz" };
      // setToken(testToken);
      // setUser(testUser);
      // localStorage.setItem("token", testToken);
      // localStorage.setItem("user", JSON.stringify(testUser));
      const data = await loginFunc({ email, password }).unwrap();

      // const data = [{
      //   Token: "token",

      //   id: 1,
      //   name: "Tony Stark",
      //   email: "tonx@start.com",
      // }];
      // dispatch(setCredentials(user));
      // console.log(data);

      if (data && data.token) {
        const { token } = data;
        const user = email;
        if (!token || !user) throw new Error("Invalid credentials");
        setToken(token);
        setUser(user);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(managementApi.util.resetApiState());
        dispatch(loginApi.util.invalidateTags(["Link"]));
      }
    } catch (err) {
      throw err;
    } finally {
      // setAuthLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = TryParseJSON(localStorage.getItem("user"));
    const linkToken = sessionStorage.getItem("linkToken");
    const linkTokenLevel = sessionStorage.getItem("linkTokenLevel");
    const linkAccessData = TryParseJSON(sessionStorage.getItem("linkAccessData"));

    if (token && user) {
      setToken(token);
      setUser(user);
    }

    if (linkToken) {
      _setLinkToken(linkToken);
    }

    if (linkTokenLevel) {
      setLinkTokenLevel(linkTokenLevel);
    }

    if (linkAccessData) {
      setLinkAccessData(linkAccessData);
    }

    setAuthLoading(false);
  }, []);

  const setLinkToken = useCallback(
    ({ token: linkToken, level: linkLevel, accessData: linkAccessData }) => {
      _setLinkToken(linkToken);
      setLinkTokenLevel(linkLevel);
      setLinkAccessData(linkAccessData);

      sessionStorage.setItem("linkToken", linkToken);
      sessionStorage.setItem("linkTokenLevel", linkLevel);
      sessionStorage.setItem("linkAccessData", JSON.stringify(linkAccessData));
    },
    []
  );

  useEffect(() => {
    if (!linkToken && !token) return;
    if (window.location.pathname === "/") return; // do not get myself on homepage

    triggerGetMyself();
  }, [linkToken, token]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        linkToken,
        setLinkToken,
        linkTokenLevel,
        linkAccessData,
        token,
        error,
        isLoading: authLoading,
        isLoginLoading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
