import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AppVersion from "./components/AppVersion";
import ProtectedRoute from "./components/ProtectedRoute";
import ToastMessages from "./components/ToastMessages";
import { namespaces } from "./consts/i18n";
import useAuth from "./hooks/useAuth";
import useDeviceDetector from "./hooks/useDeviceDetector";
import useHandleMessages from "./hooks/useHandleMessages";
import "./localization/config";
import AdminPage from "./pages/AdminPage";
import ClientPage from "./pages/ClientPage";
import HomePage from "./pages/HomePage";
import LinkErrorPage from "./pages/LinkErrorPage";
import LinkRedirectPage from "./pages/LinkRedirectPage";
import LoginPage from "./pages/LoginPage";
import MapViewer from "./pages/MapViewerPage";
import MobileNotSupportedPage from "./pages/MobileNotSupportedPage";
import NotFoundPage from "./pages/NotFoundPage";
import PointCloudViewerPage from "./pages/PointCloudViewerPage";
import ProjectPage from "./pages/ProjectPage";
import { PotreeProvider } from "./providers/potreeProvider";
import AnimateSpin from "./components/buttons/AnimateSpin";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { isMobile } = useDeviceDetector();

  const { user, token, linkToken, isLoading } = useAuth();
  const { i18n } = useTranslation();

  const { t } = useTranslation(namespaces.pages);

  // set sentry user
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        username: user.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  useHandleMessages();

  if (isLoading || !i18n.isInitialized) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-[#e5e7eb]">
        <AnimateSpin size={120} />
      </div>
    );
  }

  return (
    <div className="App w-full h-full bg-bg1 text-[#1E2D2F]">
      <PotreeProvider>
        {/* <MapProvider> */}
        <SentryRoutes>
          <Route path={"/"} element={<HomePage />} />
          <Route
            path={"mobile-not-supported"}
            element={<MobileNotSupportedPage />}
          />
          <Route
            element={
              <ProtectedRoute
                isAllowed={!isMobile}
                redirectPath="/mobile-not-supported"
              />
            }
          >
            <Route path={"login"} element={<LoginPage />} />
            {/* <Route path="demo/clients/:clientId/projects/:projectId/map/:stateId" element={<MapViewer />} />
              <Route path="demo/clients/:clientId/projects/:projectId/pointcloud/:stateId" element={<PointCloudViewerPage />} /> */}
            <Route element={<ProtectedRoute isAllowed={!!token} />}>
              <Route path={"admin"} element={<AdminPage />} />
            </Route>
            <Route
              element={<ProtectedRoute isAllowed={!!token || !!linkToken} />}
            >
              {/* <Route path={"pointcloud/:projectId"}>
                <Route path=":measureId" element={<PointCloudViewerPage />} />
                <Route path="" element={<PointCloudViewerPage />} />
              </Route>
              <Route path={"map/:projectId"}>
                <Route path=":measureId" element={<MapViewer />} />
                <Route path="" element={<MapViewer />} />
              </Route> */}
              <Route path={"clients/:clientId"}>
                <Route path="projects/:projectId">
                  <Route path="map/:stateId" element={<MapViewer />} />
                  <Route
                    path="pointcloud/:stateId"
                    element={<PointCloudViewerPage />}
                  />
                  <Route path="" element={<ProjectPage />} />
                </Route>
                <Route path="" element={<ClientPage />} />
              </Route>
            </Route>
            <Route
              path="link/:clientId/:projectId/:stateId/:token"
              element={<LinkRedirectPage />}
            />
            <Route
              path="link/:clientId/:projectId/:token"
              element={<LinkRedirectPage />}
            />
            <Route
              path="link/:clientId/:token"
              element={<LinkRedirectPage />}
            />
          </Route>

          {/* <Route
              path={LINKS.MOBILE_NOT_SUPPORTED}
              element={<MobileNotSupportedPage />}
            /> */}
          <Route path="link-error" element={<LinkErrorPage />} />
          <Route exact path="*" element={<NotFoundPage />} />
        </SentryRoutes>
        <ToastMessages />
        {/* </MapProvider> */}
      </PotreeProvider>
      <AppVersion></AppVersion>
    </div>
  );
}

export default App;
