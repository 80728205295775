import React, { useMemo, useEffect, useState } from "react";
import { getMapyCzUrlFromKrovak } from "../utils/geo";

import QRCode from "qrcode";
function QRCodeComponent({ data, size = 200 }) {
  const [qrCodeDataUrl, setQRCodeDataUrl] = useState("");

  useEffect(() => {
    // Generate the QR code data URL based on the data
    QRCode.toDataURL(JSON.stringify(data), (err, url) => {
      if (err) {
        console.error(err);
      } else {
        setQRCodeDataUrl(url);
      }
    });
  }, [data]);


  return (
    <div>
      {qrCodeDataUrl && (
        <img
          src={qrCodeDataUrl}
          alt="QR Code"
          width={size}
          height={size}
        />
      )}
    </div>
  );
}

export const QRCodeToMapyCZ = ({ x, y, level, size }) => {
  const url = useMemo(() => {
    if (!x || !y || !level) return null;

    return getMapyCzUrlFromKrovak(x, y, level);
  }, [x, y, level]);

  return <QRCodeComponent data={url} size={size} />;
};
export default QRCodeComponent;
