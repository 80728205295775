import { useCallback, useContext } from "react";
import { PotreeContext } from "../providers/potreeProvider";
const usePotreeViewer = () => {
  const { state } = useContext(PotreeContext);
  const viewer = state.viewer;

  const loadPointCloud = useCallback(
    (url) => {
      state.Potree.loadPointCloud(url, "test", (e) => {
        let scene = viewer.scene;

        const sceneHadPointClouds = scene.pointclouds.length > 0;
        if (scene.pointclouds.length > 0) {
          scene.scenePointCloud.remove(viewer.scene.pointclouds[0]);
          scene.pointclouds.pop();
        }

        const pointcloud = e.pointcloud;
        pointcloud.material.size = 1;
        pointcloud.material.pointSizeType = state.Potree.PointSizeType.ADAPTIVE;
        pointcloud.material.shape = state.Potree.PointShape.CIRCLE;

        viewer.setBackground("none");
        scene.addPointCloud(pointcloud);

        if (!sceneHadPointClouds) {
          resetView();
        }
        // viewer.fitToScreen(0.5);

        //// viewer.setCameraMode(state.Potree.CameraMode.ORTHOGRAPHIC);
      });
    },
    [viewer]
  );

  const setOrthographicCamera = useCallback(() => {
    viewer.setCameraMode(state.Potree.CameraMode.ORTHOGRAPHIC);
  }, [viewer]);

  const setPerspectiveCamera = useCallback(() => {
    viewer.setCameraMode(state.Potree.CameraMode.PERSPECTIVE);
  }, [viewer]);

  const isPerspectiveCamera = useCallback(() => {
    return viewer.scene.cameraMode === state.Potree.CameraMode.PERSPECTIVE;
  }, [viewer]);

  const isOrthographicCamera = useCallback(() => {
    return viewer.scene.cameraMode === state.Potree.CameraMode.ORTHOGRAPHIC;
  }, [viewer]);

  const makeScreenshot = useCallback(() => {
    return viewer.makeScreenshot();
  }, [viewer]);

  const zoomToMeasure = useCallback(
    (id, { duration }) => {
      const measurement = viewer.scene.measurements.find((m) => m.dbId === id);

      const bb = measurement?.createBoundingBoxObject();

      if (bb) {
        viewer.zoomTo(bb, 1.5, duration);
      }
    },
    [viewer]
  );

  const setCameraView = useCallback(
    (view) => { // view F, T, L, R, B, U
      viewer.setView(view);
    },
    [viewer]
  );

  const resetView = useCallback(() => {
    viewer.setTopView();
    viewer.fitToScreen(1);
  }, [viewer]);

  return {
    viewer: state.viewer,
    loadPointCloud,
    zoomToMeasure,
    setOrthographicCamera,
    setPerspectiveCamera,
    isPerspectiveCamera,
    isOrthographicCamera,
    makeScreenshot,
    resetView,
    setCameraView
  };
};

export default usePotreeViewer;
