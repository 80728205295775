
import useAuth from "./useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetMyselfQuery } from "../api/loginApi";

const useLinkAccessCheck = () => {
  const { linkToken, token, linkAccessData, logout, isDemo } = useAuth();
  const { clientId, projectId, stateId } = useParams();
  const isLinkToken = !!linkToken;
  const isToken = !!token;

  const navigate = useNavigate();

  const { data: myself, error: myselfError } = useGetMyselfQuery();
  // const isDemo = clientId === DEMO_CLIENT_ID  && projectId === DEMO_PROJECT_ID && stateId !== null && stateId !== undefined && linkToken !== null && linkToken !== undefined;

  useEffect(() => {
    if(myselfError && !isDemo) {
      logout();
      navigate("/login");
    }
  }, myselfError);

  if (!isToken) {
    if (
      !isLinkToken ||
      !linkAccessData ||
      (linkAccessData.clientId && linkAccessData.clientId !== clientId) ||
      (linkAccessData.projectId && linkAccessData.projectId !== projectId) ||
      (linkAccessData.stateId && linkAccessData.stateId !== stateId)
    ) {
      // redirect to login
      navigate("/login");
    }
  }
};

export default useLinkAccessCheck;
