import React from "react";

function CircleButton(props) {
  const { icon, onClick, className, primary, iconSize, disabled, ...otherProps } = props;

  const handleClick = (e) => {
    onClick(e);
  };

  const classNames = [
    "rounded-full",
    "disabled:opacity-50 disabled:cursor-not-allowed",
    "transition-colors duration-300 ease-in-out",
  ];

  if (className) {
    classNames.push(className);
  }

  if (primary) {
    classNames.push("bg-primary");
    classNames.push("hover:bg-primaryDark");
  } else {
    classNames.push("bg-white");
    classNames.push("hover:bg-primaryLight");
  }

  const iconClassNames = [];
  if (iconSize === "12px") {
    iconClassNames.push(`w-[12px]`);
    iconClassNames.push(`h-[12px]`);
    classNames.push(`p-[8px]`);
  } else if (iconSize === "14px") {
    iconClassNames.push("w-[14px]");
    iconClassNames.push("h-[14px]");
    classNames.push("p-[4px]");
  } else if (iconSize === "16px") {
    iconClassNames.push("w-[16px]");
    iconClassNames.push("h-[16px]");
    classNames.push("p-[6px]");
  } else {
    iconClassNames.push("w-[22px]");
    iconClassNames.push("h-[22px]");
    classNames.push("p-[10px]");
  }


  return (
    <button
      className={`${classNames.join(" ")}`}
      onClick={handleClick}
      disabled={disabled}
      {...otherProps}
    >
      <img src={icon} className={iconClassNames.join(" ")} />
    </button>
  );
}

export default CircleButton;
