import { useSelector, useDispatch } from "react-redux";
import {
  setMeasureSearchText,
} from "../redux/measures2/measures2Reducer";

const useSearchMeasures = () => {
  const dispatch = useDispatch();

  const searchText = useSelector((state) => state.measures2.search.text);

  const setSearchText = (text) => {
    dispatch(setMeasureSearchText(text));
  };

  return [searchText, setSearchText];
};

export default useSearchMeasures;
