import React from "react";
import TextInput, { TextInputSmall } from "../inputs/TextInput";
import DateSeparatedInput from "../inputs/DateSeparatedInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { dateToYYYYMMDD } from "../../utils/date";
import FilesInput from "../inputs/FilesInput";
import DownloadFiles from "../inputs/DownloadFiles";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import { useSelector } from "react-redux";
import InputLabel from "../inputs/InputLabel";
import useAuthInfo from "../../hooks/useAuthInfo";

function GraveYardForm({ data, onChange, issues }) {
  const { t } = useTranslation(namespaces.viewer);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  const setData = (newData) => {
    onChange(newData);
  };

  const handleTextChange = (name) => (value) => {
    setData({ ...data, [name]: value });
  };

  const handleDateChange = (name) => (date) => {
    setData({ ...data, [name]: new Date(date) });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setData({ ...data, [name]: selectedOption });
  };

  const handleFilesChange = (files) => {
    setData({ ...data, files: files });
  };

  return (
    <div className="flex flex-col gap-[20px] bg-bg1 rounded-xl pb-[25px]">
      {/* <div>
        <SelectBoxInput
          label={t("form.type")}
          options={["", "Hrob", "Obecny"]}
          selectedOption={data.type}
          onChange={handleSelectChange("type")}
        />
      </div> */}
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <TextInputSmall
            disabled={!canEdit}
            extraError={getErrorForPath(issues, ["firstName"])}
            lightTheme={true}
            label={t("form.firstName")}
            value={data.firstName}
            onChange={handleTextChange("firstName")}
          />
        </div>
        <div className="w-1/2">
          <TextInputSmall
          disabled={!canEdit}
            lightTheme={true}
            extraError={getErrorForPath(issues, ["lastName"])}
            label={t("form.lastName")}
            value={data.lastName}
            onChange={handleTextChange("lastName")}
          />
        </div>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <DateSeparatedInput
          variant={"small"}
          disabled={!canEdit}
            extraError={getErrorForPath(issues, ["dateOfBirth"])}
            lightTheme={true}
            label={t("form.dateOfBirth")}
            value={dateToYYYYMMDD(data.dateOfBirth)}
            onChange={handleDateChange("dateOfBirth")}
          />
        </div>
        <div className="w-1/2">
          <DateSeparatedInput
          disabled={!canEdit}
            lightTheme={true}
            variant={"small"}
            extraError={getErrorForPath(issues, ["dateOfDeath"])}
            label={t("form.dateOfDeath")}
            value={dateToYYYYMMDD(data.dateOfDeath)}
            onChange={handleDateChange("dateOfDeath")}
          />
        </div>
      </div>
      <div
        className="flex 
      flex-row gap-[24px]"
      >
        <div className="w-1/2">
          <DateSeparatedInput
          disabled={!canEdit}
          variant={"small"}
            lightTheme={true}
            extraError={getErrorForPath(issues, ["dateOfLicenceExpiration"])}
            label={t("form.dateOfLicenceExpiration")}
            value={dateToYYYYMMDD(data.dateOfLicenceExpiration)}
            onChange={handleDateChange("dateOfLicenceExpiration")}
          />
        </div>
      </div>
      <div>
        <InputLabel>{t("form.files")}</InputLabel>
        <DownloadFiles
          disabled={!canEdit}
          files={data.photos || []}
          onFilesChange={(_files) => {
            setData({ ...data, photos: _files });
          }}
          displayFileSizes={true}
          lightTheme={true}
        ></DownloadFiles>
         <div className="h-[13px]"></div>
        <FilesInput
          disabled={!canEdit}
          lightTheme={true}
          dropAreaLabel={t("form.dropAreaLabel")}
          files={data.files || []}
          // label={t("form.files")}
          onChange={handleFilesChange}
        />
      </div>
    </div>
  );
}


export default GraveYardForm;
