import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogWrapper from "./DialogWrapper";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import { namespaces } from "../../consts/i18n";
import uploadApi from "../../api/uploadApi";

const PreviewFileDialog = ({ isOpen, onClose, file }) => {
  const { t } = useTranslation(namespaces.pages);

  const [previewFile, setPreviewFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleCancel = (e) => {
    e.preventDefault();
    onClose();
  };

  const fetchBlobUrl = useCallback(async () => {
    // console.log("fetchBlobUrl", file);
    const url = await uploadApi.fetchPreviewBlobUrl(file.id, {
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setDownloadProgress(percentCompleted);
      },
    });

    return url;
  }, [file]);

  useEffect(() => {
    setPreviewFile(null);
    setDownloadProgress(0);
    if (file) {
      setIsLoading(true);
      fetchBlobUrl()
        .then((url) => {
          setPreviewFile(url);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [file, fetchBlobUrl]);

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[53px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-y-[30px] items-left justify-left">
          <div>
            <h3 className="text-sm font-bold text-center px-[26px]">
              {file?.originalName}
            </h3>
          </div>

          <div className="w-full">
            {isLoading ? (
              <div className="w-full h-[300px] bg-gray-200 animate-pulse">
                <div className="w-full h-full flex items-center justify-center">
                  <div className="w-[200px] h-[10px] bg-gray-300 rounded-full">
                    <div
                      className="h-full bg-primary rounded-full"
                      style={{ width: `${downloadProgress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            ) : null}
            {previewFile && <img src={previewFile} alt="preview" className="max-w-[500px] max-h-[500px]" />}
          </div>

          <div
            className={`flex items-center justify-center gap-x-[20px] w-full`}
          >
            <div>
              <TextButton
                className={"h-[56px]"}
                label={t("previewFileDialog.ok")}
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default PreviewFileDialog;
