import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DisplayType, setDisplayMode } from "../redux/viewer/viewerReducer";
import { useDispatch } from "react-redux";
import { DimensionType } from "../api/dimensionType";

const useViewerDisplayMode = () => {
  const dispatch = useDispatch();
  const { activeDisplayMode } = useSelector((state) => state.viewer);

  const measureMode = useSelector((state) => state.measures.mode);

  // in 3d mode, switch to measures display mode
  useEffect(() => {
    if (measureMode === DimensionType.D3) {
      dispatch(setDisplayMode(DisplayType.MEASURES));
    }
  }, [measureMode]);

  const _setDisplayMode = (mode) => {
    dispatch(setDisplayMode(mode));
  };

  return [activeDisplayMode, _setDisplayMode];
};

export default useViewerDisplayMode;
