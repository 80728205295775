import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeMessage } from "../redux/messages/messagesReducer";
const useHandleMessages = () => {
  const navigate = useNavigate();
  const messages = useSelector((state) => state.messages.data);
  const dispatch = useDispatch();

  if (messages.length > 0) {
    messages.forEach((message) => {
      // console.log("message", message);

      if (
        message.status === 401 &&
        ["getClient", "getProject", "getProjectState"].includes(message.source)
      ) {

        dispatch(removeMessage(message.id));

        // if user is not authorized to view the project, redirect to login page
        // console.log("redirect to login");
        navigate("/login", {
          replace: true,
          state: { from: window.location.pathname },
        });

      }

      if (
        message.status === 404 &&
        ["getClient", "getProject", "getProjectState"].includes(message.source)
      ) {
        dispatch(removeMessage(message.id));
        // console.log("redirect to 404");
        navigate("/link-error", { replace: true });
      }
    });
  }
};

export default useHandleMessages;
