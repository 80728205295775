import React, { useState, useEffect, useCallback } from "react";
import InputLabel from "./InputLabel";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";

const crateDateStr = (date) => {
  if(!date) return "";
  if(!date.day || !date.month || !date.year) return "";
  const dateStr = `${String(date.year).padStart(4, '0')}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
  return dateStr;
}

const DateSeparatedInput = ({ label, value, onChange, lightTheme, variant, extraError , disabled }) => {
 
  const [date, setDate] = React.useState({
    day: value ? getDayFromIsoString(value) : "",
    month: value ? getMonthFromIsoString(value) : "",
    year: value ? getFullYearFromIsoString(value) : "",
 });

  const [error, setError] = useState("");

  const setDay = useCallback((value) => {
    const newDate = { ...date, day: value };
    setDate(newDate);
    if(validate(newDate)) {
      onChange(crateDateStr(newDate));
    }
  }, [date]);

  const setMonth = useCallback((value) => {
    const newDate = { ...date, month: value };
    setDate(newDate);
    if(validate(newDate)) {
      onChange(crateDateStr(newDate));
    }
  }, [date]);

  const setYear = useCallback((value) => {
    const newDate = { ...date, year: value };
    setDate(newDate);
    if(validate(newDate)) {
      onChange(crateDateStr(newDate));
    }
  }, [date]);

  const { t } = useTranslation(namespaces.common);

  const validate = (date) => {
    if(!date) return true;
    
    if (!date.day && !date.month && !date.year) {
      setError("");
      return true;
    }
    
    if (!date.day || !date.month || !date.year) {
      setError(t("dialogs.dateInput.invalidDate"));
      return false;
    }

    if (date.day && date.month && date.year) {
      try{

        const d = new Date(date.year, date.month - 1, date.day);
        if (d.getFullYear() === date.year && d.getMonth() === date.month - 1 && d.getDate() === date.day) {
          setError("");
          return true;
        } else {
          setError(t("dialogs.dateInput.invalidDate"));
          return false;
        }
      }catch(e){
        setError(t("dialogs.dateInput.invalidDate"));
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (value) {
      const day = getDayFromIsoString(value);
      const month = getMonthFromIsoString(value);
      const year = getFullYearFromIsoString(value);

      validate({ day, month, year });
      setDate({ day, month, year });
    }else{
      setDate({ day: "", month: "", year: "" });
    }
  }, [value]);

  const theme = lightTheme ? "bg-white" : "bg-bg1";

  const variantClass = variant === "small" ? "h-[36px] text-[12px] px-[10px] py-[5px] font-[12px] rounded-[14px]" : "";

  return (
    <div className="flex flex-col">
      <InputLabel>{label}</InputLabel>
      <div className="flex space-x-4 items-center">
        <input
          onChange={(e) => setDay(tryParseInt(e.target.value))}
          value={date?.day || ""}
          // type="number"
          // min="1"
          // max="31"
          disabled={disabled}
          placeholder={t("dialogs.dateInput.dayPlaceholder")}
          className={`w-1/2 rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline ${theme} text-center ${
            error || extraError
              ? "bg-red-100 focus:ring-red-500"
              : "border-gray-300 focus:ring-blue-500"
          } ${variantClass}`}
        />
        <input
          onChange={(e) => setMonth(tryParseInt(e.target.value))}
          value={date?.month || ""}
          // type="number"
          // min="1"
          // max="12"
          disabled={disabled}
          placeholder={t("dialogs.dateInput.monthPlaceholder")}
          className={`w-1/2 rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline ${theme} text-center ${
            error || extraError
              ? "bg-red-100 focus:ring-red-500"
              : "border-gray-300 focus:ring-blue-500"
          } ${variantClass}`}
        />
        <input
          onChange={(e) => setYear(tryParseInt(e.target.value))}
          value={date?.year || ""}
          // type="number"
          // min="1900"
          // max="2100"
          disabled={disabled}
          placeholder={t("dialogs.dateInput.yearPlaceholder")}
          className={`flex-grow rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline ${theme} text-center ${
            error || extraError
              ? "bg-red-100 focus:ring-red-500"
              : "border-gray-300 focus:ring-blue-500"
          } ${variantClass}`}
        />
      </div>

      {error && <div className="w-full text-red-500 text-sm mt-1">{error}</div>}
      {extraError && (
        <p className="text-red-500 text-xs">{t(`dialogs.serverErrors.${extraError?.message}`)}</p>
      )}
    </div>
  );
};

const getMonthFromIsoString = (isostringDate) => {
  try {
    const month = isostringDate.split("-")[1];
    const monthInt = parseInt(month);
    return monthInt;
  } catch (e) {
    return "";
  }
};

const getDayFromIsoString = (isostringDate) => {
  try {
    const day = isostringDate.split("-")[2];
    const dayInt = parseInt(day);
    return dayInt;
  } catch (e) {
    return "";
  }
};

const getFullYearFromIsoString = (isostringDate) => {
  try {
    const year = isostringDate.split("-")[0];
    const yearInt = parseInt(year);
    return yearInt;
  } catch (e) {
    return "";
  }
};

const tryParseInt = (value) => {
  try {
    const ret = parseInt(value);
    if (isNaN(ret)) return "";
    return ret;
  } catch (e) {
    return "";
  }
};

export default DateSeparatedInput;
