import React from "react";

function ListItemWrapper(props) {
  const { children, onSelected, onDeselected, header, actionButtons } = props;

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  React.useEffect(() => {
    if (hovered) {
      onSelected();
    } else {
      onDeselected();
    }
  }, [hovered]);

  return (
    <div
      // onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className=" hover:shadow-lg bg-white rounded-lg px-[20px] max-w-[100%]"
    >
      <div className="relative flex flex-row items-center justify-between h-[42px]">
        <div>{header}</div>
        <div
          className={`flex flex-row gap-[14px] w-[${
            (actionButtons?.length || 0) * 20
          }px]`}
        >
          {actionButtons.map((button, i) => (
            <React.Fragment key={i}>{button}</React.Fragment>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
}

export default ListItemWrapper;
