import { useSelector, useDispatch } from "react-redux";
import { setRecordSearchText } from "../redux/records/recordsReducer";
const useSearchRecords = () => {
  const dispatch = useDispatch();

  const searchText = useSelector((state) => state.records.search.text);

  const setSearchText = (text) => {
    dispatch(setRecordSearchText(text));
  };

  return [searchText, setSearchText];
};

export default useSearchRecords;
