import React, { useEffect } from "react";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import useAuth from "../hooks/useAuth";
import { elipsis } from "../utils/stringUtils";
import MessageDialog from "./dialogs/MessageDialog";

function NavBar({ items }) {
  const navigate = useNavigate();

  const { t } = useTranslation(namespaces.pages);

  const { logout, token, linkToken } = useAuth();

  const [showLogoutDialog, setShowLogoutDialog] = React.useState(false);

  const handleLogoutClick = () => {
    setShowLogoutDialog(true);
  };

  const logoutAction = () => {
    logout();
    navigate("/login");
  };

  const handleItemClick = (item) => (e) => {
    navigate(item.link);
  };

  // set title based on items
  useEffect(() => {
    const title =
      items.length === 1
        ? items[0].name
        : items
            .slice(1)
            .map((item) => item.name)
            .join(" > ");
    if (title) {
      document.title = "Drone Soft > " + title;
    }

    return () => {
      document.title = "Drone Soft";
    };
  }, [items]);

  return (
    <div className="w-full min-h-[85px] bg-white flex items-center">
      <div className="flex w-full justify-between mx-[53px]">
        <div className="flex flex-wrap items-center">
          {token && (
            <button
              className="mr-[32px] px-[20px] w-[66px] flex flex-col items-center justify-center text-xs"
              onClick={handleLogoutClick}
            >
              <img src="/images/logout.svg" />
              <span className="text-primary">{t("navBar.logout")}</span>
            </button>
          )}
          {items.map((item, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 && (
                <img src="/images/arrow-right.svg" className="mr-[32px]" />
              )}
              <button
                onClick={handleItemClick(item)}
                title={item.name}
                className="px-4 py-2 mr-[22px] my-2 hover:bg-primaryLight transition-colors duration-300 text-700 font-bold h-[43px] bg-white text-base  rounded-lg text-primary max-w-[200px] overflow-hidden overflow-ellipsis"
              >
                {elipsis(item.name, 25)}
              </button>
            </React.Fragment>
          ))}
        </div>
        <Logo className="align-self-end"></Logo>
      </div>
      <MessageDialog
        title={t("logoutDialog.title")}
        submitText={t("logoutDialog.logout")}
        cancelText={t("logoutDialog.cancel")}
        isOpen={showLogoutDialog}
        onCancel={() => {
          setShowLogoutDialog(false);
        }}
        onSubmit={() => {
          setShowLogoutDialog(false);
          logoutAction();
        }}
      ></MessageDialog>
    </div>
  );
}

export default NavBar;
