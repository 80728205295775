import "./potree.css";
import React, { useEffect } from "react";
import useInitPotreeViewer from "../../hooks/useInitPotreeViewer";

const PotreeViewer = () => {
  const { potreeRenderAreaRef } = useInitPotreeViewer();

  return (
    <div
      className="potree_container"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div id="potree_render_area" ref={potreeRenderAreaRef}></div> {/**/}
      <div id="potree_sidebar_container"> </div>
    </div>
  );
};

export default PotreeViewer;
