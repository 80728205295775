import { useSelector, useDispatch } from "react-redux";
import { setMeasureFilterTypes } from "../redux/measures2/measures2Reducer";
import { useCallback } from "react";
import { useEffect } from "react";
import { DimensionType } from "../api/dimensionType";

const possibleFilters = ["saved", "distance", "area", "volume"];

export const getFilters = (dimensionType) => {
  switch (dimensionType) {
    case DimensionType.D3:
      return possibleFilters.filter((filter) => filter !== "area");
    case DimensionType.D2:
      return possibleFilters.filter((filter) => filter !== "volume");
    default:
      throw new Error("Unknown dimension type");
  }
};

const useFilterMeasures = () => {
  const dispatch = useDispatch();

  const dimension = useSelector((state) => state.measures.mode);

  const filterTypes = useSelector((state) => state.measures2.filter.types);

  useEffect(() => {
    setFilterTypes([]);
  }, [dimension]);

  const setFilterTypes = (types) => {
    dispatch(setMeasureFilterTypes(types));
  };

  const toggleFilterType = useCallback(
    (type, { on = false, off = false, toggle = undefined } = {}) => {
      if (on) {
        toggle = true;
      }

      if (off) {
        toggle = false;
      }

      if (toggle === undefined) {
        toggle = !filterTypes.includes(type);
      }

      if (toggle) {
        setFilterTypes([...filterTypes, type]);
      } else {
        setFilterTypes(filterTypes.filter((t) => t !== type));
      }
    },
    [filterTypes]
  );

  return [filterTypes, toggleFilterType];
};

export default useFilterMeasures;
