import React, { useCallback } from "react";
import usePotreeViewer from "../../hooks/usePotreeViewer";
import { ViewerSmallButton } from "./Timeline";

function ResetButton() {
  // get potree viewer
  const { resetView } = usePotreeViewer();

  const handleClickReset = useCallback(() => {
    resetView();
  }, [resetView]);

  return (
    <ViewerSmallButton
      onClick={handleClickReset}
      className={"w-[36px] h-[36px] px-[3px]"}
    >
      <img
        src="/images/viewer_reset_view.svg"
        className="w-[22px] h-[22px] object-contain"
      />
    </ViewerSmallButton>
  );
}

export default ResetButton;
