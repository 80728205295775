import { createSlice } from "@reduxjs/toolkit";

export const toolModeType = {
  NONE: "NONE",
  DISTANCE: "distance",
  AREA: "area",
  VOLUME: "volume",
  POINT: "point",
};
export const initialState = {
  toolMode: toolModeType.NONE,
};

export const pointCloudViewerSlice = createSlice({
  name: "pointCloudViewer",
  initialState,
  reducers: {
    setToolMode: (state, action) => {
      state.toolMode = action.payload;
    },
  },
});

export const { setToolMode } =
  pointCloudViewerSlice.actions;

export default pointCloudViewerSlice.reducer;
