import React from "react";

const StateItem = ({ state, selected, onClick }) => {
  const [hover, setHover] = React.useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick(state);
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={state.id}
      className="flex flex-row gap-[14px] border-b h-[30px] items-center"
    >
      {selected ? (
        hover ? (
          <div className="w-[12px] h-[12px] rounded-full bg-primary ring-1 ring-primary ring-offset-2 mx-[3px]  animate-all duration-300" />
        ) : (
          <div className="w-[12px] h-[12px] rounded-full bg-primary ring-1 ring-primary ring-offset-2 mx-[3px] animate-all duration-300" />
        )
      ) : hover ? (
        <div
          className="w-[12px] h-[12px] rounded-full bg-[#AAAAAA] ring-1 ring-[#AAAAAA] ring-offset-2 mx-[3px]
          animate-all duration-300
          "
        />
      ) : (
        <div className="w-[18px] h-[18px] rounded-full bg-bg1  animate-all duration-300" />
      )}

      <div className="text-sm text-700 font-bold flex flex-row grow">
        {state.name}
      </div>
      <div className="text-sm text-700 font-bold flex flex-row">{""}</div>
    </div>
  );
};

export default StateItem;
