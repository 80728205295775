import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import pointCloudViewerReducer from "./pointCloudViewer/pointCloudViewerReducer";
import mapViewerReducer from "./mapViewer/mapViewerReducer";
import infoReducer from "./info/infoReducer";
import authReducer from "./auth/authReducer";
import measuresReducer from "./measures/measuresReducer";
import measures2Reducer from "./measures2/measures2Reducer";
import recordsReducer from "./records/recordsReducer";
import { loginApi } from "../api/loginApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { managementApi } from "../api/managementApi";
import { computeApi } from "../api/computeApi";
import messageReducer from "./messages/messagesReducer";
import * as Sentry from "@sentry/react";
import viewerReducer from "./viewer/viewerReducer";

export const listenerMiddleware = createListenerMiddleware();

// create sentry enhancer
const sentryEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});


export const store = configureStore({
  reducer: {
    auth: authReducer,
    info: infoReducer,
    pointCloudViewer: pointCloudViewerReducer, // TODO: remove
    mapViewer: mapViewerReducer, // TODO: remove
    viewer: viewerReducer,
    measures: measuresReducer,
    measures2: measures2Reducer,
    records:recordsReducer,
    messages: messageReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [managementApi.reducerPath]: managementApi.reducer,
    [computeApi.reducerPath]: computeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(loginApi.middleware)
      .concat(managementApi.middleware)
      .concat(computeApi.middleware),
  enhancers: [sentryEnhancer],
});

setupListeners(store.dispatch);
