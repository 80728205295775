import {
  useGetClientQuery, useGetMeasuresQuery, useGetProjectQuery, useGetProjectStateQuery
} from "../api/managementApi";

import { useDispatch } from "react-redux";

export const extractLasFileId = (projectState) => {
  const data = projectState;
  if (!data || !data.files || !Array.isArray(data.files)) {
    return null;
  }

  const lasFile = data.files.find(file => file.extension === ".las");

  if (!lasFile) {
    return null;
  }

  return lasFile.id;
}

const useGetProjectStateAdditionals = ({
  projectId,
  clientId,
  stateId,
  dimension,
}) => {
  const {
    data: projectState,
    isLoading: projectStateLoading,
    error: projectStateError,
  } = useGetProjectStateQuery({
    clientId: clientId,
    projectId: projectId,
    id: stateId,
  });

  const {
    data: initialMeasures,
    isLoading: isLoadingMeasures,
    isFetching: isFetchingMeasures,
    refetch: refetchMeasures,
    error: measuresError,
  } = useGetMeasuresQuery({ projectId, clientId, dimension: dimension });

  // get client
  const {
    data: client,
    isLoading: clientLoading,
    error: clientError,
  } = useGetClientQuery({
    id: clientId,
  });

  // get project
  const {
    data: project,
    isLoading: projectLoading,
    error: projectError,
  } = useGetProjectQuery({
    id: projectId,
    clientId: clientId,
  });

  //console.log(initialMeasures);

  const dispatch = useDispatch();

  // useLayoutEffect(() => {
  //   return () => {
  //     if (initialMeasures) {
  //       dispatch(clearMeasures());
  //     }
  //   };
  // }, [initialMeasures, dispatch]);

  return {
    initialMeasures,
    isLoadingMeasures,
    isFetchingMeasures,
    measuresError,
    refetchMeasures,
    client,
    clientLoading,
    clientError,
    project,
    projectLoading,
    projectError,
    projectState,
    projectStateLoading,
    projectStateError,
  };
};

export default useGetProjectStateAdditionals;
