import React from "react";
import TextInput, { TextInputPrint} from "../inputs/TextInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";

function GeneralFormPrint({ data }) {
  const { t } = useTranslation(namespaces.viewer);

  console.log(data);

  return (
    <div className="flex flex-col gap-[20px] rounded-xl">
   
      <div>
        <TextInputPrint
          disabled={true}
          label={t("form.note")}
          value={data.note}
          onChange={() => {}}
        />
      </div>

     
    </div>
  );
}

export default GeneralFormPrint;
