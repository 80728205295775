import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import AnimateSpin from "./AnimateSpin";

const TextButton = ({
  onClick,
  className,
  label,
  secondary,
  isLoading,
  ...otherProps
}) => {
  const handleClick = (e) => {
    onClick(e);
  };

  const { t } = useTranslation(namespaces.common);

  return (
    <button
      className={`rounded-lg flex items-center justify-center disabled:bg-secondary ${
        secondary
          ? "bg-secondary text-black hover:bg-secondaryDark"
          : "bg-primary text-white hover:bg-primaryDark"
      } px-[40px] w-full text-lg font-bold transition-colors duration-300 ${
        className || ""
      } 
      disabled:opacity-50 disabled:cursor-not-allowed
       `}
      type="button"
      onClick={handleClick}
      {...otherProps}
    >
      {isLoading ? (
        /*t("loading")*/ <AnimateSpin size={60}></AnimateSpin>
      ) : (
        label
      )}
    </button>
  );
};

export default TextButton;
