import React from "react";

const DialogTitle = ({ title }) => {
  return (
    <div>
      <h3 className="text-xxxl font-bold text-center px-[26px]">{title}</h3>
    </div>
  );
};

export default DialogTitle;
