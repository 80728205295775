import React from "react";
import InputLabel from "./InputLabel";

// React Date Input component it is possible to setup initial value, and callback function that will be called when date is changed.

const DateInput = ({ label, value, onChange }) => {
  const [date, setDate] = React.useState(
    value ? new Date(value).toISOString().slice(0, 10) : ""
  );

  const handleChange = (e) => {
    if (!e.target.value) {
      setDate("");
      onChange("");
      return;
    }
    
    const date = new Date(e.target.value);

    setDate(date.toISOString().slice(0, 10));
    onChange(date.toISOString().slice(0, 10));
  };

  React.useEffect(() => {
    if (!value){
      setDate("");
      return;
    } 
    setDate(new Date(value).toISOString().slice(0, 10));
  }, [value]);

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <input
        onChange={handleChange}
        className="rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1"
        id="name"
        type="date"
        value={date}
      />
    </div>
  );
};

export default DateInput;
