import { useState, useEffect, useCallback } from "react";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const detectSize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, [setWindowWidth]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [detectSize]);

  return windowWidth;
};

export default useWindowWidth;
