import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  toolMode: "NONE",
};

export const mapViewerSlice = createSlice({
  name: "mapViewer",
  initialState,
  reducers: {
    setToolMode: (state, action) => {
      state.toolMode = action.payload;
    },
  },
});

export const { setToolMode } = mapViewerSlice.actions;

export default mapViewerSlice.reducer;
