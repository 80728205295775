import React, { useEffect } from "react";
import TextInput, { TextInputSmall } from "../inputs/TextInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import CheckboxInput from "../inputs/CheckboxInput";
import DateSeparatedInput from "../inputs/DateSeparatedInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { dateToYYYYMMDD } from "../../utils/date";
import FilesInput from "../inputs/FilesInput";
import DownloadFiles from "../inputs/DownloadFiles";
import { managementApi } from "../../api/managementApi";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import { useSelector } from "react-redux";
import InputLabel from "../inputs/InputLabel";

function FVEForm({ data, onChange, fillDefault, issues, disabled }) {
  const { t } = useTranslation(namespaces.viewer);

  // const [data, setData] = React.useState({
  //   panelType: "",
  //   holderType: "",
  //   hotspot: false,
  //   shading: false,
  //   others: "",
  //   controlDate: "",
  //   fixedDate: "",
  // });

  const isDefaultFilled = React.useRef(false);

  useEffect(() => {
    // if data is empty object, set default values
    if (fillDefault && !isDefaultFilled.current) {
      isDefaultFilled.current = true;
      setData({
        panelType: null,
        anchorType: null,
        hotspot: false,
        shading: false,
        others: "",
        dateOfRevision: null,
        dateOfFix: null,
      });
    }
    // console.log("FVEForm: default values set");
  }, [fillDefault, data]);

  const setData = (data) => {
    onChange(data);
  };

  const handleTextChange = (name) => (value) => {
    setData({ ...data, [name]: value });
  };

  const handleCheckboxChange = (name) => (checked) => {
    setData({ ...data, [name]: checked });
  };

  const handleDateChange = (name) => (date) => {
    setData({ ...data, [name]: new Date(date) });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setData({ ...data, [name]: selectedOption });
  };

  const handleFilesChange = (files) => {
    setData({ ...data, files: files });
  };

  return (
    <div className="flex flex-col gap-[20px] bg-bg1 rounded-xl py-[5px]">
      {/* <div>
        <SelectBoxInput
          label={t("form.recordType")}
          options={["", "FVE", "FVZ"]}
          selectedOption={data.recordType}
          onChange={handleSelectChange("recordType")}
        ></SelectBoxInput>
      </div> */}
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <TextInputSmall
           disabled={disabled}
            extraError={getErrorForPath(issues, ["panelType"])}
            lightTheme={true}
            label={t("form.panelType")}
            value={data.panelType}
            onChange={handleTextChange("panelType")}
          ></TextInputSmall>
        </div>
        <div className="w-1/2">
          <TextInputSmall
            lightTheme={true}
           disabled={disabled}
            extraError={getErrorForPath(issues, ["anchorType"])}
            label={t("form.anchorType")}
            value={data.anchorType}
            onChange={handleTextChange("anchorType")}
          ></TextInputSmall>
        </div>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <CheckboxInput
           disabled={disabled}
            lightTheme={true}
            extraError={getErrorForPath(issues, ["hotspot"])}
            label={t("form.hotspot")}
            checked={data.hotspot}
            onChange={handleCheckboxChange("hotspot")}
          ></CheckboxInput>
        </div>
        <div className="w-1/2">
          <CheckboxInput
           disabled={disabled}
            lightTheme={true}
            checked={data.shading}
            label={t("form.shading")}
            extraError={getErrorForPath(issues, ["shading"])}
            onChange={handleCheckboxChange("shading")}
          ></CheckboxInput>
        </div>
      </div>
      <div>
        <TextInputSmall
         disabled={disabled}
          lightTheme={true}
          extraError={getErrorForPath(issues, ["note"])}
          label={t("form.others")}
          value={data.note}
          onChange={handleTextChange("note")}
        ></TextInputSmall>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <DateSeparatedInput
         disabled={disabled}
            lightTheme={true}
            variant={"small"}
            extraError={getErrorForPath(issues, ["dateOfRevision"])}
            value={dateToYYYYMMDD(data.dateOfRevision)}
            label={t("form.dateOfRevision")}
            onChange={handleDateChange("dateOfRevision")}
          ></DateSeparatedInput>
        </div>
        <div className="w-1/2">
          <DateSeparatedInput
         disabled={disabled}
          variant={"small"}
            extraError={getErrorForPath(issues, ["dateOfFix"])}
            lightTheme={true}
            value={dateToYYYYMMDD(data.dateOfFix)}
            label={t("form.dateOfFix")}
            onChange={handleDateChange("dateOfFix")}
          ></DateSeparatedInput>
        </div>
      </div>
      <div>
        <InputLabel>{t("form.files")}</InputLabel>
        <DownloadFiles
         disabled={disabled}
          files={data.photos || []}
          onFilesChange={(_files) => {
            setData({ ...data, photos: _files });
            managementApi.util.invalidateTags([{ type: "Record", id: "LIST" }]);
          }}
          displayFileSizes={true}
          lightTheme={true}
        ></DownloadFiles>
        <div className="h-[13px]"></div>
        <FilesInput
          lightTheme={true}
          // label={}
          dropAreaLabel={t("form.dropAreaLabel")}
          files={data.files || []}
          onChange={handleFilesChange}
         disabled={disabled}
        />
      </div>
    </div>
  );
}

export default FVEForm;
