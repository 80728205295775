import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./providers/authProvider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import gitInfo from "./generatedGitInfo.json";
import AppErrorPage from "./pages/AppErrorPage";

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

if (process.env.NODE_ENV === "development") {
  Sentry.init({
    dsn: "",
    enabled: false,
  });

} else {
  Sentry.init({
    dsn: "https://7009f1f74b754a2c89e0d9f73b3a988c@o1342599.ingest.sentry.io/4504633054134272",
    integrations: [ new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
      ),
  }),],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    normalizeDepth: 10,

    release: "dronevision@" + gitInfo?.version,
  });

  console.log("dronesoft app version:", gitInfo?.version);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<AppErrorPage></AppErrorPage>}>
      <BrowserRouter>
        <Provider store={store}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
