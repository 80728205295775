import React, { useEffect } from "react";
import usePotreeViewer from "./usePotreeViewer";

const usePotreeMeasurements = () => {
  const { viewer } = usePotreeViewer();

  const startDistanceMeasurement = ({ name }) => {
    // console.log("startDistanceMeasurement");
    const measurement = viewer.measuringTool.startInsertion({
      showDistances: false,
      showArea: false,
      closed: false,
      name: name,
      measureType: "distance",
    });

    // registerListeners(measurement, onChangeMeasurement);
  };

  const startVolumeMeasurement = ({ name }) => {
    // console.log("startVolumeMeasurement");
    const measurement = viewer.measuringTool.startInsertion({
      showDistances: false,
      showPolygonVolume: true,
      closed: true,
      name: name,
      measureType: "volume",
    });

    // registerListeners(measurement, onChangeMeasurement);
  };

  const startPointMeasurement = ({ name }) => {
    console.log("startPointMeasurement");

    const measurement = viewer.measuringTool.startInsertion({
      showDistances: false,
      showCoordinates: true,
      showArea: false,
      showAngles: false,
      closed: true,
      maxMarkers: 1,
      name: name,
      measureType: "point",
    });
  };

  const startAreaMeasurement = () => {
    const measurement = viewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: true,
      closed: true,
      name: "Area",
    });
  };

  const stopMeasurement = () => {
    viewer.measuringTool.stopInsertion();
  };

  const clearMeasurements = () => {
    viewer.scene.removeAllMeasurements();   
  };

  const exportMeasurements = () => {
    viewer.measuringTool.export();
  };

  const addMeasurement = (measurement) => {
    viewer.scene.addMeasurement(measurement);
  };

  const removeMeasurement = (measurement) => {
    viewer.scene.removeMeasurement(measurement);
  };

  const setMeasurementVolumeResult = (measurementId, result) => {
    const measurement = viewer.scene.measurements.find(
      (m) => m.uuid === measurementId
    );

    measurement.setVolumeResult(result);
  };

  const setMeasurementVolumeResultState = (measurementId, state) => {
    const measurement = viewer.scene.measurements.find(
      (m) => m.uuid === measurementId
    );

    measurement.setVolumeResultState(state);
  };

  return {
    startPointMeasurement,
    startDistanceMeasurement,
    startAreaMeasurement,
    startVolumeMeasurement,
    stopMeasurement,
    exportMeasurements,
    clearMeasurements,
    removeMeasurement,
    addMeasurement,
    setMeasurementVolumeResult,
    setMeasurementVolumeResultState,
    getPotreeMeasurements: () => viewer.scene.measurements,
  };
};

export default usePotreeMeasurements;
