import React from "react";
import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import { useNavigate } from "react-router-dom";
function LinkErrorPage() {
  const { t } = useTranslation(namespaces.common);

  const navigate = useNavigate();

  return (
    <Page className={"h-screen flex justify-center"}>
      <div className="">
        <div className="bg-primaryLight rounded-lg w-[370px] pb-[10px]">
          <div className="flex items-center flex-col">
            <h2 className="text-xl font-bold m-4 text-[#ff3300]">
              <p>{t("errorLink")}</p>
            </h2>
            <div>
              <img
                width={350}
                height={350}
                src="/images/drone-error.jpg"
                alt="404"
                className="rounded-lg"
              />
            </div>
            {/* <p>
              <a
                href=""
                onClick={() => {
                  navigate(-1);
                }}
                className="text-primary font-bold text-black visited:text-black hover:text-primary transition-colors duration-300 m-6 text-xl"
              >
                {t("goBack")}
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default LinkErrorPage;
