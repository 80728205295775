// if development mode, use dronesoft.cz as API_URL, otherwise use window.location.origin
const isDev = process.env.NODE_ENV === "development"

const API_BASE = process.env.REACT_APP_API_BASE || "https://api.dev.yord.soukup.link" ||  "http://3.74.105.129"|| "https://api.dronesoft.cz"

export const API_URL = API_BASE + "/api"
export const FS_API_URL = API_BASE + "/api"
export const DOWNLOAD_BASE_URL = API_BASE;

const isDevServer = "www.dev.yord.soukup.link" === window.location.hostname || "localhost" === window.location.hostname

export const DEMO_CLIENT_ID = isDevServer ? "760911e1-0673-4ea4-b57d-3ce4d00f00e8"  :"9dbafb1b-1b4e-4a53-92ec-bcac6be4e661"
export const DEMO_TOKEN = isDevServer ? "373d5125-b2c8-4c51-a3e8-9987124b7b5f" : "cb047f55-f893-4abf-9bd7-3e2f143c9e65"

// http://localhost:3000/link//373d5125-b2c8-4c51-a3e8-9987124b7b5f

// https://www.dronesoft.cz/link/9dbafb1b-1b4e-4a53-92ec-bcac6be4e661/14d2dcd2-b908-4d25-842d-c8d152155fc1/925ae4fd-ab0f-4937-97f6-67b4d0ca9d3f/
// http://localhost:3000/link/9dbafb1b-1b4e-4a53-92ec-bcac6be4e661/14d2dcd2-b908-4d25-842d-c8d152155fc1/925ae4fd-ab0f-4937-97f6-67b4d0ca9d3f/

