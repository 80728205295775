import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import Logo from "../Logo";
import FVEForm from "../forms/FVEForm";
import { useSelector } from "react-redux";
import FVEFormPrint from "../forms/FVEFormPrint";
import FacilityFormPrint from "../forms/FacilityFormPrint";
import { QRCodeToMapyCZ } from "../QRCode";
import InputLabel from "../inputs/InputLabel";
import ParksAndGardensFormPrint from "../forms/ParksAndGardensFormPrint";
import GraveYardFormPrint from "../forms/GraveYardFormPrint";
import GeneralFormPrint from "../forms/GeneralFormPrint";
import { filterRecordTypesForToolMode } from "../../api/consts";
import TogglesSelect from "../inputs/TogglesSelect";
import ToggleButton from "../inputs/ToggleButton";
import { RecordType } from "../../api/consts";
import { flattenPoints, getCenterPoint } from "../../utils/geo";
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

const A4SizeInPx = {
  width: 794,
  height: 1123,
};

export const RecordToPrint = React.forwardRef((props, ref) => {
  const data = props.record || {};
  const { extra } = data;
  const { t } = useTranslation(namespaces.viewer);

  const { client, project } = useSelector((state) => state.info);

  const recordType = data?.type;

  const printData = { ...extra, note: data?.note };

  let position = null;
  if(data?.points) {
    const [x, y] = getCenterPoint(flattenPoints(data?.points));
    position = { x, y };
  }

  return (
    <div
      ref={ref}
      className={`m-[65px] flex flex-col text-[#1E2D2F] bg-white w-[${A4SizeInPx.width}px] h-[${A4SizeInPx.height}px]`}
      style={{ zoom: 1.25 }}
    >
      <Logo className="absolute right-[70px] top-[43px] w-[42px]"></Logo>
      <div>
        <h1 className="text-center text-[24px] text-700 font-bold leading-[29px]">
          {data?.name}
        </h1>
        <h2 className="text-center text-[12px] text-400 h-[28px]">
          {t("printReport.record")}
        </h2>
      </div>

      <div className="flex gap-[10px] flex-col mt-[18px]">
        <div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
          <div className="text-[11px] leading-[13px]">
            {t("printReport.client")}
          </div>
          <div className="text-700  font-bold text-[11px] leading-[13px]">
            {client?.name}
          </div>
        </div>

        <div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
          <div className="text-[11px] leading-[13px]">
            {t("printReport.project")}
          </div>
          <div className="text-[11px] leading-[13px] text-700  font-bold">
            {project?.name}
          </div>
        </div>
      </div>
      <Logo className="absolute right-[70px] top-[43px] w-[42px]"></Logo>

      <div
        className="flex flex-col gap-[24px] mt-[18px]
    "
      >
        <div>
          <InputLabel>{t("printReport.recordType")}</InputLabel>
          <ToggleButton
            label={t("form." + recordType)}
            onToggle={() => {}}
            checked={true}
          />
        </div>

        {data.type === RecordType.FAULT && (
          <FVEFormPrint data={printData || {}} />
        )}
        {data.type === RecordType.AREA && (
          <FacilityFormPrint data={printData || {}} />
        )}
        {data.type === RecordType.PARK && (
          <ParksAndGardensFormPrint data={printData || {}} />
        )}
        {data.type === RecordType.CEMETERY && (
          <GraveYardFormPrint data={printData || {}} />
        )}
        {data.type === RecordType.GENERIC && (
          <GeneralFormPrint data={printData || {}} />
        )}

        <div className="flex flex-col gap-[5px] mt-[18px] items-center">
          <InputLabel>{t("printReport.qrCode")}</InputLabel>
          {position && (
            <QRCodeToMapyCZ
              x={position?.x}
              y={position?.y}
              level={18}
              size={130}
            />
          )}
        </div>
      </div>

      <div className="text-[8px] mt-[20px]">
        <p>{t("printReport.footer")}</p>

        <p>
          {t("printReport.today")} {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
});
