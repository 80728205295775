import React, { useCallback, useEffect, useRef } from "react";
import GeoTIFF from "ol/source/GeoTIFF";
import Map from "ol/Map";
import TileLayer from "ol/layer/WebGLTile";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import Draw from "ol/interaction/Draw";
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  RegularShape,
  Text,
} from "ol/style";
import { LineString, Polygon, Point, MultiPoint } from "ol/geom";
import { unByKey } from "ol/Observable";
import { getArea, getLength } from "ol/sphere";
import { View } from "ol";
import { defaults } from "ol/control/defaults";
import { Overlay } from "ol";
import PolygonArea2D, {
  formatArea,
} from "../../features/measures/polygonArea2D.js";
import LineDistanceMeasure2D, {
  formatLength,
} from "../../features/measures/lineDistance2D.js";
import Point2D from "../../features/measures/point2D.js";
import { click, pointerMove } from "ol/events/condition";
import { Modify, Select } from "ol/interaction.js";
import MapProvider from "../../providers/mapProvider.js";
import { MapContext } from "../../providers/mapProvider.js";
import "./ol.css";
import { Feature } from "ol";
import uuid from "../../utils/uuid.js";
import { useDispatch } from "react-redux";

import Collection from "ol/Collection";
import useViewerDisplayMode from "../../hooks/useViewerDisplayMode.js";
import { DisplayType } from "../../redux/viewer/viewerReducer.js";
import { useSelector } from "react-redux";
import { DimensionType } from "../../api/dimensionType.js";
import {
  selectRecord,
  deselectRecord,
} from "../../redux/records/recordsReducer.js";
import {
  selectMeasure,
  deselectMeasure,
} from "../../redux/measures2/measures2Reducer.js";
import { set } from "ol/transform.js";
import { RecordType } from "../../api/consts.js";
import { isValidPolygon } from "../../utils/geo.js";

const ToolMode = {
  NONE: "none",
  DISTANCE: "distance",
  AREA: "area",
  POINT: "point",
};

const TiffViewer = ({
  url,
  toolMode,
  onAddFeature,
  onChangeFeature,
  onAbortFeature,
  records,
  measures,
  nextFeatureName,
}) => {
  const [displayMode] = useViewerDisplayMode();

  const [tooltip, setTooltip] = React.useState(null); // State for tooltip content

  const {
    hidden: hiddenRecords,
    selected: selectedRecords,
    editable: editableRecords,
    changes: changesRecords,
  } = useSelector((state) => state.records);
  const {
    hidden: hiddenMeasures,
    selected: selectedMeasures,
    editable: editableMeasures,
  } = useSelector((state) => state.measures2);

  // const measures = useSelector((state) => state.measures.data);

  const mapRef = useRef();
  const dispatch = useDispatch();

  const { map, setMap } = React.useContext(MapContext);
  const vectorSourceRef = useRef(new VectorSource());

  const modifyFeatures = useRef(new Collection());

  // hide, select record features
  useEffect(() => {
    if (!map) return;
    if (!hiddenRecords) return;
    if (!selectedRecords) return;

    const vectorSource = vectorSourceRef.current;

    const features = vectorSource.getFeatures();

    features.forEach((feature) => {
      // console.log(feature);
      const id = feature.getId();
      if (!id) return;
      // console.log(id, feature.get("name"));
      const isRecord = feature.get("isRecord") === true;
      if (!isRecord) return;

      const isHidden = hiddenRecords.includes(id);
      feature.set("hidden", isHidden);
      const isSelected = selectedRecords.includes(id);
      feature.set("selected", isSelected);
    });
  }, [map, hiddenRecords, selectedRecords]);

  // hide select measure features
  useEffect(() => {
    if (!map) return;
    if (!hiddenMeasures) return;
    if (!selectedMeasures) return;
    const vectorSource = vectorSourceRef.current;

    const features = vectorSource.getFeatures();

    features.forEach((feature) => {
      const id = feature.getId();
      if (!id) return;

      const isRecord = feature.get("isRecord") === true;
      if (isRecord) return;

      const isHidden = hiddenMeasures.includes(id);
      feature.set("hidden", isHidden);
      const isSelected = selectedMeasures.includes(id);
      feature.set("selected", isSelected);
    });
  }, [map, selectedMeasures, hiddenMeasures]);

  const handleAddFeature = useCallback(
    (e) => {
      const feature = e.feature;

      if (feature.get("doNotAdd")) {
        // remove feature from source
        const vectorSource = vectorSourceRef.current;
        vectorSource.removeFeature(feature);
        onAbortFeature();
        return;
      }

      if (feature.get("isRecord") || feature.get("isMeasure")) {
        // already created
        return;
      }

      // if in display mode records, then set isRecord to true, but not for measures
      if (
        displayMode === DisplayType.RECORDS &&
        feature.get("isMeasure") !== true
      ) {
        feature.set("isRecord", true);
      }

      // console.log("handleAddFeature", feature);

      assignUniqueIdToFeature(feature);
      assignNameToFeature(feature, nextFeatureName);
      onAddFeature(feature);
      addChangeListenerToFeature(feature, onChangeFeature);
      markFeatureForModificationIfNeeded(feature, modifyFeatures);
    },
    [onAddFeature, nextFeatureName, displayMode, onAbortFeature]
  );

  useEffect(() => {
    const vectorSource = vectorSourceRef.current;
    const addFeatureHandler = handleAddFeature;
    vectorSource.on("addfeature", addFeatureHandler);
    return () => {
      vectorSource.un("addfeature", addFeatureHandler);
    };
  }, [handleAddFeature]);

  // ensure measures are cleared
  // useEffect(() => {
  //   return () => {
  //     dispatch(clearMeasures());
  //   };
  // }, [initialMeasures]);

  useEffect(() => {
    if (!map || !records) return;

    const vectorSource = vectorSourceRef.current;
    const features = vectorSource.getFeatures();

    removeRecordFeatures(vectorSource, features);

    // apply changes to records
    const recent = records.map((record) => {
      return {
        ...record,
        ...changesRecords[record.id],
      };
    });

    addNewRecordFeatures(vectorSource, recent);

    vectorSource.getFeatures().forEach((feature) => {
      addChangeListenerToFeature(feature, onChangeFeature);
    });
  }, [map, records]);

  useEffect(() => {
    if (!map || !measures) return;

    const vectorSource = vectorSourceRef.current;
    const features = vectorSource.getFeatures();

    // console.log("redraw");

    removeMeasureFeatures(vectorSource, features);
    addNewMeasureFeatures(vectorSource, measures);

    vectorSource.getFeatures().forEach((feature) => {
      addChangeListenerToFeature(feature, onChangeFeature);
    });
  }, [map, measures]);

  useEffect(() => {
    if (!map) return;

    const vectorSource = vectorSourceRef.current;
    const features = vectorSource.getFeatures();

    switch (displayMode) {
      case DisplayType.MEASURES:
        setHiddenForRecords(features, measures);
        break;
      case DisplayType.RECORDS:
        setHiddenForMeasures(features);
        break;
      default:
        break;
    }

    // refresh to update styles
    vectorSource.changed();
  }, [displayMode, map, records, measures]);

  // add editable measures and editable records to modifyFeatures
  useEffect(() => {
    if (!map) return;
    if (!modifyFeatures) return;
    const _modifyFeatures = modifyFeatures.current;

    // _modifyFeatures.clear();

    const vectorSource = vectorSourceRef.current;
    const features = vectorSource.getFeatures();

    const addIdsToModifyFeatures = (ids) => {
      ids.forEach((id) => {
        const feature = features.find((f) => f.getId() === id);

        if (feature) {
          feature.set("editable", true);
          _modifyFeatures.push(feature);
        }
      });
    };

    if (editableMeasures) addIdsToModifyFeatures(editableMeasures);
    if (editableRecords) addIdsToModifyFeatures(editableRecords);

    return () => {
      _modifyFeatures.clear();
    };
  }, [map, measures, records, editableMeasures, editableRecords]);

  useEffect(() => {
    if (!url) {
      return;
    }

    const tifSource = new GeoTIFF({
      sources: [{ url }],
    });

    let isCleanedUp = false;
    let map = null;
    let modifyInteraction = null;

    tifSource.getView().then((viewOptions) => {
      const extendedResolutions = extendResolutions(viewOptions);
      
      // increase active area
      const increaseExtent = (extent, padding) => {
        const [minX, minY, maxX, maxY] = extent;
        const width = maxX - minX;
        const height = maxY - minY;
        const newMinX = minX - width * padding;
        const newMinY = minY - height * padding;
        const newMaxX = maxX + width * padding;
        const newMaxY = maxY + height * padding;
        return [newMinX, newMinY, newMaxX, newMaxY];
      };
      
      const mapView = new View({
        ...viewOptions,
        resolutions: extendedResolutions,
        // showFullExtent: true,
        extent: increaseExtent(viewOptions.extent, 0.1),
        // multiWorld: true,
      });

      

      //console.log(viewOptions)

      tifSource.on("change", () => {
        if (tifSource.getState() === "error") {
          console.error(tifSource.getError());
        }
      });

      const vectorSource = vectorSourceRef.current;
      // populateVectorSource(vectorSource, initialMeasures, dispatch);

      if (!isCleanedUp) {
        const { map: createdMap, modifyInteraction: createdInteraction } =
          createAndConfigureMap(
            mapView,
            tifSource,
            vectorSource,
            modifyFeatures,
            setTooltip
          );
        map = createdMap;
        modifyInteraction = createdInteraction;

        map.setTarget(mapRef.current);
        setMap(map);
      }
    });

    return () => {
      isCleanedUp = true;
      if (map) {
        map.setTarget(undefined);
        map.removeInteraction(modifyInteraction);
      }
    };
  }, [url, dispatch]);

  useEffect(() => {
    if (map === null) {
      return;
    }

    if (url == null || url === "") {
      return;
    }

    let isSubscribed = true;
    const tifSource = new GeoTIFF({
      sources: [
        {
          url: url,
        },
      ],
    });

    tifSource.on("change", () => {
      if (isSubscribed && tifSource.getState() === "error") {
        console.error(tifSource.getError());
        // window.location.reload();

        // try to reset source
        map.getLayers().forEach((layer) => {
          if (layer instanceof TileLayer) {
            layer.setSource(tifSource);
          }
        });
      }
    });

    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer instanceof TileLayer) {
          layer.setSource(tifSource);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [url, map]);

  // draw interaction
  useEffect(() => {
    if (!map || toolMode === ToolMode.NONE) return;

    const vectorSource = map.getLayers().getArray()[1].getSource();

    let helperContext = {
      isAborted: false,
    };

    const drawInteraction = new Draw({
      source: vectorSource,
      type: getDrawType(toolMode),
      style: (feature) => featureStyleFunction(feature, getDrawType(toolMode)),
    });

    const rightClickEndEventKey = map.on("pointerdown", (event) =>
      finishDrawingOnRightClick(
        event,
        drawInteraction,
        helperContext,
        onAbortFeature
      )
    );
    const contextMenuEventKey = map.on("contextmenu", preventContextMenu);

    drawInteraction.on("drawstart", (event) =>
      handleDrawStart(event, toolMode)
    );

    drawInteraction.on("drawend", (event) => {
      if (helperContext.isAborted) {
        event.feature.set("doNotAdd", true);
      }
    });

    map.addInteraction(drawInteraction);

    // Cleanup function
    return () => {
      map.removeInteraction(drawInteraction);
      map.un("pointerdown", rightClickEndEventKey);
      map.un("contextmenu", contextMenuEventKey);
    };
  }, [map, toolMode, onAbortFeature]);

  return (
    <div ref={mapRef} className="w-full h-full">
      <div
        className="bg-white rounded-full shadow-md p-2 text-xs text-center font-bold"
        style={{
          display: tooltip ? "block" : "none",
          position: "absolute",
          zIndex: 1000,
          left: tooltip?.position[0] + 20,
          top: tooltip?.position[1] - 10,
        }}
      >
        {tooltip?.text}
      </div>
    </div>
  );
};

const getDrawType = (toolMode) => {
  switch (toolMode) {
    case ToolMode.DISTANCE:
      return "LineString";
    case ToolMode.AREA:
      return "Polygon";
    case ToolMode.POINT:
      return "Point";
    default:
      return null;
  }
};

const getPointStyles = (feature, resolution) => {
  const geometry = feature.getGeometry();
  const coordinates = geometry.getCoordinates();
  const pointStyles = [];

  if (geometry instanceof LineString) {
    coordinates.forEach((coord) => {
      pointStyles.push(
        new Style({
          geometry: new Point(coord),
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: "blue",
            }),
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
          }),
        })
      );
    });
  } else if (geometry instanceof Polygon) {
    coordinates[0].forEach((coord) => {
      pointStyles.push(
        new Style({
          geometry: new Point(coord),
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: "rgba(0, 220, 10, 0.9)",
            }),
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
          }),
        })
      );
    });
  }

  return pointStyles;
};

const createInitialStyle = (color) => {
  return new Style({
    fill: new Fill({
      color: color,
    }),
    stroke: new Stroke({
      color: color,
      // lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: color,
      }),
      fill: new Fill({
        color: color,
      }),
    }),
  });
};

const Colors = {
  hiddenColor: "rgba(0, 0, 0, 0)",
  normalStrokeColorLine: "rgba(112, 126, 198, 1)",
  normalStrokeColorPolygon: "rgba(122, 191, 97, 1)",
  normalStrokeColorPolygonRecord: "rgba(255, 255, 255, 1)",
  selectedStrokeColor: "rgba(255, 0, 0, 0.9)",
  normalFillColorLine: "rgba(0, 10, 220, 0.1)",
  normalFillColorPolygon: "rgba(0, 220, 10, 0.1)",
  normalFillColorPolygonRecord: "rgba(255, 255, 255, 0.1)",
  selectedFillColor: "rgba(255, 0, 0, 0.1)",
  normalPointColor: "rgba(245, 211, 0, 1)",
};

const polygonStyle = createInitialStyle(Colors.normalStrokeColorPolygon);
const lineStyle = createInitialStyle(Colors.normalStrokeColorLine);
const pointStyle = createInitialStyle(Colors.normalPointColor);

const getStyleForDrawType = (drawType) => {
  if (drawType === "Point") {
    return pointStyle;
  } else if (drawType === "LineString") {
    return lineStyle;
  } else if (drawType === "Polygon") {
    return polygonStyle;
  }
  return pointStyle;
};

const featureStyleFunction = (feature, drawType) => {
  const styles = [getStyleForDrawType(drawType)];
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  const hidden = feature.get("hidden") === true;
  const selected = feature.get("selected");
  const toBeStored = feature.get("toBeStored");
  const editable = feature.get("editable");
  const name = feature.get("name");
  const isRecord = feature.get("isRecord") === true;
  const showValue = feature.get("showValue") === true;

  const hiddenColor = Colors.hiddenColor;
  const normalStrokeColorLine = Colors.normalStrokeColorLine;
  const normalStrokeColorPolygon = Colors.normalStrokeColorPolygon;
  const normalStrokeColorPolygonRecord = Colors.normalStrokeColorPolygonRecord;
  const selectedStrokeColor = Colors.selectedStrokeColor;
  const normalFillColorLine = Colors.normalFillColorLine;
  const normalFillColorPolygon = Colors.normalFillColorPolygon;
  const normalFillColorPolygonRecord = Colors.normalFillColorPolygonRecord;
  const selectedFillColor = Colors.selectedFillColor;

  const setStrokeColor = (color) => {
    styles[0].getStroke().setColor(color);
  };

  const setFillColor = (color) => {
    styles[0].getFill().setColor(color);
  };

  const labelStyle = getLabelStyle(selected);

  if (hidden) {
    setStrokeColor(hiddenColor);
    setFillColor(hiddenColor);
  } else if (!drawType || geometryType === drawType) {
    if (geometryType === "LineString") {
      const point = new Point(geometry.getLastCoordinate());

      if (!isRecord) {
        const label = formatLength(geometry.getLength());

        if (label) {
          labelStyle.setGeometry(point);
          labelStyle.getText().setText(label);
          styles.push(labelStyle);
        }
      }

      setStrokeColor(normalStrokeColorLine);
      setFillColor(normalFillColorLine);
    } else if (geometryType === "Polygon") {
      const point = new Point(geometry.getInteriorPoint().getCoordinates());

      if (!isRecord || showValue) {
        const label = isValidPolygon(geometry.getCoordinates())
          ? formatArea(geometry.getArea())
          : "Nevalidní polygon";

        if (label) {
          labelStyle.setGeometry(point);
          labelStyle.getText().setText(label);
          styles.push(labelStyle);
        }
      }
      setStrokeColor(
        isRecord ? normalStrokeColorPolygonRecord : normalStrokeColorPolygon
      );
      setFillColor(
        isRecord ? normalFillColorPolygonRecord : normalFillColorPolygon
      );
    } else if (geometryType === "Point") {
      const point = new Point(geometry.getCoordinates());

      // change point color
      styles[0].getImage().setFill(new Fill({ color: "rgba(245, 211, 0, 1)" }));
      styles[0].getImage().setStroke(new Stroke({ color: hiddenColor }));

      const label = name;

      if (label) {
        labelStyle.setGeometry(point);
        labelStyle.getText().setText(label);
        styles.push(labelStyle);
      }
    }
  }

  if (hidden) {
    setStrokeColor(hiddenColor);
    setFillColor(hiddenColor);
    if (geometryType === "Point") {
      styles[0].getImage().setFill(new Fill({ color: hiddenColor }));
      styles[0].getImage().setStroke(new Stroke({ color: hiddenColor }));
    }
  } else {
    if (selected) {
      setStrokeColor(selectedStrokeColor);
      setFillColor(selectedFillColor);
    } else {
      // setStrokeColor(normalStrokeColor);
      // setFillColor(normalFillColor);
    }
  }

  if ((!toBeStored || editable) && !hidden) {
    // show circle at each vertex of the feature

    const pointStyles = getPointStyles(feature, 1);
    styles.push(...pointStyles);
  }

  return styles;
};

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 10, 220, 0.9)",
    // lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(255, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
  // geometry: function (feature) {
  //   let coordinates = feature.getGeometry().getCoordinates();
  //   const pointList = [];
  //   if (feature.getGeometry().getType() === "Polygon") {
  //     // For polygons, we only need the outer ring
  //     coordinates = coordinates[0];
  //   }
  //   coordinates.forEach((coordinate) => {
  //     pointList.push(new Point(coordinate));
  //   });
  //   console.log(pointList);
  //   return new MultiPoint(pointList);
  // },
});

const getLabelStyle = (selected) =>
  new Style({
    text: new Text({
      font: selected ? "18px Calibri,sans-serif" : "14px Calibri,sans-serif",
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
      backgroundFill: new Fill({
        color: "rgba(0, 0, 0, 0.7)",
      }),
      padding: [3, 3, 3, 3],
      textBaseline: "bottom",
      offsetY: -15,
    }),
    image: new RegularShape({
      radius: 8,
      points: 3,
      angle: Math.PI,
      displacement: [0, 10],
      fill: new Fill({
        color: "rgba(0, 0, 0, 0.7)",
      }),
    }),
  });

const getMeasureFromSerialized = (serializedMeasure) => {
  if (serializedMeasure.type === "distance") {
    return LineDistanceMeasure2D.fromSerialized(serializedMeasure);
  } else if (serializedMeasure.type === "area") {
    return PolygonArea2D.fromSerialized(serializedMeasure);
  } else if (serializedMeasure.type === "point") {
    return Point2D.fromSerialized(serializedMeasure);
  }

  return null;
};

const createFeatureForMeasure = (measure, isRecord) => {
  const feature = new Feature({
    name: measure.name,
    geometry: measure.getGeometry(),
  });
  feature.set("measureType", measure.type);
  feature.set("toBeStored", measure.toBeStored);
  feature.set("dbId", measure.dbId);
  feature.setId(measure.id);
  feature.set("name", measure.name);
  feature.set("isRecord", isRecord);
  if (!isRecord) feature.set("isMeasure", true);

  return feature;
};

// Helper function to set hidden property for measures
function setHiddenForRecords(features, measures) {
  features.forEach((feature) => {
    if (feature.get("isRecord") === true) {
      feature.set("hidden", true);
    } else {
      const measure = measures.find((m) => m.id === feature.getId());
      feature.set("hidden", measure ? measure.hidden === true : false);
    }
  });
}

// Helper function to set hidden property for records
function setHiddenForMeasures(features) {
  features.forEach((feature) => {
    if (feature.get("isRecord") !== true) {
      feature.set("hidden", true);
    } else {
      feature.set("hidden");
    }
  });
}

// Helper function to remove all record features
function removeRecordFeatures(vectorSource, features) {
  features.forEach((feature) => {
    if (feature.get("isRecord")) vectorSource.removeFeature(feature);
  });
}

function removeMeasureFeatures(vectorSource, features) {
  features.forEach((feature) => {
    if (feature.get("isRecord") !== true) vectorSource.removeFeature(feature);
  });
}

function addNewMeasureFeatures(vectorSource, measures) {
  measures.forEach((measure) => {
    const converted = getMeasureFromSerialized(measure);
    const feature = createFeatureForMeasure(converted);
    vectorSource.addFeature(feature);
    feature.set("toBeStored", converted.toBeStored);
    feature.set("dbId", converted.dbId);
  });
}

// Helper function to add new features
function addNewRecordFeatures(vectorSource, records) {
  records.forEach((record) => {
    const measure = getMeasureFromSerialized({
      ...record,
      type: record.measure_type,
      points: record.points,
    });
    const feature = createFeatureForMeasure(measure, true);
    feature.set("toBeStored", record.toBeStored);
    feature.set("dbId", record.dbId);
    feature.set("showValue", record.type === RecordType.AREA);
    vectorSource.addFeature(feature);
  });
}

// Helper function to assign a unique id to a feature if not already assigned
function assignUniqueIdToFeature(feature) {
  if (!feature.getId()) {
    feature.setId(uuid());
  }
}

// Helper function to assign a name to a feature if not already assigned
function assignNameToFeature(feature, nextFeatureName) {
  if (!feature.get("name")) {
    feature.set("name", nextFeatureName);
  }
}

// Helper function to add a change listener to a feature
function addChangeListenerToFeature(feature, onChangeFeature) {
  feature.on("change", (e) => {
    onChangeFeature(e.target);
  });
}

// Helper function to mark a feature for modification if required
function markFeatureForModificationIfNeeded(feature, modifyFeatures) {
  if (!feature.get("toBeStored")) {
    modifyFeatures.current.push(feature);
  }
}

// Helper function to add extra resolutions
function extendResolutions(viewOptions) {
  const lastResolution =
    viewOptions.resolutions[viewOptions.resolutions.length - 1];

  const firstResolution = viewOptions.resolutions[0];
  return [
    ...viewOptions.resolutions,
    lastResolution * 0.5,
    lastResolution * 0.25,
    lastResolution * 0.125,
  ];
}

// Helper function to create and configure the map
function createAndConfigureMap(
  mapView,
  tifSource,
  vectorSource,
  modifyFeatures,
  setTooltip
) {
  const map = new Map({
    layers: [
      new TileLayer({
        source: tifSource,
      }),
      new VectorLayer({
        source: vectorSource,
        style: (feature) => featureStyleFunction(feature),
      }),
    ],
    controls: defaults({ attribution: false, zoom: false }),
    view: mapView,
  });

  const modifyInteraction = new Modify({
    features: modifyFeatures.current,
    insertVertexCondition: () => false,
  });

  map.addInteraction(modifyInteraction);

  map.on("pointermove", (event) => {
    // console.log("pointermove", event);
    setTooltip(null); // Hide any previous tooltip
    map.forEachFeatureAtPixel(event.pixel, function (feature) {
      if (feature.get("hidden") === true) return;
      const name = feature.get("name");
      if (!name) return;
      setTooltip({
        position: event.pixel,
        text: feature.get("name"),
      });
    });
  });

  return { map, modifyInteraction };
}

// Prevents the context menu from opening when right-clicking
function preventContextMenu(event) {
  event.originalEvent.stopPropagation();
  event.originalEvent.preventDefault();
}

// Sets the tool mode to the feature being drawn
function handleDrawStart(event, currentToolMode) {
  const feature = event.feature;
  feature.set("measureType", currentToolMode);
}

// Completes the drawing when user right-clicks on the map
function finishDrawingOnRightClick(
  event,
  drawInteraction,
  context,
  onAbortFeature
) {
  // Check if the right mouse button was clicked
  if (event.originalEvent.button !== 2) {
    return;
  }
  // Check the type of the draw interaction
  if (drawInteraction.type_ === "Point") {
    // If it's a point, do not create a feature and abort the drawing
    //console.log("aborting")
    context.isAborted = true;
    // drawInteraction.abortDrawing();
    drawInteraction.finishDrawing();
    return;
  }

  // Check if there are enough points for the given geometry type
  const minPoints = {
    LineString: 2 + 1,
    Polygon: 3 + 1,
  };

  const coordinates =
    drawInteraction.type_ === "Polygon"
      ? drawInteraction.sketchLineCoords_
      : drawInteraction.sketchCoords_;

  if (!coordinates) {
    // first point is not yet set
    drawInteraction.finishDrawing();
    drawInteraction.abortDrawing();

    onAbortFeature();
    return;
  }

  if (coordinates.length < minPoints[drawInteraction.type_]) {
    context.isAborted = true;
    drawInteraction.abortDrawing();
    drawInteraction.finishDrawing();

    if (coordinates.length <= minPoints[drawInteraction.type_] - 1) {
      // feature is not complete, call onAbortFeature
      onAbortFeature();
    }
    return;
  }

  // Finish the drawing
  drawInteraction.finishDrawing();
}

export default TiffViewer;
