import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { DimensionType } from "../../api/dimensionType";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import useViewerDisplayMode from "../../hooks/useViewerDisplayMode";
import { DisplayType } from "../../redux/viewer/viewerReducer";
import { filterRecordTypesForToolMode } from "../../api/consts";

const MeasureControl = ({ onCreateMeasure }) => {
  const measureMode = useSelector((state) => state.measures.mode);
  const [displayMode] = useViewerDisplayMode();
  const { t } = useTranslation(namespaces.viewer);

  const { project } = useSelector((state) => state.info);

  const handleButtonClick = (type) => (e) => {
    e.stopPropagation();
    onCreateMeasure({ type });
  };

  const [controlPanelOpen, setControlPanelOpen] = React.useState(false);
  const [minMaxActive, setMinMaxActive] = React.useState(false);

  const toggleControlPanel = () => {
    setControlPanelOpen(!controlPanelOpen);
  };

  const toggleMinMax = () => {
    setMinMaxActive(!minMaxActive);
  };

  const visibleButtons = useMemo(() => {
    return buttons.filter((button) => {
      // filter records display
      if (
        measureMode === DimensionType.D2 &&
        displayMode === DisplayType.RECORDS
      ) {
        const res = filterRecordTypesForToolMode(
          project?.allowedRecordTypes,
          button.action
        );
        if (res.length === 0) return false;
      }

      // 2d 3d filter
      if (measureMode === DimensionType.D2 && button.action === "volume")
        return false;
      if (measureMode === DimensionType.D3 && button.action === "area")
        return false;
      return true;
    });
  }, [measureMode, displayMode, project?.allowedRecordTypes]);

  return (
    <div
      className={`flex flex-row items-center h-[50px] justify-center rounded-full transition-all duration-300 rounded-full shadow-sm shadow-[#ddd] bg-${
        controlPanelOpen ? "white" : "primary"
      }`}
    >
      <button
        title={
          controlPanelOpen
            ? t("measureControl.helpClose")
            : t("measureControl.helpOpen")
        }
        onClick={toggleControlPanel}
        className={`h-[50px] w-[50px] flex flex-col items-center justify-center`}
      >
        <div className="relative h-[24px] w-[24px] flex justify-center items-center focus:none">
          <img
            className={`pointer-events-none absolute transition-all duration-300 opacity-${
              controlPanelOpen ? 0 : 100
            }`}
            src="/images/viewer_measurecontrol_open.svg"
            alt=""
            width={16}
            height={16}
          />
          <img
            className={`pointer-events-none absolute transition-all duration-300 opacity-${
              controlPanelOpen ? 100 : 0
            }`}
            src="/images/viewer_measurecontrol_close.svg"
            alt=""
          />
        </div>
      </button>

      <div
        className={`flex flex-row items-center h-[36px] transition-all duration-300 ${
          controlPanelOpen ? "scale-100 mr-3 gap-[13px]" : "scale-0"
        }`}
      >
        {controlPanelOpen ? (
          <div className="ml-[5px] w-[2px] bg-secondary h-[25px]" />
        ) : null}
        {visibleButtons.map((button) => (
          <button
            key={button.action}
            // title={t(button.title)}
            className={`relative ${
              controlPanelOpen ? "w-10 h-10" : ""
            } rounded-full flex justify-center items-center transition-transform duration-300 transform ${
              controlPanelOpen ? "scale-100" : "scale-0"
            }`}
            onClick={handleButtonClick(button.action)}
          >
            {controlPanelOpen && (
              <React.Fragment>
                <img
                  src={button.icon}
                  alt={button.title}
                  className="h-[25px]"
                />

                <div className="absolute inset-0 opacity-0 transition-opacity duration-300 hover:opacity-100">
                  <span
                    className="absolute left-[50%] top-[60px] text-sm font-bold rounded-full bg-white p-1 px-2"
                    style={{ transform: "translate(-50%, -50%)" }}
                  >
                    {t(button.title)}
                  </span>
                </div>
              </React.Fragment>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

const buttons = [
  {
    title: "measureControl.createPoint",
    icon: "/images/viewer_measurecontrol_point.svg",
    action: "point",
  },
  {
    title: "measureControl.createDistance",
    icon: "/images/viewer_measurecontrol_distance.svg",
    action: "distance",
  },
  {
    title: "measureControl.createVolume",
    icon: "/images/viewer_measurecontrol_area.svg",
    action: "volume",
  },
  {
    title: "measureControl.createArea",
    icon: "/images/viewer_measurecontrol_area.svg",
    action: "area",
  },
];

export default MeasureControl;
