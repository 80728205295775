import { useEffect } from "react";
import usePotreeViewer from "./usePotreeViewer";

const usePotreeMeasurementsListeners = ({
  onAddMeasurement,
  onRemoveMeasurement,
  onChangeMeasurement,
  onStopInsertingMeasurement,
}) => {
  const { viewer } = usePotreeViewer();

  // bind to potree events
  useEffect(() => {
    if (!viewer) return;
    if (!onAddMeasurement && !onRemoveMeasurement && !onChangeMeasurement)
      return;

    const addMeasurementListener = (event) => {
      if (onAddMeasurement) onAddMeasurement(event.measurement);
      if (onChangeMeasurement)
        registerListeners(event.measurement, onChangeMeasurement);
    };

    const stopInsertingMeasurementListener = (event) => {
      if (onStopInsertingMeasurement) onStopInsertingMeasurement(event.measure);
    };

    // const removeMeasurementListener = (event) => {
    //   if (onRemoveMeasurement) onRemoveMeasurement(event.measurement);
    // };

    viewer.scene.addEventListener("measurement_added", addMeasurementListener);
    viewer.scene.addEventListener(
      "stop_inserting_measurement",
      stopInsertingMeasurementListener
    );
    // viewer.scene.addEventListener(
    //   "measurement_removed",
    //   removeMeasurementListener
    // );

    return () => {
      viewer.scene.removeEventListener(
        "measurement_added",
        addMeasurementListener
      );

      viewer.scene.removeEventListener(
        "stop_inserting_measurement",
        stopInsertingMeasurementListener
      );
      // viewer.scene.removeEventListener(
      //   "measurement_removed",
      //   removeMeasurementListener
      // );
    };
  }, [
    viewer,
    onAddMeasurement,
    onRemoveMeasurement,
    onChangeMeasurement,
    onStopInsertingMeasurement,
  ]);
};

const registerListeners = (measurement, onChangeMeasurement) => {
  measurement.addEventListener("marker_added", (event) => {
    // console.log("marker_added", event);
    if (onChangeMeasurement) onChangeMeasurement(event.measurement);
  });

  measurement.addEventListener("marker_removed", (event) => {
    // console.log("marker_removed", event);
    if (onChangeMeasurement) onChangeMeasurement(event.measurement);
  });

  measurement.addEventListener("marker_moved", (event) => {
    // console.log("marker_moved", event);
    if (onChangeMeasurement) onChangeMeasurement(event.measure);
  });

  measurement.addEventListener("marker_dropped", (event) => {
    // console.log("marker_dropped", event);
    if (onChangeMeasurement) onChangeMeasurement(event.measurement);
  });
};

export default usePotreeMeasurementsListeners;
