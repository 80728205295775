import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import Logo from "../components/Logo";
import AnimateSpin from "../components/buttons/AnimateSpin";
import Toast from "../components/Toast";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import ForgotPasswordDialog from "../components/dialogs/ForgotPasswordDialog";
import TextButton from "../components/buttons/TextButton";
const LoginPage = () => {
  const { t } = useTranslation(namespaces.pages);

  const navigate = useNavigate();

  const [formState, setFormState] = React.useState({
    email: "",
    password: "",
  });

  const { login, isLoginLoading, error } = useAuth();

  const handleLogin = async () => {
    // console.log(formState);
    try {
      await login(formState.email, formState.password);
      navigate("/admin");
    } catch {}
  };

  const errorText = error?.data?.error
    ? t("loginPage." + error?.data?.error)
    : "";

  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] =
    React.useState(false);

  return (
    //centered login form
    <div className="flex  justify-center h-screen">
      <div className="w-[590px] mt-[90px]">
        <div className="bg-bg2 rounded-lg pb-[65px] px-[101px] shadow-lg">
          <div className="pt-[37px]">
            <h1 className="text-[30px] p-7 font-bold text-700 text-center">
              {t("loginPage.title")}
            </h1>
          </div>
          <div className="mb-[37px]">
            <label
              className="block text-left text-700 font-bold text-sm mb-2 text-primary"
              htmlFor="username"
            >
              {t("loginPage.username")}
            </label>
            <input
              className="rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1"
              id="username"
              type="text"
              placeholder="jiri@podebrady.cz"
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
            />
          </div>
          <div className="mb-[42px]">
            <label
              className="block text-left text-700 font-bold text-sm mb-2 text-primary"
              htmlFor="password"
            >
              {t("loginPage.password")}
            </label>
            <input
              className="rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1"
              id="password"
              type="password"
              placeholder=""
              onChange={(e) =>
                setFormState({ ...formState, password: e.target.value })
              }
            />
            {/* <p className="text-red-500 text-xs italic">
              Please choose a password.
            </p> */}
          </div>

          <div className="flex flex-col justify-center items-center">
            {/* <button
              className="rounded-lg bg-primary py-4 px-4 w-full text-lg font-bold text-white hover:bg-primaryDark transition-colors duration-300 flex justify-center items-center"
              type="button"
              onClick={handleLogin}
              disabled={
                isLoginLoading || !formState.email || !formState.password
              }
            >
              {isLoginLoading ? (
                <span className="flex gap-[5px]">
                  <AnimateSpin />
                  {t("loading")}
                </span>
              ) : (
                t("loginPage.login")
              )}
            </button> */}
            <TextButton
              disabled={
                isLoginLoading || !formState.email || !formState.password
              }
              className={"h-[56px]"}
              label={t("loginPage.login")}
              onClick={handleLogin}
              isLoading={isLoginLoading}
            />
            {error && (
              <Toast message={errorText || error?.error} type="error" />
            )}
            <a
              className="underline decoration-1 visited:text-black text-black text-base font-light mt-[17px] hover:decoration-4"
              href="#"
              style={{ color: "#000000" }}
              onClick={(e) => {
                e.preventDefault();
                setIsForgotPasswordDialogOpen(true);
              }}
            >
              {t("loginPage.forgotPassword")}
            </a>
          </div>
        </div>
        <div className="flex justify-center items-center text-base mt-[44px]">
          <Logo />
        </div>
      </div>
      <ForgotPasswordDialog
        isOpen={isForgotPasswordDialogOpen}
        onClose={() => setIsForgotPasswordDialogOpen(false)}
      ></ForgotPasswordDialog>
    </div>

    // <div className="">

    //   <div className="w-1/4">
    //     <h1>{t("loginPage.title")}</h1>

    //     <div className="place-content-center grid grid-cols-2 gap-4">
    //       <label>{t("loginPage.username")}</label>
    //       <input type="text" />

    //       <label>{t("loginPage.password")}</label>
    //       <input type="password" />
    //     </div>

    //     <button onClick={handleLogin}>{t("loginPage.login")}</button>
    //   </div>
    // </div>
  );
};

export default LoginPage;
