import React, { useEffect } from "react";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogTitle from "./DialogTitle";
import DialogWrapper from "./DialogWrapper";
import TextInput from "../inputs/TextInput";
import DateInput from "../inputs/DateInput";
import DateSeperatedInput from "../inputs/DateSeparatedInput";
import CheckboxInput from "../inputs/CheckboxInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import {
  useEditProjectMutation,
  useCreateProjectMutation,
} from "../../api/managementApi";
import { folderInputProps } from "../../utils/fsUtils";
import { set } from "ol/transform";
import SelectBox from "../inputs/SelectboxInput";
import {
  ProjectType,
  ProjectTypeToRecordTypes,
  getProjectTypeFromRecordTypes,
} from "../../api/consts";
import TogglesSelect from "../inputs/TogglesSelect";

function getProjectTypesOptions(t) {
  const projectTypesOptions = Object.values(ProjectType).map((type) => {
    return {
      value: type,
      label: t(`clientPage.createProjectDialog.projectType.${type}`),
    };
  })

  // projectTypesOptions.push({
  //   value: "GENERIC",
  //   label: t(`clientPage.createProjectDialog.projectType.OTHER`),
  // });

  return projectTypesOptions;
}

const EditProjectDialog = ({
  isOpen,
  onSubmit,
  project,
  onCancel,
  disabled,
  isCreate,
  clientId,
}) => {
  const [name, setName] = React.useState(project?.name || "");
  const [nameError, setNameError] = React.useState(false);
  const [expirationDate, setExpirationDate] = React.useState(
    project?.expiration || ""
  );
  const [allowedRecordTypes, setAllowedRecordTypes] = React.useState(["GENERIC"]);

  const [editProject, { error: editProjectError }] = useEditProjectMutation();
  const [createProject, { error: createProjectError }] =
    useCreateProjectMutation();

  const { t } = useTranslation(namespaces.pages);

  useEffect(() => {
    if(isOpen) {
      setAllowedRecordTypes(["GENERIC"]);
      setName(project?.name || "");
      setExpirationDate(project?.expiration || "");
    }
  }, [isOpen]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (nameError) return;
    
      const expiration = expirationDate ? new Date(expirationDate) : null;
      if (isCreate) {
        try {
          await createProject({
            name,
            clientId: clientId,
            expiration: expiration,
            allowedRecordTypes: allowedRecordTypes,
          }).unwrap();
          onSubmit();

          setName("");
          setExpirationDate("");
        } catch (e) {
          console.log(e);
        }
        return;
      } else {
        try {
          await editProject({
            id: project.id,
            name,
            expiration: expiration,
          }).unwrap();
          onSubmit();
        } catch (e) {
          console.log(e);
        }
      }
    },
    [
      folderInputProps?.pattern,
      name,
      onSubmit,
      nameError,
      expirationDate,
      allowedRecordTypes,
      clientId,
    ]
  );

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const handleExpirationDateChange = (value) => {
    setExpirationDate(value);
  };

  const handleChangeProjectType = (selected) => {
    // console.log(selected);
    const value = selected?.value;
    const allowed = ProjectTypeToRecordTypes[value];
    console.log(allowed);
    setAllowedRecordTypes(allowed || []);
  };

  useEffect(() => {
    setName(project?.name || "");
  }, [project?.name]);

  useEffect(() => {
    setExpirationDate(project?.expiration || "");
  }, [project?.expiration]);


  const projectType = getProjectTypeFromRecordTypes(
    allowedRecordTypes
  );

  const [projectTypesOptions, setProjectTypesOptions] = React.useState([]);

  useEffect(() => {
    setProjectTypesOptions(getProjectTypesOptions(t));
  }, [t]);


  // const [checked, setChecked] = React.useState(false);

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-y-[39px]">
          <DialogTitle
            title={
              isCreate
                ? t("clientPage.createProjectDialog.title")
                : t("clientPage.editProjectDialog.title")
            }
          />

          <TextInput
            label={t("clientPage.editProjectDialog.projectName")}
            value={name || ""}
            onChange={setName}
            onValidityChange={(valid) => {
              setNameError(!valid);
            }}
            disabled={disabled}
            {...folderInputProps}
          ></TextInput>

          {isCreate && (
            <SelectBox
              label={t("clientPage.editProjectDialog.projectType")}
              options={projectTypesOptions}
              selectedOption={projectType || ""}
              onChange={handleChangeProjectType}
            ></SelectBox>
            // <TogglesSelect
            //   label={t("clientPage.editProjectDialog.projectType")}
            //   options={projectTypesOptions}
            //   selectedOptions={[projectType] || []}
            //   onChange={handleChangeProjectType}
            // ></TogglesSelect>
          )}

          {/* <DateSeperatedInput
            label={t("clientPage.editProjectDialog.expirationDate")}
            value={expirationDate || ""}
            onChange={handleExpirationDateChange}
          ></DateSeperatedInput> */}
          {/* <CheckboxInput
            label={t("clientPage.editProjectDialog.expirationDate")}
            checked={checked}
            onChange={setChecked}
          ></CheckboxInput> */}
          <DateInput
            label={t("clientPage.editProjectDialog.expirationDate")}
            value={expirationDate || ""}
            onChange={handleExpirationDateChange}
          ></DateInput>

          <TextButton
            disabled={
              !name ||
              nameError ||
              (name === project?.name &&
                expirationDate === project?.expiration) ||
              disabled
            }
            className={"h-[56px]"}
            label={
              isCreate
                ? t("clientPage.createProjectDialog.create")
                : t("clientPage.editProjectDialog.submit")
            }
            onClick={handleSubmit}
            isLoading={false}
          />
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default EditProjectDialog;
