import React from "react";
import TextInput, { TextInputSmall } from "../inputs/TextInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import FilesInput from "../inputs/FilesInput";
import TextAreaInput, { TextAreaInputSmall } from "../inputs/TextAreaInput";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import { useSelector } from "react-redux";
import DownloadFiles from "../inputs/DownloadFiles";
import InputLabel from "../inputs/InputLabel";
import useAuthInfo from "../../hooks/useAuthInfo";

function ParksAndGardensForm({ data, onChange, issues }) {
  const { t } = useTranslation(namespaces.viewer);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  const setData = (newData) => {
    onChange(newData);
  };

  const handleTextChange = (name) => (value) => {
    setData({ ...data, [name]: value });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setData({ ...data, [name]: selectedOption });
  };

  const handleFilesChange = (files) => {
    setData({ ...data, files: files });
  };

  return (
    <div className="flex flex-col gap-[20px] bg-bg1 rounded-xl pb-[25px]">
      {/* <div>
        <SelectBoxInput
          label={t("form.type")}
          options={["", "Rostlina", "Obecny"]}
          selectedOption={data.type}
          onChange={handleSelectChange("type")}
        />
      </div> */}
      <div className="">
        <TextInputSmall
          disabled={!canEdit}
          extraError={getErrorForPath(issues, ["inventoryNumber"])}
          lightTheme={true}
          label={t("form.inventoryNumber")}
          value={data.inventoryNumber}
          onChange={handleTextChange("inventoryNumber")}
        />
      </div>
      <div className="">
        <TextInputSmall
          disabled={!canEdit}
          lightTheme={true}
          extraError={getErrorForPath(issues, ["floraName"])}
          label={t("form.name")}
          value={data.floraName}
          onChange={handleTextChange("floraName")}
        />
      </div>
      <div>
        <TextAreaInputSmall
          lightTheme={true}
          extraError={getErrorForPath(issues, ["note"])}
          label={t("form.note")}
          value={data.note}
          onChange={handleTextChange("note")}
        />
      </div>
      <div>
        <InputLabel>{t("form.files")}</InputLabel>

        <DownloadFiles
          disabled={!canEdit}
          files={data.photos || []}
          onFilesChange={(_files) => {
            setData({ ...data, photos: _files });
          }}
          lightTheme={true}
          displayFileSizes={true}
        ></DownloadFiles>
        <div className="h-[13px]"></div>
        <FilesInput
          lightTheme={true}
          disabled={!canEdit}
          // label={t("form.files")}
          dropAreaLabel={t("form.dropAreaLabel")}
          files={data.files || []}
          onChange={handleFilesChange}
        />
      </div>
    </div>
  );
}

export default ParksAndGardensForm;
