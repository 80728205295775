import uuid from "../../utils/uuid";

// base class of measures
class Measure {
  constructor(name, type) {
    this._name = name;
    this._type = type;
    // console.log(name, type);
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  set id(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  getResult() {
    return this.result;
  }

  serialize() {
    return {
      name: this.name,
      type: this.type,
      id: this.id,
      toBeStored: this.toBeStored,
      dbId : this.dbId
    };
  }
}

export default Measure;
