import React from "react";

const DialogWrapper = ({ children, onClick }) => {
  
  const handleCancel = (e) => {
    e.preventDefault();
    onClick(e);
  };
  
  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center"
      onClick={handleCancel}
    >
      {children}
    </div>
  );
};

export default DialogWrapper;
