import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import { getToken } from "../providers/authProvider";
import { identifyGeometry } from "../utils/geo";

export const DRAFT_PREFIX = ":draft:";

export const managementApi = createApi({
  reducerPath: "managementApi",
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  // refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 10,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listClients: builder.query({
      query: () => ({
        url: `client`,
        method: "GET",
        // body: { path: path },
        responseHandler: (res) => {
          return res.json().then((data) => {
            return data.data;
          });
        },
      }),
    }),

    getClient: builder.query({
      query: ({ id }) => ({
        url: `client/${id}`,
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            return data.data;
          });
        },
      }),
    }),

    createClient: builder.mutation({
      query: ({ id, name }) => ({
        url: "client",
        method: "POST",
        body: { name, createdAt: new Date().toISOString() },
      }),
    }),

    renameClient: builder.mutation({
      query: ({ id, name }) => ({
        url: `client/${id}`,
        body: { name },
        method: "PATCH",
      }),
    }),

    deleteClient: builder.mutation({
      query: ({ id }) => ({
        url: `client/${id}`,
        method: "DELETE",
      }),
    }),

    listProjects: builder.query({
      query: ({ clientId }) => ({
        url: "project?clientId=" + clientId,
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            return data.data;
          });
        },
      }),
    }),

    getProject: builder.query({
      query: ({ clientId, id }) => ({
        url: `project/${id}`,
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            return data.data;
          });
        },
      }),
    }),
    // data: { clientId, id },

    createProject: builder.mutation({
      query: ({ clientId, id, name, expiration, allowedRecordTypes }) => ({
        url: "project",
        method: "POST",
        body: { clientId, name, expiration, allowedRecordTypes },
      }),
    }),

    renameProject: builder.mutation({
      query: ({ clientId, id, name }) => ({
        url: `project/${id}`,
        body: { name },
        method: "PATCH",
      }),
    }),

    editProject: builder.mutation({
      query: ({ id, name, expiration }) => ({
        url: `project/${id}`,
        body: { name, expiration },
        method: "PATCH",
      }),
    }),

    deleteProject: builder.mutation({
      query: ({ clientId, id }) => ({
        url: `project/${id}`,
        // data: { clientId, id },
        method: "DELETE",
      }),
    }),

    listProjectStates: builder.query({
      query: ({ clientId, projectId }) => ({
        url: "state?projectId=" + projectId,
        // data: { clientId, projectId },
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            const sorted = data.data
              .filter((s) => !s.name.startsWith(DRAFT_PREFIX))
              .sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
              });
            return sorted.map((s) => {
              return {
                ...s,
                clientId, // add clientId to state, needed to creating links
              };
            });
          });
        },
      }),
      // transformResponse: (res) => {},
    }),

    renameProjectState: builder.mutation({
      query: ({ clientId, projectId, id, name }) => ({
        url: `state/${id}`,
        body: { /*clientId, projectId, id,*/ name },
        method: "PATCH",
      }),
    }),

    editProjectState: builder.mutation({
      query: ({ clientId, projectId, id, name, files, conversions }) => ({
        url: `state/${id}`,
        data: { clientId, projectId, id, name, files, conversions },
        method: "PATCH",
      }),
    }),

    deleteProjectState: builder.mutation({
      query: ({ clientId, projectId, id }) => ({
        url: `state/${id}`,
        method: "DELETE",
      }),
    }),
    // data: { clientId, projectId, id },

    getProjectState: builder.query({
      query: ({ clientId, projectId, id }) => ({
        url: `state/${id}`,
        method: "GET",
        // data: { clientId, projectId, id },
      }),
      transformResponse: (res) => {
        // console.log("projectState", res);
        return res.data;
      },
    }),

    createProjectState: builder.mutation({
      query: ({ clientId, projectId, name, files, conversions, isDraft }) => ({
        url: "state",
        body: {
          // clientId,
          projectId,
          name,
          // files,
          // conversions,
          // isDraft,
          // createdAt: new Date().toISOString(),
        },
        method: "POST",
      }),
      transformResponse: (res) => {
        console.log("res", res);
        return res.data;
      },
    }),

    getMeasures: builder.query({
      query: ({ clientId, projectId, dimension }) => ({
        url: `measurement?projectId=${projectId}&sortBy=name&sortOrder=asc`,
        method: "GET",
        // data: { clientId, projectId, dimension },
        responseHandler: (res) => {
          return res.json().then((data) => {
            const result = data.data?.filter(
              (m) => m.dimension === convertDimension(dimension)
            );
            return convertMeasureFromServer(result);
          });
        },
      }),
      // provide tags
      providesTags: (result, error, arg) => [{ type: "Measures", id: "LIST" }],
    }),

    searchMeasures: builder.query({
      query: ({ projectId, dimension, searchString }) => ({
        url: `measurement?projectId=${projectId}&searchString=${searchString}&sortBy=name&sortOrder=asc`,
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            const result = data.data?.filter(
              (m) => m.dimension === convertDimension(dimension)
            );
            return convertMeasureFromServer(result);
          });
        },
      }),
      providesTags: (result, error, arg) => [{ type: "Measures", id: "LIST" }],
    }),

    createMeasure: builder.mutation({
      query: ({ projectId, dimension, name, type, description, data }) => ({
        url: "measurement",
        body: {
          projectId,
          dimension: convertDimension(dimension),
          name,
          type: convertMeasurementType(type),
          description,
          data: JSON.stringify(data),
        },
        method: "POST",
      }),
      transformResponse: (res) => {
        // console.log("res", res);
        return res.data;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Measures", id: "LIST" },
      ],
    }),

    updateMeasure: builder.mutation({
      query: ({ id, name, description, data }) => ({
        url: `measurement/${id}`,
        body: {
          name,
          description,
          data: JSON.stringify(data),
        },
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Measures", id: "LIST" },
      ],
    }),

    deleteMeasure: builder.mutation({
      query: ({ id }) => ({
        url: `measurement/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Measures", id: "LIST" },
      ],
    }),

    saveMeasures: builder.mutation({
      query: ({ clientId, projectId, measures, dimension }) => ({
        url: "saveMeasures",
        data: {
          clientId,
          projectId,
          dimension,
          measures,
        },
      }),
    }),

    createRecord: builder.mutation({
      query: ({ projectId, type, name, data, note, showValues, extra }) => {
        return {
          url: "record",
          body: {
            projectId,
            type,
            name,
            note: note ? note : undefined,
            showValues,
            data: JSON.stringify(data),
            extra: extra,
          },
          method: "POST",
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Record", id: "LIST" }],
    }),

    updateRecord: builder.mutation({
      query: ({ id, name, data, extra, note }) => {
        return {
          url: `record/${id}`,
          body: {
            name,
            note: note ? note : undefined,
            data: JSON.stringify(data),
            extra: extra,
          },
          method: "PATCH",
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Record", id: arg },
        { type: "Record", id: "LIST" },
      ],
    }),

    deleteRecord: builder.mutation({
      query: ({ id }) => ({
        url: `record/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Record", id: arg },
        { type: "Record", id: "LIST" },
      ],
    }),

    getRecord: builder.query({
      query: ({ id }) => ({
        url: `record/${id}`,
        method: "GET",
      }),
      transformResponse: (res) => {
        return convertRecordFromServer(res.data);
      },
      providesTags: (result, error, arg) => [{ type: "Record", id: "LIST" }],
    }),

    listRecords: builder.query({
      query: ({ projectId }) => ({
        url: `record?projectId=${projectId}&sortBy=name&sortOrder=asc`,
        method: "GET",
        responseHandler: (res) => {
          return res.json().then((data) => {
            return convertRecordsResultFromServer(data);
          });
        },
      }),
      providesTags: (result, error, arg) => [{ type: "Record", id: "LIST" }],
    }),

    searchRecords: builder.query({
      query: ({ projectId, searchString, areaRecordType }) => {
        let url = `record?projectId=${projectId}`;
        if (areaRecordType && areaRecordType.length > 0) {
          for (let i = 0; i < areaRecordType.length; i++) {
            url += `&areaRecordTypes[]=${areaRecordType[i]}`;
          }
        }
        if (searchString) {
          url += `&searchString=${searchString}`;
        }

        url += `&sortBy=name&sortOrder=asc`;

        return {
          url: `${url}`,
          method: "GET",
          responseHandler: (res) => {
            return res.json().then((data) => {
              return convertRecordsResultFromServer(data);
            });
          },
        };
      },
      providesTags: (result, error, arg) => [{ type: "Record", id: "LIST" }],
    }),
  }),
});

const removeNullValuesFromObj = (obj) => {
  if(!obj) return undefined;
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== null) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const {
  useGetClientQuery,
  useCreateClientMutation,
  useListClientsQuery,
  useRenameClientMutation,
  useDeleteClientMutation,
  useGetProjectQuery,
  useListProjectsQuery,
  useCreateProjectMutation,
  useRenameProjectMutation,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useListProjectStatesQuery,
  useCreateProjectStateMutation,
  useEditProjectStateMutation,
  useRenameProjectStateMutation,
  useDeleteProjectStateMutation,
  useGetMeasuresQuery,
  useLazyGetMeasuresQuery,
  useLazySearchMeasuresQuery,
  useSaveMeasuresMutation,
  useCreateMeasureMutation,
  useUpdateMeasureMutation,
  useDeleteMeasureMutation,
  useGetProjectStateQuery,
  useCreateRecordMutation,
  useUpdateRecordMutation,
  useDeleteRecordMutation,
  useGetRecordQuery,
  useLazyGetRecordQuery,
  useListRecordsQuery,
  useLazyListRecordsQuery,
  useSearchRecordsQuery,
  useLazySearchRecordsQuery,
  useLazyGetProjectStateQuery,
} = managementApi;

const MeasurementType = {
  AREA: "AREA",
  VOLUME: "VOLUME",
  DISTANCE: "DISTANCE",
  POINT: "POINT",
};

const convertDimension = (dimension) => {
  switch (dimension) {
    case "2D":
      return DimensionType.TwoDimensional;
    case "3D":
      return DimensionType.ThreeDimensional;
    default:
      return null;
  }
};

const convertMeasurementType = (type) => {
  switch (type) {
    case "area":
      return MeasurementType.AREA;
    case "volume":
      return MeasurementType.VOLUME;
    case "distance":
      return MeasurementType.DISTANCE;
    case "point":
      return MeasurementType.POINT;
    default:
      return null;
  }
};

const DimensionType = {
  TwoDimensional: "TwoDimensional",
  ThreeDimensional: "ThreeDimensional",
};

const convertMeasurementTypeFromServer = (type) => {
  switch (type) {
    case MeasurementType.AREA:
      return "area";
    case MeasurementType.VOLUME:
      return "volume";
    case MeasurementType.DISTANCE:
      return "distance";
    case MeasurementType.POINT:
      return "point";
    default:
      return null;
  }
};

const convertDimensionFromServer = (dimension) => {
  switch (dimension) {
    case DimensionType.TwoDimensional:
      return "2D";
    case DimensionType.ThreeDimensional:
      return "3D";
    default:
      return null;
  }
};

const convertMeasureFromServer = (measures) => {
  return measures.map((m) => ({
    ...m,
    dbId: m.id,
    type: convertMeasurementTypeFromServer(m.type),
    dimension: convertDimensionFromServer(m.dimension),
    points: JSON.parse(m.data),
    toBeStored: true,
  }));
};

const geometryToMeasureType = (geometry) => {
  const map = {
    point: "point",
    line: "distance",
    polygon: "area",
  };
  return map[geometry] || null;
};

const convertRecordFromServer = (record) => {
  const points = JSON.parse(record.data);

  const geometry = identifyGeometry(points);
  // console.log("geometry: ", geometry);
  // console.log("points: ", points);
  // console.log("measuretype: ", geometryToMeasureType(geometry));
  return {
    ...record,
    dbId: record.id,
    type: record.type,
    measure_type: geometryToMeasureType(geometry),
    // points.length > 1 ? "area" : "point",
    points: points,
    toBeStored: true,
  };
};

const convertRecordsResultFromServer = (res) => {
  return {
    extra: res.extra,
    records: convertRecordsFromServer(res.data),
  };
};

const convertRecordsFromServer = (records) => {
  return records.map((r) => convertRecordFromServer(r));
};
