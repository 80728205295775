import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { default as api, default as uploadApi } from "../../api/uploadApi";
import { namespaces } from "../../consts/i18n";
import { convertBytesToFileSizeString } from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import LoadingBar from "../LoadingBar";
import DialogWrapper from "./DialogWrapper";
import MessageDialog from "./MessageDialog";
import PreviewFileDialog from "./PreviewFileDialog";
import { DropFilesComponent } from "./UploadMeasureDialog";
import { set } from "ol/transform";

const GrayWrapper = ({ children, className, ...otherProps }) => {
  return (
    <div
      className={`bg-bg1 rounded-lg pt-[24px] pb-[17px] py-[25px] flex flex-col ${
        className || ""
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
};

const DownloadFilesDialog = ({ isOpen, onClose, measure, disabled }) => {
  const { t } = useTranslation(namespaces.pages);

  // const files = measure?.files || [];
  const [previewFile, setPreviewFile] = React.useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  const [isDownloading, setIsDownloading] = React.useState({});
  const [downloadProgress, setDownloadProgress] = React.useState({});
  const [downloadErrors, setDownloadErrors] = React.useState({});

  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadError, setUploadError] = React.useState(null);

  const [filesToUpload, setFilesToUpload] = React.useState([]);

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setFilesToUpload([]);
      setUploadError(null);
    }
  }, [isOpen]);

  const handleCancel = (e) => {

    if (isUploading) {
      setCancelDialogOpen(true);
      return;
    }
    
    e.preventDefault();
    onClose();
  };

  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    // load files
    if (isOpen && !isUploading) {
      uploadApi.getFiles(measure.id).then((res) => {
        console.log("files", res);
        setFiles(res);
      });
    }
  }, [isOpen, isUploading]);

  const handleDownload = async (file) => {
    try {
      setIsDownloading({ ...isDownloading, [file.id]: true });
      await api.downloadFile(file.id, {
        originalName: file.name,
        onDownloadProgress: (progressEvent) => {
          setDownloadProgress({
            ...downloadProgress,
            [file.id]: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          });
        },
      });
      setIsDownloading({ ...isDownloading, [file.id]: false });
    } catch (e) {
      setDownloadErrors({ ...downloadErrors, [file.id]: e.message });
      setIsDownloading({ ...isDownloading, [file.id]: false });
    }
  };

  const abortController = useRef(null);

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      const controller = new AbortController();
      abortController.current = controller;

      const res = await uploadApi.uploadFiles(measure.id, filesToUpload, {
        onUploadProgress: ({ progress }) => {
          setUploadProgress(progress);
        },
        abortSignal: controller.signal,
      });

      setFilesToUpload([]);
      setUploadProgress(0);

      setIsUploading(false);
    } catch (e) {
      setUploadError(e.message);
      setIsUploading(false);
    }
  };

  const handleCancelAbort = (e) => {
    setCancelDialogOpen(false);
  };

  const handleAbortUpload = (e) => {
    if (abortController.current) {
      abortController.current.abort();
    }
    setCancelDialogOpen(false);
    setIsUploading(false);
    onClose();
  };

  const handleDeleteFile = async (file) => {
    try {
      await uploadApi.deleteFile(file.id);
      setFiles(files.filter((f) => f.id !== file.id));
    } catch (e) {
      console.error(e);
    }
  };

  const openPreview = async (file) => {
    setPreviewFile(file);
    setIsPreviewOpen(true);
  };

  const stateFiles = takeOnlyStatesFiles(files);
  const additionalFiles = takeOnlyAdditionalFiles(files);

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px] max-h-[100vh] overflow-y-auto overflow-x-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div
          className="flex flex-col gap-y-[30px] items-left justify-left "
        >
          <div>
            <h3 className="text-xxxl font-bold text-center px-[26px]">
              {t("downloadFilesDialog.title")}
            </h3>
          </div>

          {files.length > 0 && (
            <React.Fragment>
              <DownloadFiles
                disabled={disabled}
                files={stateFiles}
                downloadErrorsMap={downloadErrors}
                downloadProgressMap={downloadProgress}
                isDownloadingMap={isDownloading}
                onDownloadFile={handleDownload}
                onRequestPreview={openPreview}
                displayFileSizes={true}
              ></DownloadFiles>
              {additionalFiles.length > 0 && (
                <DownloadFiles
                  disabled={disabled}
                  files={additionalFiles}
                  downloadErrorsMap={downloadErrors}
                  downloadProgressMap={downloadProgress}
                  isDownloadingMap={isDownloading}
                  onDownloadFile={handleDownload}
                  onRequestPreview={openPreview}
                  onDeleteFile={handleDeleteFile}
                ></DownloadFiles>
              )}
            </React.Fragment>
          )}

          {!isUploading && (
            <DropFilesComponent
              disabled={disabled}
              files={filesToUpload}
              onFilesChange={(files) => {
                // ignore las, tfw, and tif files
                const filtered = files.filter(
                  (file) =>
                    !file.name.toLowerCase().endsWith(".las") &&
                    !file.name.toLowerCase().endsWith(".tfw") &&
                    !file.name.toLowerCase().endsWith(".tif")
                );

                setFilesToUpload(filtered);
              }}
              dropAreaText={t("downloadFilesDialog.dropAreaText")}
            />
          )}

          {isUploading && (
            <LoadingBar progress={uploadProgress * 100}></LoadingBar>
          )}

          {uploadError && (
            <div className="text-red-500 text-center text-xs">
              {uploadError}
            </div>
          )}

          <div
            className={`flex items-center justify-center ${
              filesToUpload.length > 0 ? "gap-[64px]" : ""
            }`}
          >
            <div>
              <TextButton
                secondary={filesToUpload.length > 0}
                className={"h-[56px]"}
                label={t("downloadFilesDialog.ok")}
                onClick={handleCancel}
              />
            </div>
            <div>
              {filesToUpload.length > 0 && (
                <TextButton
                  disabled={disabled}
                  className={"h-[56px]"}
                  label={t("downloadFilesDialog.upload")}
                  onClick={handleUpload}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <PreviewFileDialog
        isLoading={isPreviewOpen && !previewFile}
        file={previewFile}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
      />
       <MessageDialog
        isOpen={cancelDialogOpen}
        title={t("downloadFilesDialog.cancelUploadDialog.title")}
        submitText={t("downloadFilesDialog.cancelUploadDialog.ok")}
        cancelText={t("downloadFilesDialog.cancelUploadDialog.cancel")}
        onSubmit={handleAbortUpload}
        onCancel={handleCancelAbort}
      ></MessageDialog>
    </DialogWrapper>
  ) : null;
};

export default DownloadFilesDialog;

const takeOnlyStatesFiles = (files) => {
  // return only tif, las, and tfw files, take only first three
  return files
    .filter(
      (file) =>
        file.extension.toLowerCase().endsWith("las") ||
        file.extension.toLowerCase().endsWith("tfw") ||
        file.extension.toLowerCase().endsWith("tif")
    )
    .splice(0, 3);
};

const takeOnlyAdditionalFiles = (files) => {
  // return not tif, las, and tfw files
  return files
    .filter(
      (file) =>
        !file.extension.toLowerCase().endsWith("las") &&
        !file.extension.toLowerCase().endsWith("tfw") &&
        !file.extension.toLowerCase().endsWith("tif")
    )
    .filter((file) => file.calculationId === null);
};

const hasPreview = (file) => {
  return [
    ".jpg",
    ".jpeg",
    ".png",
    ".pdf",
    ".dxf",
    ".dwg",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".tiff",
    ".tif",
  ].includes(file.extension.toLowerCase());
};

const DownloadFiles = ({
  files,
  onDownloadFile,
  onRequestPreview,
  onDeleteFile,
  isDownloadingMap,
  downloadProgressMap,
  downloadErrorsMap,
  disabled,
  displayFileSizes,
}) => {
  const handleDownload = (file) => (e) => {
    e.preventDefault();
    onDownloadFile(file);
  };

  const handleOpenPreview = (file) => (e) => {
    e.preventDefault();
    onRequestPreview(file);
  };

  const { t } = useTranslation(namespaces.pages);

  const downloadErrors = downloadErrorsMap || {};
  const downloadProgress = downloadProgressMap || {};
  const isDownloading = isDownloadingMap || {};

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  return (
    <GrayWrapper className={"justify-left items-left w-full "}>
      <div className="flex flex-col gap-y-[10px] px-[25px] max-h-[300px] overflow-y-auto">
        {files.map((file) => (
          <div key={file.id} className="flex flex-row justify-between">
            <span
              key={file.id}
              className="font-bold text-black-700 text-sm py-[4px] overflow-hidden text-ellipsis max-w-[200px]"
            >
              {file.name}

              <span className="font-normal text-red-500 text-xs px-[2px]">
                {downloadErrors[file.id] ? downloadErrors[file.id] : ""}
              </span>
            </span>
            <div className="flex items-center justify-center gap-[10px]">
              {displayFileSizes && (
                <span className="text-sm text-500 text-[#B3B3B3]">
                  {convertBytesToFileSizeString(file.size)}
                </span>
              )}
              {onRequestPreview && hasPreview(file) && (
                <CircleButton
                  icon="/images/preview.svg"
                  primary
                  // disabled={disabled}
                  iconSize="12px"
                  onClick={/*disabled ? () => {} : */ handleOpenPreview(file)}
                ></CircleButton>
              )}
              <a
                // href={`${DOWNLOAD_BASE_URL}${file.path}`}
                // download={file.originalName}
                className="w-[35px] flex items-center justify-center"
                onClick={/*disabled ? () => {} :*/ handleDownload(file)}
              >
                {isDownloading[file.id] ? (
                  <span className="text-black hove:text-black text-sm font-bold">
                    {(downloadProgress[file.id] || 0) + "%"}
                  </span>
                ) : (
                  <CircleButton
                    // disabled={disabled}
                    icon="/images/download.svg"
                    primary
                    iconSize="12px"
                    onClick={(e) => {}}
                  ></CircleButton>
                )}
              </a>
              {onDeleteFile && (
                <CircleButton
                  icon="/images/sidebar_delete.svg"
                  secondary
                  iconSize="12px"
                  onClick={
                    disabled
                      ? () => {}
                      : () => {
                          setFileToDelete(file);
                          setIsDeleteDialogOpen(true);
                        }
                  }
                ></CircleButton>
              )}
            </div>
          </div>
        ))}
      </div>
      <MessageDialog
        isLoading={isDeleting}
        isOpen={isDeleteDialogOpen}
        title={t("downloadFilesDeleteDialog.title")}
        submitText={t("downloadFilesDeleteDialog.delete")}
        cancelText={t("downloadFilesDeleteDialog.cancel")}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
          setFileToDelete(null);
        }}
        onSubmit={async () => {
          setIsDeleteDialogOpen(false);
          if (!fileToDelete) return;
          if (!onDeleteFile) return;
          setIsDeleting(true);
          try {
            await onDeleteFile(fileToDelete);
          } catch (e) {
            console.error(e);
          } finally {
            setIsDeleting(false);
          }

          setFileToDelete(null);
        }}
      ></MessageDialog>
    </GrayWrapper>
  );
};
