import Measure from "./measure";
import Point from "ol/geom/Point";
// class that hold line coordinates and calculate length
class Point2D extends Measure {
  static Type = "point";
  constructor(coordinate, name) {
    super(name, "point");
    this.point = new Point(coordinate);
  }

  getGeometry() {
    return this.point;
  }

  get coordinates() {
    return this.point.getCoordinates();
  }

  set coordinates(lineCoordinates) {
    this.line.setCoordinates(lineCoordinates);
  }

  getLength() {
    return 0;
  }

  getResult() {
    return 0;
  }

  static fromSerialized(serialized) {
    let measure = new Point2D(serialized.points || [], serialized.name);
    measure.id = serialized.id;
    measure.toBeStored = serialized.toBeStored;
    measure.dbId = serialized.dbId;
    return measure;
  }
}

export default Point2D;
