import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../buttons/IconButton";
import { namespaces } from "../../../consts/i18n";
import AnimateSpin from "../../buttons/AnimateSpin";
import TextButton from "../../buttons/TextButton";

const StoreButton = ({ onClick, disabled, stored }) => {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <IconButton
      title={stored ? t("sidebar.measure.unsave") : t("sidebar.measure.save")}
      disabled={disabled}
      icon={stored ? "/images/sidebar_saved.svg" : "/images/sidebar_save.svg"}
      onClick={onClick}
    ></IconButton>
  );
};

const SaveChangesButton = ({ onClick }) => {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <button
      className="rounded-full border-primary text-sm border-2 hover:bg-primary hover:text-white font-bold border-solid text-primary px-[10px]"
      onClick={onClick}
    >
      {t("sidebar.measure.saveChanges")}
    </button>
  );
};

const ButtonsDelimiter = () => {
  return <div className="h-[24px] w-[2px] bg-secondary"></div>;
};

const EditButton = ({ onClick, disabled }) => {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <IconButton
      size={24}
      title={t("sidebar.measure.rename")}
      icon={"/images/sidebar_edit.svg"}
      disabled={disabled}
      onClick={onClick}
    ></IconButton>
  );
};

const DetailButton = ({ onClick, disabled }) => {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <IconButton
      title={t("sidebar.record.detail")}
      icon={"/images/sidebar_record_detail.svg"}
      disabled={disabled}
      size={22}
      onClick={onClick}
    ></IconButton>
  );
};

const ExportButton = ({ onClick, disabled, isLoading }) => {
  const { t } = useTranslation(namespaces.viewer);

  const [_isLoading, setIsLoading] = React.useState(disabled);

  const handleClick = () => {
    onClick();

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return isLoading || _isLoading ? (
    <div className="w-[24px] h-[24px] bg-primary rounded-full mt-[2px]">
      <AnimateSpin size={60}></AnimateSpin>
    </div>
  ) : (
    <IconButton
      title={t("sidebar.record.export")}
      icon={"/images/sidebar_record_export.svg"}
      disabled={disabled || _isLoading}
      size={22}
      onClick={handleClick}
    ></IconButton>
  );
};

const VisibilityButton = ({ onClick, disabled, visible }) => {
  const { t } = useTranslation(namespaces.viewer);

  const hidden = !visible;

  return (
    <IconButton
      size={24}
      title={hidden ? t("sidebar.measure.show") : t("sidebar.measure.hide")}
      icon={
        hidden ? "/images/sidebar_eye_closed.svg" : "/images/sidebar_eye.svg"
      }
      onClick={onClick}
    ></IconButton>
  );
};

const DeleteButton = ({ onClick, disabled }) => {
  const { t } = useTranslation(namespaces.viewer);

  return (
    <IconButton
      title={t("sidebar.measure.delete")}
      icon={"/images/sidebar_delete.svg"}
      disabled={disabled}
      onClick={onClick}
      size={20}
    ></IconButton>
  );
};

export {
  StoreButton,
  ButtonsDelimiter,
  EditButton,
  VisibilityButton,
  DeleteButton,
  ExportButton,
  DetailButton,
  SaveChangesButton,
};
