import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { DropFilesComponent } from "../dialogs/UploadMeasureDialog";
import InputLabel from "./InputLabel";
const FilesInput = ({
  onChange,
  files,
  lightTheme,
  dropAreaLabel,
  label,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation(namespaces.common);

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <DropFilesComponent
        disabled={disabled}
        lightTheme={lightTheme}
        files={files}
        onFilesChange={(_files) => {
          onChange(_files);
        }}
        dropAreaText={dropAreaLabel}
      />
    </div>
  );
};

export default FilesInput;
