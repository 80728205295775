import React from "react";
import { ViewerSmallButton } from "./Timeline";
import { useSelector } from "react-redux";
import { DimensionType } from "../../api/dimensionType";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import usePotreeViewer from "../../hooks/usePotreeViewer";

const CameraViewControl = () => {
  const measureMode = useSelector((state) => state.measures.mode);
  const { t } = useTranslation(namespaces.viewer);

  const { setCameraView } = usePotreeViewer();

  const handleClickChangeView = (view) => (e) => {
    setCameraView(view);
  };

  const [controlPanelOpen, setControlPanelOpen] = React.useState(false);

  const handleOpenControlPanel = () => {
    setControlPanelOpen(true);
  };

  const handleCloseControlPanel = () => {
    setControlPanelOpen(false);
  };

  if (measureMode === DimensionType.DIMENSION_2D) return null;

  return (
    <React.Fragment>
      <div
        className={`flex flex-row items-center justify-center transition transition-all h-[36px] w-${
          controlPanelOpen ? 48 : 96
        } bg-[#ffffff] ease-in-out duration-300 rounded-full  shadow-sm shadow-[#ddd]`}
      >
        {!controlPanelOpen ? (
          <button
            title={t("viewControl.helpOpen")}
            onClick={handleOpenControlPanel}
            style={{
              "--image-url": `url(/images/viewer_cameraview_control.svg)`,
            }}
            className={
              "rounded-full w-[35px] h-[35px] hover:bg-primaryLight bg-[image:var(--image-url)] bg-no-repeat bg-center bg-contain bg-[length:18px_18px]"
            }
          ></button>
        ) : (
          <div className="flex flex-row">
            <button
              title={t("viewControl.helpClose")}
              onClick={handleCloseControlPanel}
              style={{
                "--image-url": `url(/images/viewer_cameraview_control_close.svg)`,
              }}
              className={
                "w-[18px] h-[18px] bg-[image:var(--image-url)] bg-no-repeat bg-center bg-contain ml-[10px]"
              }
            ></button>
            <div className="flex flex-row gap-[18px] justify-center items-center mx-[18px]">
              {buttons.map((button) => (
                <button
                  title={t(button.title)}
                  style={{
                    "--image-url": `url(${button.icon})`,
                    "--image2-url": `url(${button.icon.replace(
                      ".svg",
                      "_h.svg"
                    )})`,
                  }}
                  className={
                    "w-[18px] h-[18px] bg-[image:var(--image-url)] hover:bg-[image:var(--image2-url)] bg-no-repeat bg-center bg-contain"
                  }
                  // style={{ background: "url('" + button.icon + "') no-repeat center center / contain" }}
                  onClick={handleClickChangeView(button.view)}
                >
                  {/* <img src={button.icon} className="w-[29px] h-[13px] object-contain" alt="view control" /> */}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

// front, back, left, right, top, bottom

const buttons = [
  {
    title: "cameraView.front",
    icon: "/images/viewer_frontview.svg",
    view: "F",
  },
  {
    title: "cameraView.back",
    icon: "/images/viewer_backview.svg",
    view: "B",
  },
  {
    title: "cameraView.left",
    icon: "/images/viewer_leftview.svg",
    view: "L",
  },
  {
    title: "cameraView.right",
    icon: "/images/viewer_rightview.svg",
    view: "R",
  },
  {
    title: "cameraView.top",
    icon: "/images/viewer_topview.svg",
    view: "U",
  },
  {
    title: "cameraView.bottom",
    icon: "/images/viewer_bottomview.svg",
    view: "D",
  },
];

export default CameraViewControl;
