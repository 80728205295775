import React from "react";

const CloseButton = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`absolute top-0 right-0 p-4 ${className ? className : ""}`}
    >
      <img src="/images/close.svg" alt="close" />
    </button>
  );
};

export default CloseButton;
