import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import OpenMeasureDialog from "../components/dialogs/OpenMeasureDialog";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import { namespaces } from "../consts/i18n";
import {
  useLazyGetMyselfQuery,
  useCreateLinkSessionQuery,
} from "../api/loginApi";
import { useLazyGetProjectStateQuery } from "../api/managementApi";
function LinkRedirectPage() {
  const { t } = useTranslation(namespaces.common);
  const { clientId, projectId, stateId, token } = useParams();
  const navigate = useNavigate();
  const { setLinkToken, linkToken } = useAuth();

  const {
    data: linkSession,
    error: linkSessionError,
    isLoading: linkSessionLoading,
  } = useCreateLinkSessionQuery(token);

  const [openMeasureDialogOpen, setOpenMeasureDialogOpen] =
    React.useState(false);
  const [selectedMeasure, setSelectedMeasure] = React.useState(null);

  const [resultLink, setResultLink] = React.useState(null);
  const [getMyself, { data: myself, error: myselfError }] =
    useLazyGetMyselfQuery();

  const [getProjectState, { data: projectState }] =
    useLazyGetProjectStateQuery();

  useEffect(() => {
    if (!linkToken) return;

    if (resultLink || selectedMeasure) {
      getMyself().unwrap().then((myself) => {
        // console.log("myself", myself);
      }).catch((err) => {
        console.log("err", err);
        // navigate("/link-error", { replace: true });
      });
    }
  }, [resultLink, linkToken, selectedMeasure, token]);

  useEffect(() => {
    // console.log("myself", myself);

    if (!myself) return;

    if (resultLink) {
      navigate(resultLink);
      return;
    }

    if (selectedMeasure) {
      let timeout = setTimeout(() => {
        setOpenMeasureDialogOpen(true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [myself]);

  useEffect(() => {
    if (linkSessionError) {
      navigate("/link-error", { replace: true });
    }
  }, [linkSessionError]);

  useEffect(() => {
    if (clientId && projectId && stateId && linkSession?.token) {
      // link to a specific state
      // navigate(`/clients/${clientId}/projects/${projectId}/map/${stateId}`);
      setLinkToken({
        token: linkSession.token,
        level: "state",
        accessData: { clientId, projectId, stateId },
      });

      getProjectState({
        clientId,
        projectId,
        id: stateId,
      })
        .unwrap()
        .then((projectState) => {
          // if (projectId === DEMO_PROJECT_ID) {
          //   // demo opens 3d
          //   setResultLink(
          //     `/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
          //   );
          // } else {
          // console.log("projectState", projectState);
          setSelectedMeasure({
            ...projectState,
          });
          // }
        })
        .catch((err) => {
          // console.log("err", err);
          navigate("/link-error", { replace: true });
        });
      // navigate(
      //   `/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
      // );
      // } else {
      //   setOpenMeasureDialogOpen(true); // choose between 2d and 3d
      // }
    } else if (clientId && projectId && linkSession?.token) {
      // link to a project
      // navigate(`/clients/${clientId}/projects/${projectId}`);
      setLinkToken({
        token: linkSession.token,
        level: "project",
        accessData: { clientId, projectId },
      });
      setResultLink(`/clients/${clientId}/projects/${projectId}`);
    } else if (clientId && linkSession?.token) {
      // link to a client
      // navigate(`/clients/${clientId}`);
      setLinkToken({
        token: linkSession.token,
        level: "client",
        accessData: { clientId },
      });

      setResultLink(`/clients/${clientId}`);
    }
  }, [linkSession]);

  // useEffect(() => {
  //   if (myselfError) {
  //     navigate("/link-error", { replace: true });
  //   }
  // }, [myselfError]);

  return (
    <Page className={"h-screen flex justify-center"}>
      <OpenMeasureDialog
        disableCloseFromUI={true}
        isOpen={openMeasureDialogOpen}
        clientId={clientId}
        projectId={projectId}
        measure={selectedMeasure}
        onClose={() => {
          setSelectedMeasure(null);
          setOpenMeasureDialogOpen(false);
        }}
      ></OpenMeasureDialog>
    </Page>
  );
}

export default LinkRedirectPage;
