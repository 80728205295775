import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../components/Logo";
import { namespaces } from "../consts/i18n";

const MobileNotSupportedPage = () => {
  const { t } = useTranslation(namespaces.pages);

  return (
    <div className="flex flex-col items-center">
      <div className="mt-[83px] w-[215px] text-base font-bold text-700-black">
        <p className="text-center">{t("mobileNotSupportedPage.description")}</p>
        <p className="text-center mt-[1em]">
          {t("mobileNotSupportedPage.description2")}
        </p>
      </div>
      <div className="mt-[60px]">
        <Logo></Logo>
      </div>
    </div>
  );
};

export default MobileNotSupportedPage;
