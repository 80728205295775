import React, { useEffect } from "react";
import TextInput, { TextInputSmall, TextInputPrint } from "../inputs/TextInput";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import CheckboxInput from "../inputs/CheckboxInput";
import DateSeparatedInput from "../inputs/DateSeparatedInput";
import { dateToYYYYMMDD } from "../../utils/date";
function FVEFormPrint({ data }) {
  const { t } = useTranslation(namespaces.viewer);

  const disabled = true;

  return (
    <div className="flex flex-col gap-[20px] rounded-xl py-[5px]">
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <TextInputPrint
            disabled={disabled}
            label={t("form.panelType")}
            value={data.panelType}
            onChange={() => {}}
          ></TextInputPrint>
        </div>
        <div className="w-1/2">
          <TextInputPrint
            disabled={disabled}
            label={t("form.anchorType")}
            value={data.anchorType}
            onChange={() => {}}
          ></TextInputPrint>
        </div>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <CheckboxInput
            disabled={disabled}
            label={t("form.hotspot")}
            checked={data.hotspot}
            onChange={() => {}}
          ></CheckboxInput>
        </div>
        <div className="w-1/2">
          <CheckboxInput
            disabled={disabled}
            checked={data.shading}
            label={t("form.shading")}
            onChange={() => {}}
          ></CheckboxInput>
        </div>
      </div>
      <div>
        <TextInputPrint
          disabled={disabled}
          label={t("form.others")}
          value={data.note}
          onChange={() => {}}
        ></TextInputPrint>
      </div>
      <div className="flex flex-row gap-[24px]">
        <div className="w-1/2">
          <DateSeparatedInput
            disabled={disabled}
            variant={"small"}
            value={dateToYYYYMMDD(data.dateOfRevision)}
            label={t("form.dateOfRevision")}
            onChange={() => {}}
          ></DateSeparatedInput>
        </div>
        <div className="w-1/2">
          <DateSeparatedInput
            disabled={disabled}
            variant={"small"}
            value={dateToYYYYMMDD(data.dateOfFix)}
            label={t("form.dateOfFix")}
            onChange={() => {}}
          ></DateSeparatedInput>
        </div>
      </div>
    </div>
  );
}

export default FVEFormPrint;
