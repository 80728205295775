import React, { useMemo } from "react";
import Card from "./Card";
import CircleButton from "../buttons/CircleButton";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { toReadableDate } from "../../utils/date";
import {
  convertBytesToFileSizeString,
  expiresIn,
} from "../../utils/stringUtils";
import { getProjectTypeFromRecordTypes } from "../../api/consts";
function ProjectCard(props) {
  const { project, onEdit, onDelete, onClick, onShareClick,  onOpenRecords, ...otherProps } =
    props;

  const { t } = useTranslation(namespaces.pages);
  const { t: t2 } = useTranslation(namespaces.common);

  const handleClick = (e) => {
    e.preventDefault();
    // navigate(`projects/${project.id}`);
    onClick(project);
  };

  const handleCreateLink = (e) => {
    e.stopPropagation();
    onShareClick(project);
  };

  const handleEditName = (e) => {
    e.stopPropagation();
    onEdit(project);
  };

  const handleDeleteProject = (e) => {
    e.stopPropagation();
    onDelete(project);
  };

  const handleOpenRecords = (e) => {
    e.stopPropagation();
    onOpenRecords(project);
  };

  const tags = [
    `${t("clientPage.project")} • ${
      project.extra?.size
        ? convertBytesToFileSizeString(project.extra?.size)
        : "0 MB"
    }`,
  ];

  if (project.expiration) {
    tags.push(expiresIn(project.expiration, t2));
  }

  const subtitle = useMemo(() => {
    const projectType = getProjectTypeFromRecordTypes(
      project.allowedRecordTypes
    );

    if (projectType) {
      return `${t(`clientPage.projectType.${projectType}`)} • vzniklo ${toReadableDate(
        project.createdAt
      )}`;
    }

    return `vytvořeno ${toReadableDate(project.createdAt)}`;
  });

  return (
    <Card
      title={project.name}
      pretitle={t("clientPage.project")}
      tags={tags}
      subtitle={subtitle}
      onClick={handleClick}
      actions={[
        onOpenRecords && project.records?.length > 0 && (
          <CircleButton
            icon="/images/records.svg"
            onClick={handleOpenRecords}
          ></CircleButton>
        ),
        onShareClick && (
          <CircleButton
            icon="/images/link.svg"
            onClick={handleCreateLink}
          ></CircleButton>
        ),
        onEdit && (
          <CircleButton
            icon="/images/edit.svg"
            onClick={handleEditName}
          ></CircleButton>
        ),
        onDelete && (
          <CircleButton
            icon="/images/trash.svg"
            onClick={handleDeleteProject}
          ></CircleButton>
        ),

      ]}
      {...otherProps}
    ></Card>
  );
}

export default ProjectCard;
