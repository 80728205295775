import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TiffViewer from "../components/viewer/TiffViewer";
import MapProvider from "../providers/mapProvider";
import Sidebar from "../components/viewer/sidebar/Sidebar";
import { DimensionType } from "../api/dimensionType";
import {
  addMeasure,
  changeMeasure,
  setMeasureMode,
} from "../redux/measures/measuresReducer";
import TopBar from "../components/viewer/TopBar";
import useGetProjectStateAdditionals from "../hooks/useGetProjectStateAdditionals";
import useSetViewerTitle from "../hooks/useSetViewerTitle";
import uploadApi from "../api/uploadApi";
import Toast from "../components/Toast";
import LoadingOverlay from "../components/LoadingOverlay";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";
import { getUniqueName } from "../redux/measures/measuresReducer";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import useViewerDisplayMode from "../hooks/useViewerDisplayMode";
import useGetProjectRecords from "../hooks/useGetRecords";
import { DisplayType } from "../redux/viewer/viewerReducer";
import { addChangeForRecord, addNonStoredRecord, clearNonStoredRecords } from "../redux/records/recordsReducer";
import { RecordType } from "../api/consts";
import useGetMeasures from "../hooks/useGetMeasures";
import {
  addNonStoredMeasure,
  clearMeasures,
  updateNonStoredMeasure,
} from "../redux/measures2/measures2Reducer";

import { debounce } from "lodash";
const MapViewer = () => {
  const { projectId, clientId, stateId } = useParams(); // default 5f9f1b9b-1b1a-4b1a-9b9f-1b1a4b1a9b9f
  const { t } = useTranslation(namespaces.viewer);
  useLinkAccessCheck();

  const [displayMode] = useViewerDisplayMode();

  const measureMode = useSelector((state) => state.measures.mode);

  const { records } = useGetProjectRecords({
    projectId,
    dimension: measureMode,
  });

  const { measures } = useGetMeasures({
    projectId,
    dimension: measureMode,
  });

  const dispatch = useDispatch();

  const [mapUrl, setMapUrl] = React.useState("");
  const [fetchMapUrlError, setFetchMapUrlError] = React.useState(false);
  // const measurementsData = useSelector((state) => state.measures.data);

  // list measures using uselistMeasuresQuery
  const {
    initialMeasures,
    isLoadingMeasures,
    isFetchingMeasures,
    refetchMeasures,
    projectState,
  } = useGetProjectStateAdditionals({
    projectId,
    clientId,
    stateId,
    dimension: DimensionType.D2,
  });

  useEffect(() => {
    if (!projectState) return;

    const mapUrl = uploadApi.getCOGConversionUrl(projectState);
    if (!mapUrl) {
      setFetchMapUrlError(new Error("No map url"));
      return;
    }

    console.log("opening: ", mapUrl);

    setMapUrl(mapUrl);
  }, [projectState, setMapUrl]);

  // console.log("projectState", projectState, "stateId", stateId);

  useSetViewerTitle({
    dimensionType: DimensionType.D2,
  });

  const [toolMode, setToolMode] = React.useState("none");
  const [nextFeatureName, setNextFeatureName] = React.useState("");

  useEffect(() => {
    dispatch(setMeasureMode(DimensionType.D2));

    return () => {
      dispatch(clearMeasures());
      dispatch(clearNonStoredRecords())
    };
  }, [dispatch]);

  useEffect(() => {
    refetchMeasures();
  }, [refetchMeasures, stateId]);

  const handleCreateMeasure = (name, type) => {
    setToolMode(type);
    setNextFeatureName(name);
  };

  const handleAddFeature = useCallback(
    (feature) => {
      const name = feature.get("name") || nextFeatureName;
      // console.log(feature);
      if (feature.get("isRecord") === true) {
        const serialized = {
          name: name,
          points: feature.getGeometry().getCoordinates(),
          measure_type: feature.get("measureType"), //toolMode,
          type: RecordType.GENERIC,
          id: feature.getId(),
          // dbId: feature.get("dbId"),
          toBeStored: feature.get("toBeStored"),
        };
        dispatch(addNonStoredRecord(serialized));
      } else {
        const serialized = {
          name: name,
          points: feature.getGeometry().getCoordinates(),
          type: feature.get("measureType"), //toolMode,
          id: feature.getId(),
          dbId: feature.get("dbId"),
          toBeStored: feature.get("toBeStored"),
        };
        dispatch(addNonStoredMeasure(serialized));
      }

      setToolMode("none");
    },
    [nextFeatureName, dispatch, displayMode]
  );

  const handleChangeFeature = useCallback(
    (feature) => {
      // console.log("handleChangeFeature", feature);

      if (feature.get("isRecord") === true) {
        dispatch(addChangeForRecord({
          id: feature.getId(),
          points: feature.getGeometry().getCoordinates(),
        }));
      } else {
        dispatch(
          updateNonStoredMeasure({
            id: feature.getId(),
            points: feature.getGeometry().getCoordinates(),
          })
        );
      }
    },
    [dispatch]
  );

  const debouncedHandleChangeFeature = React.useRef(
    debounce(handleChangeFeature, 500)
  ).current;

  const loadingVisible = isLoadingMeasures || isFetchingMeasures || !mapUrl;

  return (
    <React.Fragment>
      {fetchMapUrlError && (
        <Toast
          message={fetchMapUrlError.message}
          type="error"
          onAfterHide={() => {
            setFetchMapUrlError(false);
          }}
        ></Toast>
      )}
      <MapProvider>
        <div className="flex flex-row h-full">
          <div className="grow bg-white relative">
            {/* <div className={`absolute w-full h-full z-10 flex justify-center items-center bg-[#a1a1a1] transition duration-300 ease-in-out ${loadingVisible ? 'opacity-50' : 'opacity-0 pointer-events-none'}
              `}><AnimateSpin size={100} /></div> */}
            <LoadingOverlay visible={loadingVisible}>
              <TiffViewer
                records={records}
                measures={measures}
                initialMeasures={initialMeasures}
                nextFeatureName={nextFeatureName}
                onAddFeature={handleAddFeature}
                onAbortFeature={() => {
                  setToolMode("none");
                }}
                onChangeFeature={debouncedHandleChangeFeature}
                toolMode={toolMode}
                url={mapUrl}
              ></TiffViewer>
            </LoadingOverlay>
            <TopBar
              onCreateMeasure={({ type }) => {
                const nameFromType = {
                  [toolModeType.POINT]: t("defaultMeasureName.point"),
                  [toolModeType.DISTANCE]: t("defaultMeasureName.distance"),
                  [toolModeType.AREA]: t("defaultMeasureName.area"),
                }[type];
                const name = getUniqueName(nameFromType, measures);
                handleCreateMeasure(name, type);
              }}
            />
          </div>
          <div className="flex-none w-[460px]">
            <Sidebar
              dimensionType={DimensionType.D2}
              onCreateMeasure={handleCreateMeasure}
            ></Sidebar>
          </div>
        </div>
      </MapProvider>
    </React.Fragment>
  );
};

export default MapViewer;
