import { createSlice } from "@reduxjs/toolkit";
import { loginApi } from "../../api/loginApi";

export const initialState = {
  me: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      loginApi.endpoints.getMyself.matchFulfilled,
      (state, { payload }) => {
        state.me = payload.data;
      }
    );
    builder.addMatcher(
      loginApi.endpoints.getMyself.matchRejected,
      (state, { payload }) => {
        // state.me = null;
      }
    );
  },
});

export default authSlice.reducer;
