import React from "react";
import DialogWrapper from "./DialogWrapper";
import CloseButton from "../buttons/CloseButton";
import RecordList from "../RecordList";

function RecordListDialog({onClose, projectId}) {

  return (
    <DialogWrapper onClick={() => onClose()}>
      <div
        className="relative bg-white rounded-xl w-[800px] max-h-[90%] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton
          onClick={() => onClose()}
        ></CloseButton>
        <RecordList projectId={projectId}></RecordList>
      </div>
    </DialogWrapper>
  );
}

export default RecordListDialog;
