import React from "react";

function TabItem({ label, onClick, active }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <button
      className={
        (active ? " bg-primary text-white" : " bg-white text-black") +
        " rounded-t-lg h-[32px] w-[106px] p-1 px-4 text-base font-bold hover:shadow-lg relative"
      }
      onClick={handleClick}
    >
      {label}
      {active && (
        <span className="absolute left-[38px] bottom-[-11px]">
          <img
            src="/images/sidebar_tab_active.svg"
            alt="arrow-down"
            className="inline-block ml-2"
          />
        </span>
      )}
    </button>
  );
}

export default TabItem;
