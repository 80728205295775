import React from "react";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogWrapper from "./DialogWrapper";
const MessageDialog = ({
  isOpen,
  title,
  submitText,
  cancelText,
  onCancel,
  onSubmit,
  isSubmitLoading,
  disabled,
}) => {
  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[518px] px-[65px] pt-[65px] pb-[64px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-y-[39px]">
          <div>
            <h3 className="text-xxxl font-bold text-center overflow-hidden text-ellipsis">
              {title}
            </h3>
          </div>

          <div className="flex flex-row justify-between gap-[64px]">
            <TextButton
              className={"h-[56px]"}
              label={cancelText}
              secondary
              onClick={handleCancel}
            />
            <TextButton
              className={"h-[56px]"}
              label={submitText}
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
              disabled={isSubmitLoading || disabled}
            />
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default MessageDialog;
