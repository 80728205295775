import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogTitle from "./DialogTitle";
import DialogWrapper from "./DialogWrapper";
import TextInput from "../inputs/TextInput";
const ForgotPasswordDialog = ({ isOpen, onClose }) => {
  const handleSubmit = () => {
    console.log(email);
  };

  const handleCancel = () => {
    onClose();
  };

  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);

  const { t } = useTranslation(namespaces.pages);

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-[30px]">
          <DialogTitle
            title={t("loginPage.forgotPasswordDialog.title")}
          ></DialogTitle>

          <TextInput
            onChange={setEmail}
            label={t("loginPage.forgotPasswordDialog.email")}
            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
            onValidityChange={(valid) => {
              setEmailValid(valid);
            }
            }
          />

          <div className="flex flex-row justify-between gap-[64px] mt-[20px]">
            <TextButton
              disabled={!email || !emailValid}
              className={"h-[56px]"}
              label={t("loginPage.forgotPasswordDialog.send")}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default ForgotPasswordDialog;
