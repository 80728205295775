import uuid from "../../utils/uuid";
import Measure from "./measure";
import Measure3DHelper from "./measure3DHelper";

class LineDistanceMeasure3D extends Measure {
  static Type = "distance";
  constructor(lineCoordinates, name, viewer) {
    super(name, LineDistanceMeasure3D.Type);
    this.potreeMeasure = new window.Potree.ExtendedMeasure(viewer); // todo: use import
    this.potreeMeasure.showDistances = false;
    this.potreeMeasure.showTotalLength = true;
    this.potreeMeasure.showArea = false;
    this.potreeMeasure.closed = false;
    this.potreeMeasure.name = name;
    this.potreeMeasure.measureType = "distance";
    this.id = this.potreeMeasure.dbId || uuid();
    Measure3DHelper.initMarkers(this.potreeMeasure, lineCoordinates);
  }

  getLength() {
    return this.potreeMeasure.getLength();
  }

  getResult() {
    return this.getLength();
  }

  serialize() {
    return {
      ...super.serialize(),
      ...Measure3DHelper.serialize(this.potreeMeasure),
    };
  }

  static fromPotreeMeasure(potreeMeasure) {
    let measure = new LineDistanceMeasure3D([], potreeMeasure.name);
    measure.potreeMeasure = potreeMeasure;
    measure.id = potreeMeasure.dbId;
    measure.dbId = potreeMeasure.dbId;
    measure.toBeStored = potreeMeasure.toBeStored;
    return measure;
  }

  static fromSerialized(serialized) {
    let measure = new LineDistanceMeasure3D(serialized.points, serialized.name);
    measure.id = serialized.id;
    measure.dbId = serialized.dbId;
    measure.toBeStored = serialized.toBeStored;
    measure.potreeMeasure.toBeStored = serialized.toBeStored;
    measure.potreeMeasure.dbId = serialized.dbId;
    return measure;
  }
}

export default LineDistanceMeasure3D;
