import React from "react";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import { namespaces } from "../../consts/i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "./DialogWrapper";

const OpenMeasureDialog = ({
  isOpen,
  measure,
  onClose,
  disableCloseFromUI,
  clientId,
  projectId,
}) => {
  const navigate = useNavigate();

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleOpen2D = (e) => {
    e.preventDefault();
    onClose();
    // navigate(`/2d/` + measure.id);
    if (clientId && projectId) {
      // we can use full path
      navigate(`/clients/${clientId}/projects/${projectId}/map/${measure.id}`);
    } else {
      navigate("map/" + measure.id);
    }
  };

  const handleOpen3D = (e) => {
    e.preventDefault();
    onClose();
    if (clientId && projectId) {
      navigate(
        `/clients/${clientId}/projects/${projectId}/pointcloud/${measure.id}`
      );
    } else {
      navigate(`pointcloud/${measure.id}` /*+ measure.id*/);
    }
  };

  const { t } = useTranslation(namespaces.pages);

  return isOpen ? (
    <DialogWrapper onClick={disableCloseFromUI ? () => {} : handleClose}>
      <div
        className="relative bg-white rounded-xl w-[518px] px-[65px] pt-[65px] pb-[64px]"
        onClick={(e) => e.stopPropagation()}
      >
        {disableCloseFromUI ? null : (
          <CloseButton onClick={handleClose}></CloseButton>
        )}
        <div className="flex flex-col gap-y-[39px]">
          <div>
            <h3
              className="text-xxxl font-bold text-center
              whitespace-pre-wrap break-words
            "
            >
              {t("projectPage.openMeasureDialog.title")} {measure.name}
            </h3>
          </div>

          <div className="flex flex-row justify-center gap-[64px]">
            <div className="flex flex-col gap-[5px] items-center">
              <TextButton
                className={"h-[56px] w-[150px]"}
                label={t("projectPage.openMeasureDialog.open2D")}
                onClick={handleOpen2D}
              />
              <span className="text-sm text-700 font-bold mt-[11px]">
                {t("projectPage.openMeasureDialog.open2DHelp")}
              </span>
            </div>
            <div className="flex flex-col gap-[5px] items-center">
              <TextButton
                className={"h-[56px] w-[150px]"}
                label={t("projectPage.openMeasureDialog.open3D")}
                onClick={handleOpen3D}
              />
              <span className="text-sm text-700 font-bold mt-[11px]">
                {t("projectPage.openMeasureDialog.open3DHelp")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default OpenMeasureDialog;
