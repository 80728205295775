import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import NavBar from "../components/NavBar";
import PageContent from "../components/PageContent";
import Page from "../components/Page";
import ProjectCard from "../components/cards/ProjectCard";
import CardGrid from "../components/cards/CardGrid";
import IconTextButton from "../components/buttons/IconTextButton";
import InputDialog from "../components/dialogs/InputDialog";
import MessageDialog from "../components/dialogs/MessageDialog";
import ShareLinkDialog from "../components/dialogs/ShareLinkDialog";
import Toast from "../components/Toast";
import {
  useListProjectsQuery,
  useRenameProjectMutation,
  useCreateProjectMutation,
  useGetClientQuery,
  useDeleteProjectMutation,
} from "../api/managementApi";
import uuid from "../utils/uuid";
import { adminNavItem } from "./AdminPage";
import { filterFolderNameChars, folderInputProps } from "../utils/fsUtils";
import useAuth from "../hooks/useAuth";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import EditProjectDialog from "../components/dialogs/EditProjectDialog";
import RecordListDialog from "../components/dialogs/RecordListDialog";

const ClientPage = () => {
  useLinkAccessCheck();

  const { t } = useTranslation(namespaces.pages);
  const { clientId } = useParams();

  const { token, isDemo, isAdmin } = useAuth();

  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [editProjectDialogOpen, setEditProjectDialogOpen] =
    React.useState(false);

  const {
    data: projects,
    error: projectsError,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    refetch,
  } = useListProjectsQuery({ clientId });

  const { data: client, error: clientError } = useGetClientQuery({
    id: clientId,
  });

  const navItems = [
    token && adminNavItem,
    {
      name: client?.name,
      link: `/clients/${clientId}`,
    },
  ].filter(Boolean);

  const [
    renameProject,
    { error: renameError, isLoading: isRenameProjectLoading },
  ] = useRenameProjectMutation();

  const [
    createProject,
    { error: createError, isLoading: isCreateProjectLoading },
  ] = useCreateProjectMutation();

  const [
    deleteProject,
    { error: deleteError, isLoading: isDeleteProjectLoading },
  ] = useDeleteProjectMutation();

  const handleAddProject = () => {
    setCreateDialogOpen(true);
  };

  const [selectedProject, setSelectedProject] = React.useState(null);

  const [recordListDialogOpen, setRecordListDialogOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleCreateSubmit = async (name) => {
    await createProject({ name, clientId, id: uuid() }).unwrap();
    refetch();
    setCreateDialogOpen(false);
  };

  const handleDeleteClick = (project) => {
    setSelectedProject(project);
    setDeleteDialogOpen(true);
  };

  const handleShowRecordListClick = (project) => {
    setSelectedProject(project);
    setRecordListDialogOpen(true);
  };

  const handleRenameClick = useCallback(
    (project) => {
      setSelectedProject(project);
      // setRenameDialogOpen(true);
      if (isAdmin) setEditProjectDialogOpen(true);
      else setRenameDialogOpen(true);
    },
    [isAdmin]
  );

  const handleClick = (project) => {
    navigate(`projects/${project.id}`);
  };

  const handleShareClick = (project) => {
    setSelectedProject(project);
    setShareDialogOpen(true);
  };

  const handleRenameSubmit = async (name) => {
    await renameProject({
      clientId: clientId,
      id: selectedProject.id,
      name,
    }).unwrap();

    refetch();

    setRenameDialogOpen(false);
  };

  const handleDeleteSubmit = async () => {
    await deleteProject({
      clientId: clientId,
      id: selectedProject.id,
    }).unwrap();

    refetch();

    setDeleteDialogOpen(false);
  };

  const error =
    projectsError || renameError || createError || deleteError || clientError;

  return (
    <Page>
      <NavBar items={navItems}></NavBar>
      {/* {error && <Toast message={error.message} type="error"></Toast>} */}
      <PageContent
        title={client?.name}
        actions={[
          /*token &&  user can too create projects, but cant delete them*/ isAdmin && (
            <IconTextButton
              icon="/images/plus.svg"
              label={t("clientPage.addProject")}
              onClick={handleAddProject}
              disabled={isDemo}
            ></IconTextButton>
          ),
        ]}
      >
        <CardGrid isLoading={isProjectsLoading || isProjectsFetching}>
          {projects?.length > 0 &&
            projects.map((project) => (
              <React.Fragment key={project.id}>
                <ProjectCard
                  project={project}
                  onEdit={handleRenameClick}
                  onDelete={token && handleDeleteClick}
                  onClick={handleClick}
                  onShareClick={handleShareClick}
                  onOpenRecords={handleShowRecordListClick}
                />
              </React.Fragment>
            ))}
        </CardGrid>
      </PageContent>

      {recordListDialogOpen && (
        <RecordListDialog
          projectId={selectedProject?.id}
          onClose={() => setRecordListDialogOpen(false)}
        ></RecordListDialog>
      )}
      {/* <InputDialog
        isOpen={createDialogOpen}
        onCancel={() => {
          setCreateDialogOpen(false);
        }}
        label={t("clientPage.createProjectDialog.name")}
        title={t("clientPage.createProjectDialog.title")}
        submitText={t("clientPage.createProjectDialog.create")}
        onSubmit={handleCreateSubmit}
        inputProps={folderInputProps}
        enableClearInputValueAfterSubmit={true}
        disabled={isDemo}
      /> */}
      <InputDialog
        isOpen={renameDialogOpen}
        onCancel={() => {
          setRenameDialogOpen(false);
        }}
        initialValue={selectedProject?.name}
        label={t("clientPage.renameProjectDialog.name")}
        title={t("clientPage.renameProjectDialog.title")}
        submitText={t("clientPage.renameProjectDialog.rename")}
        onSubmit={handleRenameSubmit}
        inputProps={folderInputProps}
        disabled={isDemo}
      />
      <EditProjectDialog
        isOpen={editProjectDialogOpen}
        project={selectedProject}
        onCancel={() => {
          setEditProjectDialogOpen(false);
        }}
        onSubmit={() => {
          setEditProjectDialogOpen(false);
          refetch();
        }}
      ></EditProjectDialog>
      <EditProjectDialog
        clientId={clientId}
        isCreate={true}
        isOpen={createDialogOpen}
        onCancel={() => {
          setCreateDialogOpen(false);
        }}
        onSubmit={() => {
          setCreateDialogOpen(false);
          refetch();
        }}
      ></EditProjectDialog>
      <MessageDialog
        isOpen={deleteDialogOpen}
        title={t("clientPage.deleteProjectDialog.title", {
          folder: selectedProject?.name,
        })}
        onCancel={() => setDeleteDialogOpen(false)}
        onSubmit={handleDeleteSubmit}
        cancelText={t("clientPage.deleteProjectDialog.cancel")}
        submitText={t("clientPage.deleteProjectDialog.delete")}
        disabled={isDemo}
      />
      {selectedProject && shareDialogOpen && (
        <ShareLinkDialog
          disabled={isDemo}
          deactivateDisabled={isDemo}
          isOpen={shareDialogOpen}
          data={selectedProject}
          title={t("shareLinkDialog.title")}
          onClose={() => setShareDialogOpen(false)}
        ></ShareLinkDialog>
      )}
    </Page>
  );
};

export default ClientPage;

// const ProjectsMockData = [
//   {
//     id: 1,
//     name: "Konstrukční hala na lukách 1",
//   },
//   {
//     id: 2,
//     name: "Project 2",
//   },
//   {
//     id: 3,
//     name: "Project 3",
//   },
//   {
//     id: 4,
//     name: "Project 4",
//   },
// ];
