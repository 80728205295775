import React from "react";
import AnimateSpin from "../buttons/AnimateSpin";

function CardGrid({ children, isLoading }) {
  return (
    <div className="relative">
      <div
        className={`relative flex flex-wrap mt-[35px] gap-[22px] ${
          isLoading ? "blur-sm" : ""
        } `}
      >
        {children}
      </div>
      {isLoading && (
        <div className="absolute top-0 w-full h-full bg-transparent rounded-xl transition-all duration-300 flex items-center justify-center">
          <AnimateSpin size={100}></AnimateSpin>
        </div>
      )}
    </div>
  );
}

export default CardGrid;
