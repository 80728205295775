export const convertIframeToHtmlStringWithStyles = (iframe) => {
  // Access the iframe's document
  var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

  // Extract styles from each stylesheet
  var styles = '';
  for (var i = 0; i < iframeDocument.styleSheets.length; i++) {
      var rules;
      try {
          rules = iframeDocument.styleSheets[i].cssRules;
      } catch (error) {
          console.error('Error accessing stylesheets.', error);
          continue; // Skip this iteration because stylesheets[i] is not accessible due to cross-origin
      }
      for (var r = 0; r < rules.length; r++) {
          styles += rules[r].cssText;
      }
  }

  // Serialize the document to string
  var serializer = new XMLSerializer();
  var iframeHtmlString = serializer.serializeToString(iframeDocument);

  // Prepend styles to the HTML string
  var iframeHtmlStringWithStyles = '<style>' + styles + '</style>' + iframeHtmlString;

  return iframeHtmlStringWithStyles;
}