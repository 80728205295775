import React, { createContext, useReducer } from "react";
const PotreeContext = createContext();

const potreeReducer = (state, action) => {
  switch (action.type) {
    case "SET_POTREE_VIEWER":
      return {
        ...state,
        viewer: action.payload,
      };
    case "SET_POTREE":
      return {
        ...state,
        Potree: action.payload,
      };
    default:
      return state;
  }
};

const PotreeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(potreeReducer, {
    viewer: null,
    Potree: null,
  });

  return (
    <PotreeContext.Provider value={{ state, dispatch }}>
      {children}
    </PotreeContext.Provider>
  );
};

export { PotreeContext, PotreeProvider };
