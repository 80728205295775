const bindIds = ({clientId, projectId, id}) => {
  let res = {};
  if (id && !clientId && !projectId) {
    // client
    res = { clientId: id };
  } else if (id && clientId && !projectId) {
    // project
    res.clientId = clientId;
    res.projectId = id;
  } else if (id && clientId && projectId) {
    // measure
    res.clientId = clientId;
    res.projectId = projectId;
    res.measureId = id;
  }

  return res;
};

const bindIds2 = ({clientId, projectId, id}) => {
  let res = {};
  if (id && !clientId && !projectId) {
    // client
    res = { clientId: id };
  } else if (id && clientId && !projectId) {
    // project
    res.clientId = clientId;
    res.projectId = id;
  } else if (id && clientId && projectId) {
    // measure
    res.clientId = clientId;
    res.projectId = projectId;
    res.stateId = id;
  }

  return res;
};

export const getObjectFromIds = ({clientId, projectId, id}) => {
  const res = bindIds2({clientId, projectId, id});
  return res;
};

export const createLinkInputPathFromData = ({ clientId, projectId, id }) => {
  const res = bindIds({ clientId, projectId, id });

  const arr = ["clients", res.clientId, res.projectId, res.measureId ].filter(
    Boolean
  );

  return arr.join("/");
};

export const createResultLinkFromDataAndToken = ({clientId, projectId, id}, token) => {
  const res = bindIds({clientId, projectId, id});

  const resPath = [res.clientId, res.projectId, res.measureId].filter(Boolean).join("/");

  return `${window.location.origin}/link/${resPath}/${token}`;
};
