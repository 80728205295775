import React from "react";
import TabItem from "../TabItem";
import useViewerDisplayMode from "../../../hooks/useViewerDisplayMode";
import { DisplayType } from "../../../redux/viewer/viewerReducer";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../../consts/i18n";

function DisplayTypeTabs() {
  const [displayMode, setDisplayMode] = useViewerDisplayMode();

  const { t } = useTranslation(namespaces.viewer);

  return (
    <div className="flex flex-row items-center pt-[20px] gap-[21px] border-b-[2px] border-b-[#FBFBFB] pl-[21px] bg-[#FBFBFB]">
      <TabItem
        label={t("sidebar.tabs.measurements")}
        active={displayMode === DisplayType.MEASURES}
        onClick={() => setDisplayMode(DisplayType.MEASURES)}
      ></TabItem>
      <TabItem
        label={t("sidebar.tabs.records")}
        active={displayMode === DisplayType.RECORDS}
        onClick={() => setDisplayMode(DisplayType.RECORDS)}
      ></TabItem>
    </div>
  );
}

export default DisplayTypeTabs;
