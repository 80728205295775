import React from "react";

function FilterItemWithIcon({ label, onToggle, checked }) {
  const handleClick = () => {
    onToggle(!checked);
  };

  return (
    <button
      className={(checked ? " bg-primary text-white" : " bg-white text-black") + " rounded-full h-[28px] p-1 px-4 text-base font-bold hover:shadow-lg flex items-center justify-center gap-[4px]"}
      onClick={handleClick}
    >
      {label}
      <img src={ checked ? "/images/sidebar_eye_white.svg" : "/images/sidebar_eye.svg"} className="w-[24px] h-[24px] inline-block mr-[-5px] mb-[2px] " />
    </button>
  );
}

export default FilterItemWithIcon;