import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PreviewFileDialog from "../dialogs/PreviewFileDialog";
import uploadApi from "../../api/uploadApi";
import { namespaces } from "../../consts/i18n";
import { convertBytesToFileSizeString } from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import MessageDialog from "../dialogs/MessageDialog";
import { managementApi } from "../../api/managementApi";

const hasPreview = (file) => {
  return [
    ".jpg",
    ".jpeg",
    ".png",
    ".pdf",
    ".dxf",
    ".dwg",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".tiff",
    ".tif",
  ].includes(file.extension.toLowerCase());
};

const deleteFile = async (fileId, { onDeleted, onError }) => {
  try {
    await uploadApi.deleteFile(fileId);
    onDeleted();

  } catch (e) {
    console.error(e);
    if (onError) onError();
  }
};

const DownloadFiles = ({
  files,
  onFilesChange,
  disabled,
  displayFileSizes,
  lightTheme,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [previewFile, setPreviewFile] = React.useState(null);

  const [isDownloading, setIsDownloading] = React.useState({});
  const [downloadProgress, setDownloadProgress] = React.useState({});
  const [downloadErrors, setDownloadErrors] = React.useState({});

  const theme = lightTheme ? "bg-white" : "bg-bg1";
  const textColor = lightTheme ? "text-[#a1a1a1]" : "text-black-700";

  // const handleDownload = (file) => (e) => {
  //   e.preventDefault();
  //   onDownloadFile(file);
  // };

  const handleOpenPreview = (file) => (e) => {
    e.preventDefault();
    setPreviewFile(file);
    setIsPreviewOpen(true);
  };

  const handleDownload = (file) => async (e) => {
    try {
      setIsDownloading({ ...isDownloading, [file.id]: true });
      await uploadApi.downloadFile(file.id, {
        originalName: file.name,
        onDownloadProgress: (progressEvent) => {
          setDownloadProgress({
            ...downloadProgress,
            [file.id]: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          });
        },
      });
      setIsDownloading({ ...isDownloading, [file.id]: false });
    } catch (e) {
      setDownloadErrors({ ...downloadErrors, [file.id]: e.message });
      setIsDownloading({ ...isDownloading, [file.id]: false });
    }
  };

  const { t } = useTranslation(namespaces.pages);

  const handleDeleteFile = useCallback(async () => {
    const file = fileToDelete;
    setIsDeleteDialogOpen(false);
    setIsDeleting(true);
    await deleteFile(file.id, {
      onDeleted: () => {
        onFilesChange(files.filter((f) => f.id !== file.id));
      },
    });
    setFileToDelete(null);
    setIsDeleting(false);
  }, [fileToDelete, files, onFilesChange]);

  if(!files || files.length === 0) return null;

  return (
    <GrayWrapper className={`justify-left items-left w-full ${theme} py-[4px] pt-[4px] pb-[4px]`}>
      <div className="flex flex-col gap-y-[10px] px-[25px] max-h-[300px] overflow-y-auto my-[5px]">
        {files.map((file) => (
          <div key={file.id} className="flex flex-row justify-between items-center">
            <span
              key={file.id}
              className={`font-bold text-black-700 text-sm py-[4px] overflow-hidden text-ellipsis max-w-[200px] ${textColor}`}
            >
              {file.name}

              <span className="font-normal text-red-500 text-xs px-[2px]">
                {downloadErrors[file.id] ? downloadErrors[file.id] : ""}
              </span>
            </span>
            <div className="flex items-center justify-center gap-[10px]">
              {displayFileSizes && (
                <span className="text-sm text-500 text-[#B3B3B3]">
                  {convertBytesToFileSizeString(file.size)}
                </span>
              )}
              {hasPreview(file) && (
                <CircleButton
                  icon="/images/preview.svg"
                  primary
                  // disabled={disabled}
                  iconSize="12px"
                  onClick={/*disabled ? () => {} : */ handleOpenPreview(file)}
                ></CircleButton>
              )}
              <a
                // href={`${DOWNLOAD_BASE_URL}${file.path}`}
                // download={file.originalName}
                className="w-[35px] flex items-center justify-center"
                onClick={/*disabled ? () => {} :*/ handleDownload(file)}
              >
                {isDownloading[file.id] ? (
                  <span className="text-black hove:text-black text-sm font-bold">
                    {(downloadProgress[file.id] || 0) + "%"}
                  </span>
                ) : (
                  <CircleButton
                    // disabled={disabled}
                    icon="/images/download.svg"
                    primary
                    iconSize="12px"
                    onClick={(e) => {}}
                  ></CircleButton>
                )}
              </a>
              {
                <CircleButton
                  icon="/images/sidebar_delete.svg"
                  secondary
                  iconSize="12px"
                  disabled={disabled}
                  onClick={
                    disabled
                      ? () => {}
                      : () => {
                          setFileToDelete(file);
                          setIsDeleteDialogOpen(true);
                        }
                  }
                ></CircleButton>
              }
            </div>
          </div>
        ))}
      </div>
      <MessageDialog
        isLoading={isDeleting}
        isOpen={isDeleteDialogOpen}
        title={t("downloadFilesDeleteDialog.title")}
        submitText={t("downloadFilesDeleteDialog.delete")}
        cancelText={t("downloadFilesDeleteDialog.cancel")}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
          setFileToDelete(null);
        }}
        onSubmit={handleDeleteFile}
      ></MessageDialog>
      <PreviewFileDialog
        isLoading={isPreviewOpen && !previewFile}
        file={previewFile}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
      />
    </GrayWrapper>
  );
};

const GrayWrapper = ({ children, className, ...otherProps }) => {
  return (
    <div
      className={`bg-bg1 rounded-lg pt-[24px] pb-[17px] py-[25px] flex flex-col ${
        className || ""
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default DownloadFiles;
