import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { namespaces } from "../consts/i18n";
import { removeMessage } from "../redux/messages/messagesReducer";
import Toast from "./Toast";

const replaceUnwantedChars = (text) => {
  return text.replace(/\./g, "_").replace(/\:/g, "_");
};

const convertText = (t, text) => {
  const translatedText = t(text);

  if (translatedText !== text) {
    return translatedText;
  }

  if (!text.startsWith("toasts.")) {
    // if text not starts with toasts, then add it

    const newText = `toasts.${replaceUnwantedChars(text)}`;
    const translatedText = t(newText);
    if (translatedText !== newText) {
      return translatedText;
    }
  }
  
  return text;
};

const ToastMessages = () => {
  const { t } = useTranslation(namespaces.common);
  const messages = useSelector((state) => state.messages.data);
  const dispatch = useDispatch();

  return (
    <div className="fixed top-0 right-0 z-[100]">
      {messages.map((message) => (
        <Toast
          className={"relative"}
          key={message.id}
          message={convertText(t, message.text)}
          hideTimeout={message.type === "error" ? 10000 : 3000}
          type={message.type}
          onAfterHide={() => dispatch(removeMessage(message.id))}
        />
      ))}
    </div>
  );
};

export default ToastMessages;
