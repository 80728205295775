const AppErrorPage = () => {
  return (
    <div
    className="
      w-full h-full
      flex flex-col items-center justify-center gap-4
    "
  >
    <img
      src="/images/drone-error.jpg"
      alt="404"
      className="h-1/2 rounded-lg"
    />
    <div
      className="
      w-1/2
      flex flex-col items-center justify-center
      rounded-lg
      p-4
      "
    >
      <h1
        className="
        text-2xl
        text-red-500
        "
      >
        <b> Ups! Zdá se, že se něco se pokazilo.</b> Dron je v tuto chvíli rozbitý. Prosím <a hreg="#" onClick={() => {
          window.location.reload();
        }} className="underline decoration-1
        cursor-pointer hover:text-red-300">obnovte stránku</a>.
      </h1>
    </div>
  </div>
  );
};

export default AppErrorPage;
